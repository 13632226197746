import React from 'react'
import PropTypes from 'prop-types'
import MotorForm from 'components/Forms/MotorForm'
import { Breadcrumb } from 'antd'

const MotorMainPage = props => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 mb-5rem">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Buy Motor Insurance</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <MotorForm />
        </div>
      </div>
    </div>
  )
}

MotorMainPage.propTypes = {
  match: PropTypes.object
}

export default MotorMainPage
