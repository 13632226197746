export function getPremiumFromAges(premiums, ages) {
  let total = 0
  for (let i = 0; i < ages.length; i++) {
    let premium = premiums.find(premium => {
      let upper = parseInt(premium.ageRange.upper, 10)
      let lower = parseInt(premium.ageRange.lower, 10)
      let intAge = parseInt(ages[i], 10)
      return intAge >= lower && intAge < upper
    })

    if (!premium) {
      return null
    }
    total += parseFloat(premium.premium)
  }
  return total
}

export function getBreakdownValueByLabel(breakdowns, label) {
  for (let i = 0; i < breakdowns.length; i++) {
    if (breakdowns[i].label.toLowerCase() === label.toLowerCase()) {
      if (breakdowns[i].value) {
        return breakdowns[i].value
      } else return 'N/A'
    }
  }

  return ''
}

export const formatProducts = (productsList, search) => {
  let formattedProducts = null

  // sort travel premiums in order of age range
  formattedProducts = productsList.map(product => {
    let travelPremiums = product.travelPremiums

    travelPremiums.sort(
      (a, b) =>
        b.ageRange.upper -
        b.ageRange.lower -
        (a.ageRange.upper - a.ageRange.lower)
    )
    product.travelPremiums = travelPremiums
    return product
  })

  // convert all premiums to local currency
  formattedProducts = formattedProducts.map(p => {
    let newLocalPremiums = p.travelPremiums.map(tp => {
      return {
        ...tp,
        premium: tp.localPremium
      }
    })
    // debugger

    return {
      ...p,
      currency: 'GHS',
      travelPremiums: newLocalPremiums
    }
  })

  // sort products by local currency premiums
  formattedProducts = formattedProducts.sort((a, b) => {
    const premiumA = getPremiumFromAges(a.travelPremiums, search.travellerAges)

    const premiumB = getPremiumFromAges(b.travelPremiums, search.travellerAges)

    return premiumA - premiumB
  })

  // for products for product card.
  formattedProducts = formattedProducts.map((p, i) => {
    let premium = p.travelPremiums[0].premium

    if (search.travellerAges.length > 0 && !p.travelInfo.isFamily) {
      premium = getPremiumFromAges(p.travelPremiums, search.travellerAges)
    }

    if (i === 4) {
      // for showing other usable content between policy listings
      return {
        isPolicy: false
      }
    } else {
      return {
        ...p,
        isPolicy: true,
        premium: {
          currency: p.currency,
          amount: premium
        },
        companyLogo: p.company.logoUrl,
        companyName: p.company.name,
        companyId: p.company.id,
        compare: true,
        showDetail: true
      }
    }
  })

  return formattedProducts
}
