import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import axios from 'axios'
import * as _ from 'lodash'
import { css } from 'react-emotion'
import { FadeLoader } from 'react-spinners'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { getHeaders, transactionsUrl, errorMessage } from '../../config'
import { setCookie } from 'utils/urls'

import ProductBreakdown from 'components/Product/ProductBreakdown'
import WithUser from 'hocs/WithUser'
import MotorProductBreakdown from 'components/Product/MotorProductBreakdown'
import PropertyProductBreakdown from 'components/Product/PropertyProductBreakdown'
import Rating from 'components/Rating'
import {
  MOTOR_INSURANCE,
  TRAVEL_INSURANCE,
  PROPERTY_INSURANCE,
  LIFE_INSURANCE,
  HEALTH_INSURANCE
} from 'app-constants'
import HealthProductBreakdown from 'components/Product/HealthProductBreakdown'
import LifeProductBreakdown from 'components/Product/LifeProductBreakdown'

class PaymentSuccessfulPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSubmitting: true,
      message: null,
      product: null,
      premium: null,
      policies: [],
      region: null,
      showModal: false,
      isProperty: false,
      isMotor: false,
      isTravel: false,
      isHealth: false,
      isLife: false,
      currentModule: '',

      // Motor
      policy: {},

      errorMessage: 'An error occurred, please try again'
    }

    this.handleCancel = this.handleCancel.bind(this)
    this.handleOk = this.handleOk.bind(this)
  }

  // isMotor = this.props.match.path.split('/').includes('motor')
  // isProperty = this.props.match.path.split('/').includes('property')

  confirmGHPayment() {
    try {
      let URL = `${transactionsUrl}${this.props.match.params.paymentId}/confirm-expresspay${this.props.location.search}`

      axios
        .get(`${URL}`, {
          headers: getHeaders()
        })
        .then(res => {
          this.setState({ message: res.data.message }, () => {
            // setTimeout(() => {
            //   this.setState({ showModal: true })
            // }, 3000)
          })

          // remove purchase referrer cookie
          let expiryDate = new Date('1970-12-01')
          setCookie('referrer', 'none', expiryDate)
        })
        .catch(err => {
          console.error(err)
          this.setState({
            message: 'We were unable to process your payment. Please try again'
          })
        })
        .then(() => {
          this.setState({ isSubmitting: false })
        })
    } catch (err) {
      console.error(err)
    }
  }

  confirmNonGhPayment() {
    const message =
      'Your Payment is being processed, we will notify you shortly.'

    this.setState({ message, isSubmitting: false }, () => {
      setTimeout(() => {
        this.setState({ showModal: true })
      }, 3000)
    })

    // remove purchase referrer cookie
    let expiryDate = new Date('1970-12-01')
    setCookie('referrer', 'none', expiryDate)
  }

  confirmPayment(region) {
    if (region === 'gh') {
      console.log('TIME TO CONFIRM')
      this.confirmGHPayment()
    } else {
      this.confirmNonGhPayment()
    }
  }

  getPayment() {
    let paymentUrl = `${transactionsUrl}${this.props.match.params.paymentId}`

    axios
      .get(paymentUrl, {
        headers: getHeaders()
      })
      .then(res => {
        let data = res.data
        if (res.data.moduleType === TRAVEL_INSURANCE) {
          this.setState(
            {
              currentModule: res.data.moduleType,
              product: data.policy.product,
              premium: data.policy.premium,
              region: data.policy.product.company.region
            },
            () => {
              this.confirmPayment(this.state.region)
            }
          )
        } else if (
          [
            MOTOR_INSURANCE,
            HEALTH_INSURANCE,
            PROPERTY_INSURANCE,
            LIFE_INSURANCE
          ].includes(res.data.moduleType)
        ) {
          this.setState(
            {
              currentModule: res.data.moduleType,
              product: data.policy,
              premium: data.policy.premium,
              region: data.policy.company.region
            },
            () => {
              this.confirmPayment(this.state.region)
            }
          )
        }
      })
      .catch(err => {
        if (err.response) {
          errorMessage('Oops! Something went wrong, kindly retry.')
        } else {
          errorMessage(
            'Error connecting, kindly check your internet connection.'
          )
        }
        this.setState({ isSubmitting: false })
      })
  }

  componentDidMount() {
    this.getPayment()
  }

  handleOk() {
    this.setState({ showModal: false })
  }

  handleCancel() {
    this.setState({ showModal: false })
  }

  render() {
    let product = this.state.product

    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `
    return (
      <React.Fragment>
        {!_.isEmpty(this.state.product) ? (
          <div>
            <br />
            <br />
            <div className="row reverse-md wrapper mt-5rem mb-5rem pl-4rem pr-4rem">
              <div className="col-md-7">
                <h4 className="text--blue mb-2rem" style={{ maxWidth: '70%' }}>
                  {this.state.isSubmitting ? (
                    'Just a moment...'
                  ) : (
                    // <span>Payment Successfully Processed</span>
                    <span className="">
                      {this.state.message && this.state.message}.
                    </span>
                  )}
                </h4>

                <Link to={`/${this.state.currentModule}`}>
                  <button
                    className="btn btn--gradient-primary btn--width-lng mr-2rem mb-2rem"
                    type="button"
                  >
                    View Policies
                  </button>
                </Link>
              </div>
              <div className="col-md-5 mb-2rem">
                <p className="mb-2rem text--blue">
                  Here's a breakdown of your policy
                </p>
                {this.state.currentModule === TRAVEL_INSURANCE && product ? (
                  <ProductBreakdown
                    product={product}
                    premium={this.state.premium}
                    isPurchasing={true}
                  />
                ) : null}

                {this.state.currentModule === MOTOR_INSURANCE && product ? (
                  <MotorProductBreakdown
                    product={product}
                    premium={this.state.premium}
                    isPurchasing={true}
                  />
                ) : null}

                {this.state.currentModule === PROPERTY_INSURANCE && product ? (
                  <PropertyProductBreakdown
                    product={product}
                    premium={this.state.premium}
                    isPurchasing={true}
                  />
                ) : null}

                {this.state.currentModule === HEALTH_INSURANCE && product ? (
                  <HealthProductBreakdown
                    product={product}
                    premium={this.state.premium}
                    isPurchasing={true}
                  />
                ) : null}

                {this.state.currentModule === LIFE_INSURANCE && product ? (
                  <LifeProductBreakdown
                    product={product}
                    premium={this.state.premium}
                    isPurchasing={true}
                  />
                ) : null}
              </div>
            </div>

            <Modal
              title="Please rate your experience with us"
              centered
              visible={this.state.showModal}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              footer={null}
            >
              <Rating handleCancel={this.handleCancel} />
            </Modal>
          </div>
        ) : (
          <div className="mt-10rem mb-10rem">
            <FadeLoader
              className={override}
              sizeUnit={'px'}
              size={150}
              color={'#13d1cf'}
            />
          </div>
        )}
      </React.Fragment>
    )
  }
}
PaymentSuccessfulPage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  authUser: PropTypes.shape({
    token: PropTypes.string
  })
}

export default withRouter(WithUser(PaymentSuccessfulPage))
