import { constants } from "../../app-constants";
import store from "../../store";

export const setRegion = (region) =>
  store.dispatch({
    type: constants.SET_REGION,
    payload: region,
  });

export const removeRegion = () =>
  store.dispatch({
    type: constants.REMOVE_REGION,
  });
