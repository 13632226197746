import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { OTPVerificationUrl, getHeaders } from '../../config'
import WithUser from 'hocs/WithUser'
import { Input } from 'antd'
import checkSign from 'img/check-sign.svg'

class SignupOTP extends Component {
  state = {
    otp: '',
    isSubmitting: false,
    errorMessage: '',
    redirectToReferrer: false
  }

  handleInputUpdate = (stateName, event) => {
    this.setState({ [stateName]: event.target.value, errorMessage: '' })
  }

  handleSubmit = event => {
    event.preventDefault()
    this.setState({ isSubmitting: true })
    let { otp } = this.state
    let { phoneNumber } = this.props.authUser
    let data = {
      phoneNumber,
      otp
    }

    axios
      .post(OTPVerificationUrl, data, { headers: getHeaders() })
      .then(() => {
        this.props.history.push('/auth/login')
      })
      .catch(error => {
        if (error.response && error.response.status < 500) {
          this.setState({ errorMessage: error.response.data.message })
        } else
          this.setState({
            errorMessage: 'Oops! Something happened. Please try again.'
          })

        this.setState({ isSubmitting: false })
      })
  }

  render() {
    return (
      <React.Fragment>
        <main style={{ minHeight: '60vh' }} className="site-content">
          <img style={{ maxHeight: '30px' }} src={checkSign} alt="BestQuote" />
          <p className="mt-1rem mb-1rem">Verify Your Account!</p>
          <p className="mt-1rem mb-5rem">
            <span className="text--muted">
              Please check your phone inbox for verification code.
            </span>
            <form
              className="form mt-2rem"
              onSubmit={this.handleSubmit}
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <div className="form__inputs mt-2rem">
                <div className="form__wrapper">
                  <div className="">
                    <label className="form__label mt-1rem mb-1rem">
                      Enter 4 Digit Verification Code
                    </label>
                    <Input
                      className="form__input"
                      placeholder="XXXX"
                      type="text"
                      required
                      onChange={this.handleInputUpdate.bind(this, 'otp')}
                    />
                    {this.state.errorMessage ? (
                      <p style={{ color: 'red', marginTop: '1em' }}>
                        {this.state.errorMessage}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="form__inputs mt-2rem">
                <div className="form__wrapper">
                  <div>
                    <button
                      className="btn btn--gradient-primary btn--width-auto mt-2rem mb-2rem"
                      type="submit"
                    >
                      {this.state.isSubmitting ? '...' : 'Activate Account'}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </p>
        </main>
      </React.Fragment>
    )
  }
}

export default withRouter(WithUser(SignupOTP))
