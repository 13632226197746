import React from 'react'
// import { Link } from 'react-router-dom'
// import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// import { getPremiumFromAges } from 'utils/products'
import { getBreakdownValueByLabel } from 'utils/products'
import { getExactValue } from 'utils/payments'
import WithProducts from 'hocs/WithProducts'

class ProductBreakdown extends React.Component {
  render() {
    let product = this.props.product
    let premium = this.props.premium?.amount
      ? this.props.premium
      : product.premium
    // let travellerAges = this.props.travellerAges
    // if (!travellerAges) travellerAges = []

    // const urlOptions = { T: 'travel', M: 'motor', L: 'life' }

    // Calculate premium based on travellers
    // if (travellerAges.length > 0 && !product.travelInfo.isFamily) {
    //   premium = getPremiumFromAges(product.travelPremiums, travellerAges)
    // }

    //Set agreed premium if purchasing
    // if (this.props.isPurchasing && this.props.premium) {
    //   premium = this.props.premium.amount
    // }

    // let ghsPremium = null
    // if (premium) {
    //   ghsPremium = getGhsValue(
    //     premium,
    //     product.currency,
    //     this.props.exchangeRate
    //   )
    // }

    let bds = product.travelInfo.travelCoverBreakdownSet

    return (
      <div className="policy-breakdown">
        <div className="policy__header">
          <img
            alt=""
            className="policy__logo-img policy__logo-img--summary"
            src={product.company.logoUrl}
          />
        </div>
        <div className="policy__info policy__info--summary policy__info--breakdown">
          <p className="policy__category">{product.name}</p>
          <p className="text--muted mb-1rem">by {product.company.name}</p>

          <p className="policy-breakdown__premium mt-1rem">
            {premium ? (
              <span>
                Premium: {getExactValue(premium.currency, premium.amount)}
              </span>
            ) : null}
          </p>
          {/* <p className="mt-1rem">
            {ghsPremium ? (
              <span>
                GHS Approximation: &#8373;
                {getDecimalValue(ghsPremium)}
              </span>
            ) : null}
          </p> */}
        </div>

        <div />

        <div className="policy__features mt-2rem">
          <p className="mb-1rem text--upper text--muted">General Info</p>

          <div className="policy__age-groups">
            <div className="policy__feature">
              <span>Age Groups</span>
            </div>
            {product.travelPremiums.map(premium => (
              <div className="policy__feature" key={premium.id}>
                <span>
                  {premium.ageRange.lower} - {premium.ageRange.upper}
                </span>
                <span>
                  {/* {getApproximateValue(product.currency, premium.premium)} */}
                  {getExactValue(product.currency, premium.premium)}
                </span>
              </div>
            ))}
          </div>

          <div className="policy__feature">
            <span>Duration</span>
            <span>{product.travelInfo.maxDuration} days</span>
          </div>
        </div>

        <hr />

        <div className="policy__features">
          <div className="policy__feature">
            <span>Medical expenses and hospitalization abroad</span>
            <span>{getBreakdownValueByLabel(bds, 'me_abroad')}</span>
          </div>
          <div className="policy__feature">
            <span>
              Emergency medical evacuation in case of illness/accident
            </span>
            <span>{getBreakdownValueByLabel(bds, 'em_evacuation')}</span>
          </div>
          <div className="policy__feature">
            <span>Emergency dental care</span>
            <span>{getBreakdownValueByLabel(bds, 'e_dental')}</span>
          </div>
          <div className="policy__feature">
            <span>Emergency optical</span>
            <span>{getBreakdownValueByLabel(bds, 'e_optical')}</span>
          </div>
          <div className="policy__feature">
            <span>Return after medical treatment</span>
            <span>{getBreakdownValueByLabel(bds, 'return_treatment')}</span>
          </div>
        </div>

        <hr />

        <div className="policy__features">
          <div className="policy__feature">
            <span>Repatriation of mortal remains</span>
            <span>{getBreakdownValueByLabel(bds, 'mortal_remains')}</span>
          </div>
          <div className="policy__feature">
            <span>
              Repatriation of family member travelling with the insured
            </span>
            <span>{getBreakdownValueByLabel(bds, 'family_repa')}</span>
          </div>
          <div className="policy__feature">
            <span>
              Emergency return home following the death of a close family member
            </span>
            <span>{getBreakdownValueByLabel(bds, 'ef_death_return')}</span>
          </div>
          <div className="policy__feature">
            <span>Accidental death by means of transport</span>
            <span>{getBreakdownValueByLabel(bds, 'transport_death')}</span>
          </div>
        </div>

        <hr />

        <div className="policy__features">
          <div className="policy__feature">
            <span>Travel of one immediate family member</span>
            <span>{getBreakdownValueByLabel(bds, 'ifm_travel')}</span>
          </div>
          <div className="policy__feature">
            <span>Trip cancellation</span>
            <span>{getBreakdownValueByLabel(bds, 'trip_cancel')}</span>
          </div>
          <div className="policy__feature">
            <span>Delayed departure</span>
            <span>{getBreakdownValueByLabel(bds, 'departure_delay')}</span>
          </div>
        </div>

        <div className="policy__features">
          <div className="policy__feature">
            <span>Advance of bond/fund</span>
            <span>{getBreakdownValueByLabel(bds, 'bond_advance')}</span>
          </div>
          <div className="policy__feature">
            <span>24 hours assistance service</span>
            <span>{getBreakdownValueByLabel(bds, 'assist_24')}</span>
          </div>
          <div className="policy__feature">
            <span>Legal Defence</span>
            <span>{getBreakdownValueByLabel(bds, 'legal_defence')}</span>
          </div>
        </div>

        <div className="policy__features">
          <div className="policy__feature">
            <span>Loss of documents</span>
            <span>{getBreakdownValueByLabel(bds, 'doc_loss')}</span>
          </div>
          <div className="policy__feature">
            <span>
              Location and forwarding of baggage and personal belonging
            </span>
            <span>{getBreakdownValueByLabel(bds, 'forwarding_baggage')}</span>
          </div>
          <div className="policy__feature">
            <span>Compensation for in-flight loss of checked in baggage</span>
            <span>{getBreakdownValueByLabel(bds, 'ifl_baggage')}</span>
          </div>
          <div className="policy__feature">
            <span>Compensation for delay in arrival of luggage</span>
            <span>{getBreakdownValueByLabel(bds, 'luggage_delay')}</span>
          </div>
        </div>

        <div className="policy__features">
          <div className="policy__feature">
            <span>Personal Injury</span>
            <span>{getBreakdownValueByLabel(bds, 'personal_liability')}</span>
          </div>
        </div>

        {/* {!this.props.isPurchasing ? (
          <div className="policy__buttons">
            <Link
              to={{
                pathname: `/${urlOptions[product.type]}/products/${
                  product.id
                }/details`,
                state: { id: product.id }
              }}
            >
              <button className="btn btn--buy btn--gradient-border">
                Buy Now
              </button>
            </Link>
          </div>
        ) : null} */}
      </div>
    )
  }
}
ProductBreakdown.propTypes = {
  isPurchasing: PropTypes.bool,
  travellerAges: PropTypes.array,
  exchangeRate: PropTypes.shape({
    ghsToEur: PropTypes.string,
    ghsToUsd: PropTypes.string
  }),
  premium: PropTypes.shape({
    amount: PropTypes.string
  }),
  product: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.number,
    travelPremiums: PropTypes.array,
    travelInfo: PropTypes.object,
    currency: PropTypes.string,
    company: PropTypes.shape({
      logoUrl: PropTypes.string,
      name: PropTypes.string
    })
  })
}

export default WithProducts(ProductBreakdown)
