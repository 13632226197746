import React from 'react'
import PropTypes from 'prop-types'

import added from 'img/added.svg'

const FileUpload = props => {
  return (
    <div className="flex-row mb-1rem">
      {props.document ? (
        <img
          src={added}
          alt=""
          style={{
            height: 'auto',
            maxHeight: '1.4rem',
            marginRight: '5px'
          }}
        />
      ) : (
        <span style={{ marginRight: '5px' }}>+</span>
      )}
      <label>
        <span style={{ fontSize: '1.4rem' }}>{props.fileName}</span>
      </label>
      <label className="form__file-upload">
        {props.document ? (
          <span style={{ color: '#20d670', fontSize: '1.4rem' }}>
            {props.document.name}
          </span>
        ) : (
          '+ ADD FILE'
        )}
        <input
          type="file"
          onChange={props.handleFiles(
            props.stateName,
            props.documentName,
            props.fileName
          )}
          accept="application/pdf"
          required={props.required}
        />
      </label>
    </div>
  )
}
FileUpload.propTypes = {
  fileName: PropTypes.string,
  document: PropTypes.shape({
    name: PropTypes.string
  }),
  stateName: PropTypes.string,
  documentName: PropTypes.string,
  handleFiles: PropTypes.func,
  required: PropTypes.bool
}

export default FileUpload
