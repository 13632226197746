import React from 'react'
import PropType from 'prop-types'
import phoneRinging from 'img/phone_ringing.svg'
import WithProducts from 'hocs/WithProducts'

const GetHelpCard = props => {
  return (
    <React.Fragment>
      <div className="get_help_card">
        <img src={props.image} alt="get-help" />
        <div className="get_help_card_caption">
          <h2>Don’t know which premium to buy?</h2>
          <p>Get help from our refined insurance experts to guide you.</p>
          <button
            type="button"
            className="btn_normal btn_white"
            onClick={props.toggleMessenger}
          >
            <img src={phoneRinging} alt="ring" /> Send Us a Message
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}

GetHelpCard.propTypes = {
  image: PropType.any
}

export default WithProducts(GetHelpCard)
