import React, { Component } from 'react'

// TODO: Actually make this work
class UpdatePasswordForm extends Component {
  render() {
    return (
      <div>
        <p className="mb-1rem">Update Password</p>
        <p className="text--muted mb-2rem">
          You may update your password any time. We suggest you choose a strong
          password. All new passwords must contain at least 8 characters.
        </p>

        <form className="form">
          <div className="form__inputs mb-5rem">
            <div className="form__wrapper mb-2rem">
              <label className="form__label mt-1rem mb-1rem">
                New Password
              </label>
              <input
                className="form__input"
                // onChange={this.handleInputUpdate.bind(this, "email")}
                autoFocus="autofocus"
                required
              />
            </div>
            <div className="form__wrapper">
              <label className="form__label mt-1rem mb-1rem">
                Confirm Password
              </label>
              <input
                className="form__input"
                type="password"
                // onChange={this.handleInputUpdate.bind(this, "password")}
                required
              />
            </div>
          </div>

          <hr />

          <div className="form__wrapper mt-5rem">
            <label className="form__label mt-1rem mb-1rem">
              Current Password
            </label>
            <input
              className="form__input"
              placeholder="Enter password"
              type="password"
              // onChange={this.handleInputUpdate.bind(this, "password")}
              // autoComplete="current-password"
              // required
            />
          </div>

          <div className="mt-5rem">
            <button className="btn btn--gradient-primary btn--width-md  mb-2rem mr-2rem">
              Save Changes
            </button>
            {/* <span className="text--aqua">CANCEL</span> */}
          </div>
        </form>
      </div>
    )
  }
}

export default UpdatePasswordForm
