import React, { useState, useEffect } from 'react'
import arrowDown from 'img/motor/arrow_down.svg'
// import divider from 'img/motor/line_divider.svg'
import sync from 'img/motor/sync.svg'
import syncRed from 'img/motor/sync-red.svg'
import PropTypes from 'prop-types'
import { LoadingOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import ProductBreakdown from 'components/Product/ProductBreakdown'

const PolicyCard = props => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const { policy } = props

  useEffect(() => {
    setIsSubmitting(props.isSubmitting)
    // eslint-disable-next-line
  }, [])

  let nfExact = currency =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })

  return (
    <React.Fragment>
      {/* <div className="policy_card">
        <div className="policy_card_excerpt">
          <div className="policy_card_excerpt_left">
            <p>
              View <br />
              Details
            </p>
            <img src={arrowDown} alt="details" />
            <img
              src={divider}
              className="policy_card_excerpt_left_divider"
              alt="divider"
            />
          </div>

          <div className="policy_card_excerpt_company">
            <img src={policy.companyLogo} alt="logo" />
            <p>{policy.companyName}</p>
          </div>

          <div className="policy_card_excerpt_pricing">
            <h1>
              {policy.premium.currency} {policy.premium.amount}
            </h1>
          </div>

          <div className="policy_card_excerpt_cta">
            <button type="button" className="policy_card_excerpt_cta1">
              Buy Now
            </button>
            <button type="button" className="policy_card_excerpt_cta2">
              Compare
              <img src={sync} alt="sync" />
            </button>
          </div>
        </div>
      </div> */}

      <div className="policy_card">
        <div className="policy_card_excerpt">
          <div className="policy_card_excerpt_top">
            <div className="policy_card_excerpt_company">
              <img src={policy.companyLogo} alt="logo" />
            </div>
            {policy.showDetail ? (
              <div
                className="policy_card_excerpt_details"
                onClick={() => setShowModal(true)}
              >
                <p>View Details</p>
                <img src={arrowDown} alt="details" />
              </div>
            ) : policy.discount ? (
              <div className="policy_card_excerpt_details">
                <p>
                  {`Discount:
                  ${nfExact(policy.discount.currency).format(
                    policy.discount.amount
                  )}`}
                </p>
              </div>
            ) : null}
          </div>

          <div className="policy_card_excerpt_pricing">
            <h1>
              {nfExact(policy.premium.currency).format(policy.premium.amount)}
            </h1>
          </div>

          <div className="policy_card_excerpt_cta">
            {policy.compare === true ? (
              !props.showRemoveBtn ? (
                <button
                  type="button"
                  className="policy_card_excerpt_cta1"
                  onClick={props.handleCompare}
                >
                  Compare
                  <img src={sync} alt="sync" />
                </button>
              ) : (
                <button
                  type="button"
                  className="policy_card_excerpt_cta2"
                  onClick={props.handleRemove}
                >
                  Remove
                  <img src={syncRed} alt="sync-red" />
                </button>
              )
            ) : null}
            <button
              type="button"
              className="policy_card_excerpt_cta3"
              style={{ right: '2rem' }}
              onClick={props.handleBuy}
            >
              {isSubmitting === true ? <LoadingOutlined /> : 'Buy Now'}
            </button>
          </div>
        </div>
      </div>

      <Modal
        title=""
        centered
        width={'80%'}
        visible={showModal}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        <ProductBreakdown
          product={policy}
          premium={policy.premium}
          key={policy.id}
          isPurchasing={true}
        />
      </Modal>
    </React.Fragment>
  )
}

PolicyCard.propTypes = {
  policy: PropTypes.object,
  handleBuy: PropTypes.func,
  handleCompare: PropTypes.func,
  handleRemove: PropTypes.func,
  isSubmitting: PropTypes.bool,
  showRemoveBtn: PropTypes.bool
}

export default PolicyCard
