import { constants } from 'app-constants'
import store from 'store'

export const setExchangeRate = rate => ({
  type: constants.SET_EXCHANGE_RATE,
  payload: rate
})

export const setPayment = payload => {
  store.dispatch({
    type: constants.SET_PAYMENT,
    payload: payload
  })
}

export const removePayment = () => {
  store.dispatch({
    type: constants.REMOVE_PAYMENT
  })
}

export const setPaymentRegion = region =>
  store.dispatch({
    type: constants.SET_PAYMENT_REGION,
    payload: region
  })

export const removePaymentRegion = () =>
  store.dispatch({
    type: constants.REMOVE_PAYMENT_REGION
  })

export const setSubaccount = id =>
  store.dispatch({
    type: constants.SET_SUBACCOUNT,
    payload: id
  })

export const removeSubaccount = () =>
  store.dispatch({
    type: constants.REMOVE_SUBACCOUNT
  })

export const openPaymentModal = payload => {
  store.dispatch({
    type: constants.OPEN_PAYMENT_DIALOG,
    payload: payload
  })
}
