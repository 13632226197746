import React, { useState } from 'react'
import moment from 'moment'
import axios from 'axios'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { getHeaders } from '../../config'
import WithProducts from 'hocs/WithProducts'
// import WithRegion from 'hocs/WithRegion'
// import { getCookie } from 'utils/urls'
import { Form } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import countryList from 'react-select-country-list'
import SelectInput from './SelectInput'
import DatePickerInput from './DatePickerInput'

const TravelSearchForm = props => {
  const { payload } = props

  const [policy, setPolicy] = useState({
    travellerType: payload.travellerType || '',
    countries: payload.countries || [],
    travellerAges: payload.travellerAges || [],
    coverType: payload.coverType || '',
    startDate: payload.startDate || new Date(),
    endDate: payload.endDate || new Date(),
    region: 'gh'
  })

  const [formSection, setFormSection] = useState(1)
  // const [region, setRegion] = useState('')
  // const [referrer, setReferrer] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState({})
  // const [affiliateReferrer, setAffiliateReferrer] = useState(false)

  const removeErrorMessage = stateName => {
    // remove form field errors
    if (errorMessage[stateName]) {
      setErrorMessage({
        ...errorMessage,
        [stateName]: null,
        nonFieldErrors: null
      })
    } else if (errorMessage.vehicles) {
      setErrorMessage({
        ...errorMessage,
        vehicles: errorMessage.vehicles.map(v => {
          return { ...v, [stateName]: null }
        }),
        nonFieldErrors: null
      })
    }
  }

  const handleSelectUpdate = stateName => value => {
    if (value) {
      setPolicy({ ...policy, [stateName]: value })
    } else {
      setPolicy({ ...policy, [stateName]: '' })
    }
    console.log(policy[stateName])

    // remove form field errors
    removeErrorMessage(stateName)
  }

  const handleDateUpdate = stateName => date => {
    setPolicy({ ...policy, [stateName]: date })

    // remove form field errors
    removeErrorMessage(stateName)
  }

  const numberOfAdults = ages => {
    let adults = 0
    for (let i = 0; i < ages.length; i++) {
      if (ages[i] > 17) adults += 1
    }
    return adults
  }

  const handleSubmit = async () => {
    // handle checks
    if (policy.travellerType === 'F') {
      if (policy.travellerAges.length < 3 || policy.travellerAges.length > 5) {
        setErrorMessage({
          ...errorMessage,
          nonFieldErrors: ['Family policies require 3-5 travellers']
        })
        return
      } else if (numberOfAdults(policy.travellerAges) !== 2) {
        setErrorMessage({
          ...errorMessage,
          nonFieldErrors: ['Family policies require at least two adults']
        })
        return
      }
    }

    let startDate = moment(policy.startDate)
    let endDate = moment(policy.endDate)
    let diff = endDate.diff(startDate, 'days')

    if (policy.coverType === 'M') {
      if (diff < 90) {
        setErrorMessage({
          ...errorMessage,
          nonFieldErrors: [
            'The minimum duration for a multiple trip is 90 days'
          ]
        })
        return
      }
    }

    setIsSubmitting(true)

    const url = `${process.env.REACT_APP_API_BASE}/api/v1/search/travel`

    let data = policy
    data.countries = data.countries.map(country => country.toLowerCase())
    data.isFamily = data.travellerType === 'F'
    data.isSingleTrip = data.coverType === 'S'

    // save search query
    axios
      .post(url, data, { headers: getHeaders() })
      .then(res => {
        // console.log(res.data.results.id)
        // use search query to retrieve products
        const newUrl = `${url}/${res.data.results.id}/results`
        axios.get(newUrl, { headers: getHeaders() }).then(res => {
          let data = res.data
          let results = data.results.map(product => {
            product.isToggled = false
            return product
          })

          props.setProducts(results)
          props.setPayload(policy)
          props.setSearchItem(data.search)
          setIsSubmitting(false)
          props.history.push('/buy-travel-insurance/compare')
        })
      })
      .catch(err => {
        setIsSubmitting(false)
        if (err.response) {
          err.response.status === 400
            ? setErrorMessage({
                ...err.response.data,
                nonFieldErrors: ["Kindly make sure you've completed the form"]
              })
            : setErrorMessage({
                ...errorMessage,
                nonFieldErrors: ['Oops, server Error! Retry']
              })
        } else {
          setErrorMessage({
            ...errorMessage,
            nonFieldErrors: ['Error Connecting to Server, Retry.']
          })
        }
      })
  }

  const handleNextSection = () => {
    setFormSection(formSection + 1)
  }

  const travelOptions = [
    { label: 'Individual(s)', value: 'I' },
    { label: 'Family', value: 'F' }
  ]
  const coverOptions = [
    { label: 'Single Trip', value: 'S' },
    { label: 'Multi-Trip', value: 'M' }
  ]
  const countryOptions = countryList().getData()
  let ages = []
  for (let i = 0; i < 120; i++) {
    ages.push({ label: i, value: i })
  }

  return (
    <Form
      onFinish={formSection === 1 ? handleSubmit : handleNextSection}
      layout="vertical"
      className="travel_form"
    >
      {formSection === 1 ? (
        <div className="p-2rem">
          <h3 className="mb-2rem">Start your insurance cover</h3>
          <div className="row travel_form_section">
            {/* travel types */}
            <div className="col-md-5">
              <SelectInput
                label={'Your Destination(s)?'}
                value={policy.countries}
                name={'travelling destination(s)'}
                optionList={countryOptions}
                onChange={handleSelectUpdate('countries')}
                mode={'multiple'}
                required={true}
                placeholder="select destinations"
              />
              {errorMessage.countries
                ? errorMessage.countries.map((err, i) => (
                    <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>
            {/* end motor types */}

            <div className="col-md-5 offset-md-2 offset-sm-0">
              <SelectInput
                label="Who's Travelling?"
                value={policy.travellerType}
                name="type of travel"
                onChange={handleSelectUpdate('travellerType')}
                optionList={travelOptions}
                placeholder="choose traveler"
                required={true}
              />

              {errorMessage.travellerType
                ? errorMessage.travellerType.map((err, i) => (
                    <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>

            {/* driving experience and duration */}
            <div className="col-md-5">
              <SelectInput
                label={'Traveller Age(s)?'}
                value={policy.travellerAges}
                name={'travelller ages'}
                optionList={ages}
                onChange={handleSelectUpdate('travellerAges')}
                mode={'multiple'}
                placeholder="select traveller ages"
                required={true}
              />
              {errorMessage.travellerAges
                ? errorMessage.travellerAges.map(err => (
                    <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                  ))
                : null}
            </div>

            <div className="col-md-5 offset-md-2 offset-sm-0">
              <SelectInput
                label="Which cover do you prefer?"
                value={policy.coverType}
                name="type of travel cover"
                onChange={handleSelectUpdate('coverType')}
                optionList={coverOptions}
                toolTipLabel="SINGLE TRIP - Covers only one trip under the cover period"
                toolTipLabel2="MULTIPLE TRIP - Covers more than one trip under the cover period"
                placeholder="select type of the cover"
                required={true}
              />

              {errorMessage.coverType
                ? errorMessage.coverType.map((err, i) => (
                    <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>

            <div className="col-md-5">
              <DatePickerInput
                label="When does your Journey Start?"
                value={policy.startDate}
                handleChange={handleDateUpdate('startDate')}
                name="starting date"
                required={true}
              />{' '}
              {errorMessage.startDate
                ? errorMessage.startDate.map((err, i) => (
                    <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>

            <div className="col-md-5 offset-md-2 offset-sm-0">
              <DatePickerInput
                label="When does your Journey End?"
                value={policy.endDate}
                handleChange={handleDateUpdate('endDate')}
                name="ending date"
                required={true}
              />{' '}
              {errorMessage.endDate
                ? errorMessage.endDate.map((err, i) => (
                    <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>
            <div>
              {errorMessage.nonFieldErrors
                ? errorMessage.nonFieldErrors.map((err, i) => (
                    <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>
          </div>
        </div>
      ) : null}

      <div className="travel_form_buttons">
        <button
          className="btn_normal btn_dark btn_md"
          type="button"
          onClick={() =>
            setFormSection(formSection > 1 ? formSection - 1 : formSection)
          }
          style={formSection !== 1 ? {} : { visibility: 'hidden' }}
        >
          Back
        </button>

        <button className="btn_normal btn_gradient btn_md" type="submit">
          {isSubmitting ? (
            <LoadingOutlined />
          ) : formSection === 1 ? (
            'Search'
          ) : (
            'Continue'
          )}
        </button>
      </div>
    </Form>
  )
}

TravelSearchForm.propTypes = {
  setMotorProducts: PropTypes.func
}

export default WithProducts(withRouter(TravelSearchForm))
