import React, { Component } from 'react'
import axios from 'axios'
import WithUser from 'hocs/WithUser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

class Rating extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ratings: [],
      currentRating: null,
      currentLabel: null,
      ratingRemarks: '',
      success: false
    }

    this.handleRatingChange = this.handleRatingChange.bind(this)
    this.handleRatingLabel = this.handleRatingLabel.bind(this)
    this.handleRatingRemarks = this.handleRatingRemarks.bind(this)
  }

  componentDidMount() {
    // set rating data
    let ratings = [
      {
        label: 'Very Poor',
        value: 1,
        checked: false
      },
      {
        label: 'Poor',
        value: 2,
        checked: false
      },
      {
        label: 'Satisfactory',
        value: 3,
        checked: false
      },
      {
        label: 'Good',
        value: 4,
        checked: false
      },
      {
        label: 'Excellent',
        value: 5,
        checked: false
      }
    ]
    this.setState({ ratings })
    // end set ratings
  }

  handleRatingChange(rating) {
    this.setState({ currentRating: rating })

    let updatedRatings = this.state.ratings.map(r => {
      if (r.value > Number(rating)) return { ...r, checked: false }

      return { ...r, checked: true }
    })

    this.setState({ ratings: updatedRatings }, () => {
      // update label test too.
      this.handleRatingLabel()
    })
  }

  handleRatingLabel() {
    const thisRating = this.state.ratings.find(
      obj => obj.value === this.state.currentRating
    )
    this.setState({ currentLabel: thisRating.label })
  }

  handleRatingRemarks(e) {
    this.setState({ ratingRemarks: e.target.value })
  }

  handleSubmit = async () => {
    try {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_BASE}/api/v1/feedbacks/`,
        data: {
          rating: Number(this.state.currentRating),
          remarks: this.state.ratingRemarks
        },
        headers: {
          Authorization: `JWT ${this.props.authUser.token}`
        }
      }).then(res => {
        this.setState({ success: true }, () => {
          setTimeout(() => {
            this.props.handleCancel()
          }, 1000)
        })
      })
    } catch (err) {
      this.setState({ success: true }, () => {
        setTimeout(() => {
          this.props.handleCancel()
        }, 1000)
      })
      console.error(err)
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="rating-section">
          {!this.state.success ? (
            <>
              <div className="rating-stars">
                {this.state.ratings.map((rating, i) => (
                  <div
                    className="rating-column"
                    onClick={() => this.handleRatingChange(rating.value)}
                    key={i}
                  >
                    <FontAwesomeIcon
                      icon={faStar}
                      color={rating.checked ? 'orange' : 'grey'}
                      className="rating-star"
                      size="3x"
                    />
                    {/* <b className="rating-caption">{rating.label}</b> */}
                  </div>
                ))}
              </div>

              <div className="rating-caption">
                <b>{this.state.currentLabel}</b>
              </div>

              {this.state.currentLabel ? (
                <>
                  <div className="mt-2rem rating-remarks">
                    <textarea
                      className="form__input mt-2rem"
                      style={{ width: '80%' }}
                      value={this.state.ratingRemarks}
                      name="ratingRemarks"
                      placeholder="Your Remarks (Optional)"
                      onChange={this.handleRatingRemarks}
                    />
                  </div>

                  <div className="rating-action mt-2rem">
                    <button
                      className="btn btn--gradient-primary mr-4rem mb-2rem"
                      type="button"
                      onClick={this.handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </>
              ) : null}
            </>
          ) : (
            <div className="rating-success mt-2rem">
              <h2 className="rating-caption mr-3rem">Thank You!</h2>
              <FontAwesomeIcon icon={faCheckCircle} color={'green'} size="4x" />
            </div>
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default WithUser(Rating)
