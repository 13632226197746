import { constants } from '../../app-constants'

let initialState = {
  region: 'gh',
  regionUpdated: false
}

export const region = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_REGION: {
      return {
        ...state,
        region: action.payload
      }
    }

    case constants.REMOVE_REGION: {
      return {
        ...state,
        region: ''
      }
    }

    case constants.UPDATE_REGION: {
      return {
        ...state,
        regionUpdated: action.payload
      }
    }

    default: {
      return state
    }
  }
}
