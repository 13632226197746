import React, { useState, useEffect } from 'react'
import moment from 'moment'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { getHeaders, motorUrl, companyUrl } from '../../config'
import WithRegion from 'hocs/WithRegion'
import * as pc from 'app-constants'
import { LoadingOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import SelectInput from './SelectInput'
import DatePickerInput from './DatePickerInput'
import BasicInput from './BasicInput'
import CheckboxInput from './CheckboxInput'
import BasicTooltip from './BasicTooltip'
import WithProducts from 'hocs/WithProducts'
import WithUser from 'hocs/WithUser'

const MotorForm = props => {
  const { motorPayload } = props
  const [policy, setPolicy] = useState({
    companyId: motorPayload.companyId || '',
    motorType: motorPayload.motorType || null,
    startDate: motorPayload.startDate || new Date(),
    yearsOfDrivingExperience: motorPayload.yearsOfDrivingExperience || 0,
    duration: motorPayload.duration || null,
    extraCover: motorPayload.extraCover || 0,
    buyExcess: motorPayload.buyExcess || false,
    showExtraCover: motorPayload.showExtraCover || false,
    referrer: motorPayload.referrer || '',

    // vehicle
    type: motorPayload.type || null,
    cubicCapacity: motorPayload.cubicCapacity || 0,
    manufactureYear: motorPayload.manufactureYear || null,
    registrationNumber: motorPayload.registrationNumber || null,
    registrationYear: motorPayload.registrationYear || null,
    numberOfSeats: motorPayload.numberOfSeats || null,
    lastClaimYear: motorPayload.lastClaimYear || null,
    value: motorPayload.value || 0,
    color: motorPayload.color || '',
    tppdCover: motorPayload.tppdCover || null,
    privateUse: motorPayload.privateUse || false,
    tppdError: motorPayload.tppdError || '',
    chassisNumber: motorPayload.chassisNumber || '',
    model: motorPayload.model || '',
    make: motorPayload.make || '',
    claimCompany: motorPayload.claimCompany || ''
  })
  const [companies, setCompanies] = useState([])
  const [formSection, setFormSection] = useState(1)
  // const [region, setRegion] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState({})

  const vehicle = {
    type: policy.type,
    cubicCapacity: policy.cubicCapacity,
    manufactureYear: policy.manufactureYear,
    registrationNumber: policy.registrationNumber,
    registrationYear: policy.registrationYear,
    numberOfSeats: policy.numberOfSeats,
    lastClaimYear: policy.lastClaimYear,
    value: policy.value,
    color: policy.color,
    tppdCover: policy.tppdCover,
    privateUse: policy.privateUse,
    tppdError: policy.tppdError,
    chassisNumber: policy.chassisNumber,
    model: policy.model,
    make: policy.make,
    claimCompany: policy.claimCompany
  }
  const vehicles = [vehicle]

  const removeErrorMessage = stateName => {
    // remove form field errors
    if (errorMessage[stateName]) {
      setErrorMessage({
        ...errorMessage,
        [stateName]: null,
        nonFieldErrors: null
      })
    } else if (errorMessage.vehicles) {
      setErrorMessage({
        ...errorMessage,
        vehicles: errorMessage.vehicles.map(v => {
          return { ...v, [stateName]: null }
        }),
        nonFieldErrors: null
      })
    }
  }

  const handleSelectUpdate = stateName => value => {
    setPolicy({ ...policy, [stateName]: value })

    // remove form field errors
    removeErrorMessage(stateName)
  }

  const handleFieldUpdate = stateName => event => {
    setPolicy({ ...policy, [stateName]: event.target.value })

    // remove form field errors
    removeErrorMessage(stateName)
  }

  const handleDateUpdate = stateName => date => {
    setPolicy({ ...policy, [stateName]: date })

    // remove form field errors
    removeErrorMessage(stateName)
  }

  const handleCheckbox = event => {
    setPolicy({ ...policy, [event.target.name]: !policy[event.target.name] })
  }

  const handleSubmit = async () => {
    let data = {
      ...policy,
      startDate: policy.startDate
        ? moment(policy.startDate).format('YYYY-MM-DD')
        : '',
      buyExtraCover: policy.showExtraCover,
      extraCover: policy.showExtraCover ? Number(policy.extraCover) : 0,
      vehicles: vehicles.map(v => {
        return {
          ...v,
          lastClaimYear: getYearFromNumberOfYears(v.lastClaimYear)
        }
      })
    }

    setIsSubmitting(true)
    console.log(data)

    axios
      .post(`${motorUrl}/preview`, data, { headers: getHeaders() })
      .then(res => {
        props.setMotorProducts(res.data.results)
        props.setMotorPayload(data)
        props.history.push('/buy-motor-insurance/compare')

        // this.scrollTo()
        setIsSubmitting(false)
      })
      .catch(err => {
        setIsSubmitting(false)
        if (err.response) {
          err.response.status === 400
            ? setErrorMessage({
                ...err.response.data,
                nonFieldErrors: ["Kindly make sure you've completed the form"]
              })
            : setErrorMessage({
                ...errorMessage,
                nonFieldErrors: ['Oops, server Error! Retry']
              })
        } else {
          setErrorMessage({
            ...errorMessage,
            nonFieldErrors: ['Error Connecting to Server, Retry.']
          })
        }
      })
  }

  const handleNextSection = () => {
    setFormSection(formSection + 1)
  }

  useEffect(() => {
    // setRegion({ region: region })

    axios
      .get(`${companyUrl}/featured-companies`, { headers: getHeaders() })
      .then(res => {
        setCompanies(res.data.results)
      })

    // const { motorProducts } = props
    // motorProducts.length && this.scrollTo()
  }, [])

  const isComprehensive = policy.motorType === 'C' ? true : false

  const yearOptions = []

  let currYear = new Date().getFullYear()

  for (let i = currYear; i > 1969; i--) {
    yearOptions.push({ label: i, value: i })
  }

  let numberOfYearsOptions = []
  for (let i = 0; i <= 5; i++) {
    numberOfYearsOptions.push({ label: i, value: i })
  }

  const getYearFromNumberOfYears = numberOfYears => {
    if (numberOfYears < 1) {
      return 0
    } else {
      return Number(currYear) - numberOfYears
    }
  }

  const seatOptions = []
  for (let i = 1; i < 100; i++) {
    seatOptions.push({ label: i, value: i })
  }

  const vehiclesWithFiveSeats = ['TX']

  // const drivingExperienceOptions = [
  //   { label: 'Less than 1 year', value: 0.5 },
  //   { label: 'Less than 2 years', value: 1.5 },
  //   { label: 'More than 2 years', value: 3 }
  // ]

  const durationOptions = [
    { label: '3 Months', value: 3 },
    { label: '6 Months', value: 6 },
    { label: '8 Months', value: 8 },
    { label: '1 Year (12 Months)', value: 12 }
  ]

  const privateUseOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ]

  const comapnyOptions = companies.map(comp => {
    return {
      ...comp,
      label: comp.name,
      value: comp.id
    }
  })

  // const ccOptions = [
  //   { label: 'Below 1.6', value: 1500 },
  //   { label: 'From 1.6 to 2.0', value: 1800 },
  //   { label: 'Above 2.0', value: 2500 }
  // ]

  const motorProductTypes = pc.MOTOR_PRODUCT_TYPE_CHOICES
  const motorProductOptions = Object.keys(motorProductTypes).map(key => {
    return { label: motorProductTypes[key], value: key }
  })

  const vtc = pc.VEHICLE_TYPE_CHOICES
  const vehicleTypeOptions = Object.keys(vtc).map(key => {
    return { label: vtc[key], value: key }
  })

  return (
    <Form
      onFinish={formSection === 3 ? handleSubmit : handleNextSection}
      layout="vertical"
      className="motor_form"
    >
      {formSection === 1 ? (
        <div className="p-2rem">
          <h3 className="mb-2rem">Start your insurance cover</h3>
          <div className="row">
            {/* motor types */}
            <div className="col-md-5 mb-2rem">
              <SelectInput
                label={'Kindly select your prefered policy cover'}
                value={policy.motorType}
                name={'type of cover'}
                optionList={motorProductOptions}
                onChange={handleSelectUpdate('motorType')}
                placeholder="select preferred cover"
                required={true}
              />
              {errorMessage.motorType
                ? errorMessage.motorType.map((err, i) => (
                    <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>
            {/* end motor types */}

            <div className="col-md-5 offset-md-2 offset-sm-0 mb-2rem">
              <DatePickerInput
                label="Kindly chose when you want this policy to start"
                value={policy.startDate}
                handleChange={handleDateUpdate('startDate')}
                name="starting date"
                required={true}
              />{' '}
              {errorMessage.startDate
                ? errorMessage.startDate.map((err, i) => (
                    <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>

            {/* driving experience and duration */}
            <div className="col-md-5">
              <SelectInput
                label={
                  'Kindly tell how long you want to buy this insurance for'
                }
                value={policy.duration}
                name={'policy duration'}
                optionList={durationOptions}
                onChange={handleSelectUpdate('duration')}
                placeholder="select duration"
                required={true}
              />
              {errorMessage.duration
                ? errorMessage.duration.map(err => (
                    <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                  ))
                : null}
            </div>
          </div>
        </div>
      ) : null}

      {/* **************VEHICLE INFORMATION****************** */}
      {formSection === 2 ? (
        <div className="p-2rem">
          <h3 className="mb-2rem">Tell us about your car</h3>
          <div className="row">
            <div className="col-md-5 mb-2rem">
              <SelectInput
                label={'Kindly select the type of car'}
                value={policy.type}
                name={'type of car'}
                optionList={vehicleTypeOptions}
                onChange={handleSelectUpdate('type')}
                placeholder="select type of car"
                required={true}
              />

              {errorMessage.vehicles
                ? errorMessage.vehicles.map((err, i) => (
                    <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                      {err.type}
                    </p>
                  ))
                : null}
            </div>

            <div className="col-md-5 offset-md-2 offset-sm-0 mb-2rem">
              <BasicInput
                label="Kindly enter your Car's registration number"
                value={policy.registrationNumber}
                name={'registration number'}
                placeholder="GR 1147-18"
                handleChange={handleFieldUpdate('registrationNumber')}
                required={true}
              />

              {errorMessage.vehicles
                ? errorMessage.vehicles.map((err, i) => (
                    <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                      {err.registrationNumber}
                    </p>
                  ))
                : null}
            </div>

            <div className="col-md-5 mb-2rem">
              <BasicInput
                label="Kindly enter your car's Chassis Number"
                value={policy.chassisNumber}
                name={'chassis number'}
                placeholder="Chassis no."
                handleChange={handleFieldUpdate('chassisNumber')}
                toolTipLabel={
                  'You can find this on your Road-Worthy Certificate'
                }
                required={true}
              />

              {errorMessage.vehicles
                ? errorMessage.vehicles.map((err, i) => (
                    <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                      {err.chassisNumber}
                    </p>
                  ))
                : null}
            </div>

            <div className="col-md-5 offset-md-2 offset-sm-0 mb-2rem">
              <BasicInput
                label="Kindly color of your car"
                value={policy.color}
                name={'color of car'}
                placeholder="Color of car: eg: black"
                handleChange={handleFieldUpdate('color')}
                required={true}
              />

              {errorMessage.vehicles
                ? errorMessage.vehicles.map((err, i) => (
                    <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                      {err.color}
                    </p>
                  ))
                : null}
            </div>

            <div className="col-md-5 mb-2rem">
              <BasicInput
                label="Your Car's Model"
                value={policy.make}
                name={'car model'}
                placeholder="eg. Toyota Corolla"
                handleChange={handleFieldUpdate('make')}
                required={true}
              />

              {errorMessage.vehicles
                ? errorMessage.vehicles.map((err, i) => (
                    <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                      {err.make}
                    </p>
                  ))
                : null}
            </div>

            <div className="col-md-5 offset-md-2 offset-sm-0 mb-2rem">
              <SelectInput
                label={"Your Car's Model Year"}
                value={policy.manufactureYear}
                name={'year of manufacture'}
                optionList={yearOptions}
                onChange={handleSelectUpdate('manufactureYear')}
                toolTipLabel={'The year your car was manufactured'}
                placeholder="select model year"
                required={true}
              />

              {errorMessage.vehicles
                ? errorMessage.vehicles.map((err, i) => (
                    <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                      {err.manufactureYear}
                    </p>
                  ))
                : null}
            </div>

            {!vehiclesWithFiveSeats.includes(policy.type) ? (
              <div className="col-md-5 mb-2rem">
                <SelectInput
                  label={'Number of Seats in Your Car'}
                  value={policy.numberOfSeats}
                  name={'number of seats'}
                  optionList={seatOptions}
                  onChange={handleSelectUpdate('numberOfSeats')}
                  placeholder="select number of seats"
                  required={true}
                />

                {errorMessage.vehicles
                  ? errorMessage.vehicles.map((err, i) => (
                      <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                        {err.numberOfSeats}
                      </p>
                    ))
                  : null}
              </div>
            ) : null}

            {isComprehensive ? (
              <div
                className={`
              col-md-5 mb-2rem ${
                !vehiclesWithFiveSeats.includes(policy.type)
                  ? 'offset-md-2'
                  : ''
              }
              `}
              >
                <BasicInput
                  inputType="number"
                  label="How much is Your Car Worth?"
                  toolTipLabel="This will determine how much you get for claims."
                  value={policy.value}
                  name="value of car"
                  placeholder="eg: 20000"
                  handleChange={handleFieldUpdate('value')}
                  required={true}
                />

                {errorMessage.vehicles
                  ? errorMessage.vehicles.map((err, i) => (
                      <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                        {err.value}
                      </p>
                    ))
                  : null}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {/* *******END VEHICLE INFORMATION********* */}

      {formSection === 3 ? (
        <div className="row">
          <h3 className="mb-2rem">Finish up!</h3>

          {/* extra cover, et al */}
          <div className="col-md-12 mb-2rem">
            <CheckboxInput
              label="Do You Want to Buy an Extra Third Party Cover?"
              checked={policy.showExtraCover}
              name="showExtraCover"
              handleChange={handleCheckbox}
            />
          </div>

          <div className="col-md-12 mb-2rem">
            <CheckboxInput
              label="Do You Want to Buy Back Excess Cover?"
              checked={policy.buyExcess}
              name="buyExcess"
              handleChange={handleCheckbox}
            />
          </div>
          {/* end extra cover, et al */}

          {/* extra cover value, et al */}
          {policy.showExtraCover && (
            <>
              <div className="col-md-5">
                <div className="">
                  <BasicInput
                    label={'Enter the Excess Amount to Buy (GHS)'}
                    value={policy.extraCover}
                    name="excess amount"
                    placeholder="eg: 20000"
                    handleChange={handleFieldUpdate('extraCover')}
                    required={true}
                  />

                  {errorMessage.extraCover
                    ? errorMessage.extraCover.map((err, i) => (
                        <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                          {err}
                        </p>
                      ))
                    : null}
                </div>
              </div>
              <div className="col-md-7" />
            </>
          )}
          {/* end extra cover value, et al */}

          <div className={`col-md-5 offset-sm-0 mb-2rem`}>
            <SelectInput
              label={'Is the Car for only Private Use?'}
              value={policy.privateUse}
              name={'car usage'}
              optionList={privateUseOptions}
              onChange={handleSelectUpdate('privateUse')}
              placeholder="select"
              required={true}
            />
            {errorMessage.vehicles
              ? errorMessage.vehicles.map((err, i) => (
                  <p
                    style={{
                      color: 'red',
                      marginTop: '1em'
                    }}
                    key={i}
                  >
                    {err.privateUse}
                  </p>
                ))
              : null}
          </div>
          <div className="col-md-7 col-sm-0" />

          {/* NCD - no claim discount */}
          {isComprehensive && (
            <>
              <div className="col-md-5 mb-2rem mt-2rem">
                <h4 className="mb-1rem">
                  Apply for No Claim Discount (Totally Optional)
                  <BasicTooltip
                    label="This is only applicable to companies you
                                    previously bought a policy from."
                    label2="You will need to Top-Up with the Discount Amount
                                    if you don't Qualify."
                  />
                </h4>
                <SelectInput
                  label="How long have you gone without making a claim for the same car?"
                  value={policy.lastClaimYear}
                  name="years of no claim"
                  onChange={handleSelectUpdate('lastClaimYear')}
                  optionList={numberOfYearsOptions}
                  placeholder="select number of years"
                  required={false}
                />
                <label>
                  NB: Choose <small>'0'</small> if it's the first time you are
                  insuring your car.{' '}
                </label>

                {errorMessage.vehicles
                  ? errorMessage.vehicles.map((err, i) => (
                      <p
                        style={{
                          color: 'red',
                          marginTop: '1em'
                        }}
                        key={i}
                      >
                        {err.lastClaimYear}
                      </p>
                    ))
                  : null}
              </div>
              <div className="col-md-7 col-sm-0" />

              {policy.lastClaimYear ? (
                <div className="col-md-5 offset-sm-0 mb-2rem">
                  <SelectInput
                    label="Which company did you purchase your last policy with, for the same car?"
                    value={policy.claimCompany}
                    name="company"
                    onChange={handleSelectUpdate('claimCompany')}
                    optionList={comapnyOptions}
                    placeholder="select company"
                    required={false}
                  />

                  {errorMessage.vehicles
                    ? errorMessage.vehicles.map((err, i) => (
                        <p
                          style={{
                            color: 'red',
                            marginTop: '1em'
                          }}
                          key={i}
                        >
                          {err.claimCompany}
                        </p>
                      ))
                    : null}
                </div>
              ) : null}
            </>
          )}
          {/* end NCD */}

          <div className="col-md-12 mb-2rem">
            {errorMessage.nonFieldErrors
              ? errorMessage.nonFieldErrors.map((err, i) => (
                  <div style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </div>
                ))
              : null}
          </div>
        </div>
      ) : null}

      <div className="motor_form_buttons">
        <button
          className="btn_normal btn_dark btn_md"
          type="button"
          onClick={() =>
            setFormSection(formSection > 1 ? formSection - 1 : formSection)
          }
          style={formSection !== 1 ? {} : { visibility: 'hidden' }}
        >
          Back
        </button>

        <button className="btn_normal btn_gradient btn_md" type="submit">
          {isSubmitting ? (
            <LoadingOutlined />
          ) : formSection === 3 ? (
            'Done'
          ) : (
            'Continue'
          )}
        </button>
      </div>
    </Form>
  )
}

export default WithUser(WithProducts(WithRegion(withRouter(MotorForm))))
