import React from 'react'
import { Switch } from 'react-router-dom'
import PropTypes from 'prop-types'

// import PageNotFound from 'pages/PageNotFound'
import HomePage from './Home'
import FormPage from './Form'
import DetailPage from './Detail'
import PrivateRoute from 'hocs/PrivateRoute'

const ClientRoutes = props => (
  <>
    <Switch>
      <PrivateRoute exact path={props.match.path} component={HomePage} />
      <PrivateRoute
        exact
        path={`${props.match.path}/add-new`}
        component={FormPage}
      />
      <PrivateRoute
        exact
        path={`${props.match.path}/:id`}
        component={DetailPage}
      />
    </Switch>
  </>
)
ClientRoutes.propTypes = {
  match: PropTypes.object
}

export default ClientRoutes
