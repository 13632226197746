import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import axios from 'axios'
import { verifyAccountUrl } from '../../config'
import PropTypes from 'prop-types'

import bqLogo from 'img/bestquote-logo.png'

class ActivateAccount extends Component {
  state = {
    isSubmitting: false,
    errorMessage: ''
  }

  componentDidMount() {
    this.setState({ isSubmitting: true }, () => {
      let params = this.props.match.params
      axios
        .post(verifyAccountUrl, {
          key: params.key
        })
        .then(() => {
          this.props.history.push('/account')
        })
        .catch(() => {
          this.setState({ errorMessage: 'Unable to activate account' })
          this.setState({ isSubmitting: false })
        })
    })
  }

  render() {
    return (
      <div className="auth">
        <div className="auth__bounding-box">
          <div
            className="align--right mt-2rem"
            style={{ fontSize: '3rem', cursor: 'pointer' }}
          >
            <span onClick={this.props.history.goBack}>&times;</span>
          </div>
          <img
            src={bqLogo}
            alt="BestQuote Logo"
            className="bq-logo bq-logo--auth mb-2rem"
          />
          <p className="site-content__lead">Hello *name*,</p>
          <p className="">Thank you for signing up to BestQuote</p>
          <p className="">
            We're delighted to have you here! Please wait while we activate your
            account.
          </p>
          <br />
          <br />
          {this.state.isSubmitting ? <p>... Activating account ...</p> : null}
          <p>{this.state.errorMessage}</p>
          {this.state.errorMessage ? <Link to="/">Go home</Link> : null}
          <br />
          <hr />
          <br />
          <small>
            If you have any questions about using the product, feel free to
            message us at hello@mybestquote.com.
          </small>
          <small>
            This message confirms activation of account for the product or
            products listed above
          </small>
        </div>
      </div>
    )
  }
}
ActivateAccount.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      key: PropTypes.string
    })
  })
}

export default withRouter(ActivateAccount)
