import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

class PropertyProductBreakdown extends React.Component {
  render() {
    let product = this.props.product
    let premium = this.props.premium

    let { propertyPolicyInfo, houses } = product
    let { propertyType } = product
    let { startDate } = product

    let { duration } = propertyPolicyInfo

    let nfNoCurrency = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })

    const urlOptions = { T: 'travel', M: 'motor', L: 'life', P: 'property' }

    const policyTypes = {
      H: 'Home Owners',
      C: 'Commercial'
    }

    return (
      <div className="policy-breakdown">
        <div className="policy__header">
          <img
            alt=""
            className="policy__logo-img policy__logo-img--summary"
            src={product.company.logoUrl}
          />
        </div>
        <div className="policy__info policy__info--summary policy__info--breakdown">
          <p className="policy__category">{product.name}</p>
          <p className="text--muted mb-1rem">by {product.company.name}</p>

          <p className="policy-breakdown__premium mt-1rem">
            {premium ? (
              <span>
                Premium: {premium ? premium.currency : null}
                {nfNoCurrency.format(premium.amount)}
              </span>
            ) : null}
          </p>
        </div>

        <div />

        <hr />

        <div className="policy__features">
          <div className="policy__feature">
            <span>Policy type</span>
            <span>{policyTypes[propertyType]}</span>
          </div>
          <div className="policy__feature">
            <span>Start date</span>
            <span>{moment(startDate).format('ll')}</span>
          </div>
          <div className="policy__feature">
            <span>Duration</span>
            <span style={{ width: '300px' }}>
              {duration} months
              {/* - ({endDateApprox}) */}
            </span>
          </div>

          <div className="policy__feature">
            <span>Houses Insured</span>
            <span>{`${houses.length} ${
              houses.length > 1 ? 'houses' : 'house'
            }`}</span>
          </div>

          {/* <div className="policy__feature">
            <span>Excess bought back</span>
            <span>
              {propertyPolicyInfo.excessBoughtBack
                ? propertyPolicyInfo.excessBoughtBack
                : 'None'}
            </span>
          </div>
          <div className="policy__feature">
            <span>Extra cover</span>
            <span>
              {propertyPolicyInfo.extraCover ? propertyPolicyInfo.extraCover : 'None'}
            </span>
          </div> */}
        </div>

        <hr />

        {!this.props.isPurchasing ? (
          <div className="policy__buttons">
            <Link
              to={{
                pathname: `/${urlOptions[product.type]}/products/${
                  product.id
                }/details`,
                state: { id: product.id }
              }}
            >
              <button className="btn btn--buy btn--gradient-border">
                Buy Now
              </button>
            </Link>
          </div>
        ) : null}
      </div>
    )
  }
}
PropertyProductBreakdown.propTypes = {
  isPurchasing: PropTypes.bool,
  product: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    name: PropTypes.string,
    startDate: PropTypes.string,
    propertyPolicyInfo: PropTypes.shape({
      duration: PropTypes.number,
      excessBoughtBack: PropTypes.number,
      extraCover: PropTypes.string
    }),
    company: PropTypes.shape({
      logoUrl: PropTypes.string,
      name: PropTypes.string
    }),
    propertyType: PropTypes.string
  }),
  premium: PropTypes.number
}

export default connect(state => ({
  exchangeRate: state.payment.exchangeRate
}))(PropertyProductBreakdown)
