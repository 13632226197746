import React, { Component } from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { css } from 'react-emotion'
import { FadeLoader } from 'react-spinners'
import { connect } from 'react-redux'
import countryList from 'react-select-country-list'
import PropTypes from 'prop-types'
import { getHeaders, productsUrl } from '../../config'
import { Breadcrumb } from 'antd'

// import Header from 'components/Header'
// import ProductSearchSummary from "compoents/Product/ProductSearchSummary";
import TravelBuyForm from './TravelBuyForm'
import ProductBreakdown from 'components/Product/ProductBreakdown'
import { formatProducts, getPremiumFromAges } from 'utils/products'

class TripDetailsPage extends Component {
  state = {
    product: null,
    premium: null,
    search: null,
    travellerAges: []
  }

  UNSAFE_componentWillMount() {
    let search = this.props.search

    if (search.id > 0) {
      let searchCountries = []
      for (let i = 0; i < search.countries.length; i++) {
        searchCountries.push({
          value: search.countries[i],
          label: countryList().getLabel(search.countries[i])
        })
      }
      search.countries = searchCountries
      this.setState({
        search: search,
        travellerAges: search.travellerAges
      })
    }

    let id = this.props.match.params.id
    axios
      .get(`${productsUrl}/${id}/`, {
        headers: getHeaders()
      })
      .then(res => {
        let products = formatProducts([res.data.results], search)
        this.setState({ product: products[0] })
      })
  }

  setTravellerAges = ages => {
    this.setState({ travellerAges: ages }, () => {
      let premium = getPremiumFromAges(this.state.product.travelPremiums, ages)

      this.setState({
        premium: { currency: this.state.product.currency, amount: premium }
      })
    })
  }

  render() {
    let product = this.state.product

    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `
    return (
      <div className="container">
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            {/* <HomeOutlined /> */}
            <span>Home</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/buy-travel-insurance">
            Search Motor Policies
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/buy-travel-insurance/compare">
            Compare
          </Breadcrumb.Item>
          <Breadcrumb.Item>Trip Details</Breadcrumb.Item>
        </Breadcrumb>

        <div className="wrapper mt-5rem mb-5rem">
          {product ? (
            <div className="container">
              <div className="row reverse-md">
                <div className="col-md-7">
                  <TravelBuyForm
                    setTravellerAges={this.setTravellerAges}
                    search={this.state.search}
                    premiums={product.travelPremiums}
                  />
                </div>
                <div className="col-md-5 mb-2rem">
                  <p className="mb-2rem">Here's a breakdown of your policy:</p>
                  <ProductBreakdown
                    product={product}
                    premium={this.state.premium}
                    key={product.id}
                    isPurchasing={true}
                    travellerAges={this.state.travellerAges}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="site-content mb-10rem">
              <FadeLoader
                className={override}
                sizeUnit={'px'}
                size={150}
                color={'#13d1cf'}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}
TripDetailsPage.propTypes = {
  search: PropTypes.object,
  authUser: PropTypes.shape({
    token: PropTypes.string
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  })
}

export default withRouter(
  connect(state => ({
    search: state.search,
    authUser: state.authUser
  }))(TripDetailsPage)
)
