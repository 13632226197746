import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { css } from 'react-emotion'
import { FadeLoader } from 'react-spinners'
import * as Scroll from 'react-scroll'
import PropTypes from 'prop-types'

import PropertyProduct from 'components/Product/PropertyProduct'
import Modal from 'components/UI/Modal'
import Header from 'components/Header'
import MailingList from 'pages/MailingList'
import GetUserName from 'pages/GetUserName'
import PropertyProductSearch from './PropertyProductSearch'
import axios from 'axios/index'
import {
  clearProductToggles,
  setProducts,
  toggleProduct
} from 'components/Product/actions'
import { setSearchItem } from 'components/Search/actions'
import { getHeaders } from '../../config'
import { Breadcrumb } from 'antd'

let Element = Scroll.Element

class PropertyPage extends Component {
  state = {
    showModal: false,
    isSubmitting: false,
    searchInfo: null,
    companies: []
  }

  componentDidMount() {
    // let { data } = await axios({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_API_BASE}/api/v1/companies/featured-companies/`
    // })
    // let { results } = data
    // this.setState({ companies: results })
  }

  getSearchInfo = values => {
    let showModal = true
    if (this.props.authUser.token) showModal = false
    this.setState(
      {
        searchInfo: values,
        showModal: showModal,
        isSubmitting: !showModal
      },
      () => {
        if (!showModal) this.handleModal()
      }
    )
  }

  addUserInfo = values => {
    let searchInfo = this.state.searchInfo
    searchInfo = { ...searchInfo, ...values }
    this.setState({ searchInfo: searchInfo, showModal: false }, () => {
      this.handleModal()
    })
  }

  handleModal = () => {
    this.setState({ isSubmitting: true })
    const URL = `${process.env.REACT_APP_API_BASE}/api/v1/search/travel/`
    let data = Object.assign({}, this.state.searchInfo)
    data.countries = data.countries.map(country => country.value.toLowerCase())
    data.travellerAges = data.travellerAges.map(age => age.value)
    data.isFamily = data.travellerType === 'F'
    data.isSingleTrip = data.coverType === 'S'

    let headers = {}
    let token = this.props.authUser.token
    if (token) {
      headers['Authorization'] = `JWT ${token}`
    }

    axios({
      method: 'post',
      url: URL,
      data: data,
      headers: getHeaders()
    })
      .then(res => {
        this.props.setSearchItem(res.data.results)
        this.props.history.push(`/travel/search/${res.data.results.id}`)
      })
      .catch(() => {
        this.setState({ isSubmitting: false })
      })
  }

  handleCloseModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    const { propertyProducts } = this.props
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
      height: 10px;
    `

    return (
      <div className="container">
        <div className="ml-3rem">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Buy Home Insurance</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Header>
          <PropertyProductSearch />
        </Header>

        <main className="site-content">
          <Element name="propertySearchResults">
            <div className="v-spacer wrapper--lead mt-5rem">
              <div className="row site-content--spaced">
                <div className=" site-content__lead--left">
                  <h3 className="site-content__lead">
                    Home policy search results
                  </h3>
                </div>
              </div>
            </div>

            {this.state.isSubmitting ? (
              <FadeLoader
                className={override}
                sizeUnit={'px'}
                size={150}
                color={'#13d1cf'}
              />
            ) : (
              <div className="site-content policy-list">
                {!propertyProducts.length ? (
                  <div className="mt-5rem mb-10rem">
                    <p className="site-content__subtitle">No results</p>
                  </div>
                ) : (
                  this.props.propertyProducts.map((product, id) => (
                    <PropertyProduct
                      key={id}
                      id={id}
                      company={product.company}
                      product={product.preview}
                      payload={product.payload}
                    />
                  ))
                )}
              </div>
            )}
          </Element>

          <MailingList />

          <Modal
            show={this.state.showModal}
            modalStyle="modal"
            backdropStyle="backdrop"
            handleCloseModal={this.handleCloseModal}
          >
            <GetUserName handleSubmit={this.addUserInfo} />
          </Modal>
        </main>
      </div>
    )
  }
}
PropertyPage.propTypes = {
  authUser: PropTypes.shape({
    token: PropTypes.string
  }),
  setSearchItem: PropTypes.func,
  history: PropTypes.object,
  propertyProducts: PropTypes.array
}

export default withRouter(
  connect(
    state => ({
      propertyProducts: state.propertyProducts,
      authUser: state.authUser
    }),
    dispatch => ({
      setProducts: products => dispatch(setProducts(products)),
      toggleProduct: productId => dispatch(toggleProduct(productId)),
      clearProductToggles: () => dispatch(clearProductToggles()),
      setSearchItem: search => dispatch(setSearchItem(search))
    })
  )(PropertyPage)
)
