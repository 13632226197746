import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from 'axios'
import PropTypes from 'prop-types'
import { getHeaders } from '../../config'

import { toggleProduct } from './actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

class ProductProduct extends React.Component {
  state = {
    isSubmitting: false
  }

  handleBuyPolicy = async id => {
    if (this.props.authUser.token) {
      try {
        this.setState({ isSubmitting: true })
        let data = {
          ...this.props.payload,
          companyId: id
        }
        let res = await axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_BASE}/api/v1/property-policies/`,
          data,
          headers: getHeaders()
        })

        if (res.status >= '199' && res.status <= '299') {
          let id = res.data.results.id
          this.props.history.push({
            pathname: `/payments/new/${id}/`,
            state: { id, type: 'property' }
          })
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.setState({ isSubmitting: false })
      }
    } else {
      this.props.history.push({
        pathname: '/auth/login',
        state: { from: { pathname: '/property' } }
      })
    }
  }

  render() {
    let company = this.props.company
    let { premium } = this.props.product

    let nfNoCurrency = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })

    return (
      <div className="policy mt-2rem ml-2rem mr-2rem mb-2rem">
        <div className="policy__header">
          <img
            alt={company.name}
            className="policy__logo-img"
            src={company.logoUrl}
            style={{ bottom: 'unset', top: '2.5rem' }}
          />
        </div>
        <div className="policy__info">
          <p className="policy__category">
            Property Insurance{' '}
            <i style={{ color: 'grey' }}>
              <FontAwesomeIcon icon={faCheckCircle} />
            </i>
          </p>
          <p className="text--muted" style={{ marginTop: '5px' }}>
            by {company.name}
          </p>
        </div>

        <div className="policy__premium mb-3rem">
          <span>PREMIUM</span>
          <span className="policy__premium--value">
            {premium ? premium.currency : null}
            {premium ? nfNoCurrency.format(premium.amount) : null}
          </span>
          <span style={{ minWidth: '40px' }}>&nbsp;</span>
        </div>

        <div className="policy__buttons">
          {company.agreementAccepted ? (
            <button
              onClick={() => this.handleBuyPolicy(company.id)}
              className="btn btn--buy btn--gradient-border mb-1rem"
            >
              {this.state.isSubmitting ? 'Submitting...' : 'Buy Now'}
            </button>
          ) : (
            <p
              className="text--muted mt-1rem mb-2rem"
              style={{ fontSize: '1.2rem' }}
            >
              Coming soon
            </p>
          )}
          {/* <small>
            <p
              style={{ cursor: 'pointer' }}
              className="text--muted"
              onClick={this.handleLearnMore}
            >
              Learn more
            </p>
          </small> */}
        </div>
      </div>
    )
  }
}
ProductProduct.propTypes = {
  payload: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  authUser: PropTypes.shape({
    token: PropTypes.string
  }),
  product: PropTypes.shape({
    premium: PropTypes.number
  }),
  company: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    logoUrl: PropTypes.string,
    agreementAccepted: PropTypes.bool
  })
}

export default connect(
  state => ({
    exchangeRate: state.payment.exchangeRate,
    authUser: state.authUser
  }),

  dispatch => ({
    toggleProduct: productId => dispatch(toggleProduct(productId))
  })
)(withRouter(ProductProduct))
