import { setPayment } from '../Payments/actions'
import {
  getHeaders,
  errorMessage,
  lifeUrl,
  healthUrl,
  propertyUrl,
  motorUrl,
  travelUrl
} from '../../config'
import axios from 'axios'

export const initiatePayment = (policyType, policyId) => {
  let paymentURL = `${travelUrl}/${policyId}/pay`

  if (policyType === 'motor') {
    paymentURL = `${motorUrl}/${policyId}/pay`
  }

  if (policyType === 'property') {
    paymentURL = `${propertyUrl}/${policyId}/pay`
  }

  if (policyType === 'health') {
    paymentURL = `${healthUrl}/${policyId}/pay`
  }

  if (policyType === 'life') {
    paymentURL = `${lifeUrl}/${policyId}/pay`
  }

  axios
    .post(paymentURL, {}, { headers: getHeaders() })
    .then(res => {
      let transaction = res.data.data
      console.log(transaction)
      setPayment(transaction)
    })
    .catch(err => {
      if (err.response) {
        errorMessage('Oops! Something went wrong, kindly retry.')
      } else {
        errorMessage('Error connecting, kindly check your internet connection.')
      }
    })
}
