import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { css } from 'react-emotion'
import { FadeLoader } from 'react-spinners'
import PropTypes from 'prop-types'
import { getHeaders, motorUrl } from '../../config'

// import Header from 'components/Header'
// import ProductSearchSummary from "compoents/Product/ProductSearchSummary";
import MotorBuyForm from 'components/Forms/MotorBuyForm'
// import { formatProducts, getPremiumFromAges } from 'utils/products'
import MotorProductBreakdown from 'components/Product/MotorProductBreakdown'

const MotorDetails = props => {
  const [policy, setPolicy] = useState(null)

  useEffect(() => {
    axios
      .get(`${motorUrl}/${props.match.params.id}/get`, {
        headers: getHeaders()
      })
      .then(res => {
        setPolicy(res.data)
      })
    // eslint-disable-next-line
  }, [])

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `

  return (
    <div>
      {/* <Header /> */}

      <div className="mt-5rem mb-5rem">
        {policy ? (
          <div className="row reverse-md">
            <div className="col-md-7">
              <MotorBuyForm policy={policy} />
            </div>
            <div className="col-md-5 mt-5rem mb-2rem">
              <p className="mb-2rem">Here's a breakdown of your policy:</p>
              <MotorProductBreakdown
                product={policy}
                premium={policy.premium}
                isPurchasing={true}
              />
            </div>
          </div>
        ) : (
          <div className="site-content mb-10rem">
            <FadeLoader
              className={override}
              sizeUnit={'px'}
              size={150}
              color={'#13d1cf'}
            />
          </div>
        )}
      </div>
    </div>
  )
}

MotorDetails.propTypes = {
  search: PropTypes.object,
  authUser: PropTypes.shape({
    token: PropTypes.string
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  })
}

export default withRouter(MotorDetails)
