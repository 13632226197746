// import Navbar from 'components/Navbar'
import React, { useEffect, useState } from 'react'
import PolicyCard from 'components/Cards/PolicyCard'
import GetHelpCard from 'components/Cards/GetHelpCard'
import { getHeaders, errorMessage, lifeUrl } from '../../config'
import axios from 'axios'
import WithProducts from 'hocs/WithProducts'
import { Link } from 'react-router-dom'
import WithUser from 'hocs/WithUser'
import carIcon from 'img/motor/city_driver.svg'
// import noticeIcon from 'img/motor/notice.svg'
import helpImg from 'img/get_help_image.png'
import helpImgMobile from 'img/get_help_image_mobile.png'

const LifeCompare = props => {
  const [policies, setPolicies] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleBuy = policy => {
    if (props.authUser.token) {
      setIsSubmitting(true)
      let data = {
        ...policy.payload,
        companyId: policy.companyId,
        planId: policy.planId
      }

      axios
        .post(lifeUrl, data, { headers: getHeaders() })
        .then(res => {
          let id = res.data.data.id
          props.history.push(`/buy-life-insurance/${id}/buy`)
          setIsSubmitting(false)
        })
        .catch(err => {
          setIsSubmitting(false)
          if (err.response) {
            errorMessage('Oops! Something went wrong, kindly retry.')
          } else {
            errorMessage(
              'Error connecting, kindly check your internet connection.'
            )
          }
        })
    } else {
      props.history.push({
        pathname: '/auth/login',
        state: { from: { pathname: '/buy-life-insurance/compare' } }
      })
    }
  }

  const formatProducts = products => {
    let prods = []

    // eslint-disable-next-line
    products.map((p, i) => {
      prods.push({
        isPolicy: true,
        payload: p.payload,
        premium: {
          currency: p.preview.lifePlan.premiumCurrency,
          amount:
            p.payload.formType === 'premium'
              ? p.preview.sumAssured
              : p.preview.premium
        },
        formType:
          p.payload.formType === 'premium'
            ? `Benefits at a premium of ${p.preview.lifePlan.premiumCurrency} ${p.preview.premium}`
            : `Monthly Contributions with benefits of ${p.preview.lifePlan.premiumCurrency} ${p.preview.sumAssured}`,
        planName: p.preview.lifePlan.name,
        companyLogo: p.company.logoUrl,
        companyName: p.company.name,
        companyId: p.company.id,
        lifePlan: p.preview.lifePlan,
        planId: p.preview.lifePlan.id,
        compare: false,
        showDetail: false
      })

      if (i === 3) {
        prods.push({
          isPolicy: false
        })
      }
    })

    return prods
  }

  useEffect(() => {
    if (props.lifeProducts.length) {
      setPolicies(formatProducts(props.lifeProducts))
    }
    // eslint-disable-next-line
  }, [])

  return (
    <React.Fragment>
      {/* <Navbar /> */}
      <div className="motor_compare">
        <div className="motor_compare_header">
          {policies.length ? (
            <h2>
              You're viewing{' '}
              <span style={{ color: '#009B9C' }}>Life Insurance</span>{' '}
              {policies[0].formType} for
            </h2>
          ) : (
            <p>
              Please provide information about your life cover{' '}
              <Link to="/buy-life-insurance">here</Link>
            </p>
          )}
        </div>

        {policies.length ? (
          <>
            <div className="motor_compare_card">
              <img src={carIcon} alt="compare card" />
              <div className="motor_compare_card_caption">
                <h3>{`${policies[0].planName}`}</h3>
                {/* <p>
                  <span>
                    Car Reg. #: {policies[0].vehicle.registrationNumber}
                  </span>
                </p> */}

                <p
                  style={{
                    display: 'inline-flex',
                    backgroundColor: '#3f3d56',
                    padding: '0.5rem 1rem',
                    float: 'right',
                    marginTop: '2rem',
                    borderRadius: '5px',
                    fontWeight: '700',
                    cursor: 'pointer'
                  }}
                >
                  <Link to="/buy-life-insurance">
                    <span style={{ color: '#f2f2f2' }}>Edit Details</span>
                  </Link>
                </p>
              </div>
            </div>

            <div className="motor_compare_policies">
              {/* <div className="motor_compare_policies_warning">
                <img src={noticeIcon} alt="notice" />
                <p>Click on the compare button to start comparing</p>
              </div> */}

              <div className="motor_compare_policies_list">
                {policies.map(policy =>
                  policy.isPolicy ? (
                    <div className="motor_compare_policies_list_card mb-3rem">
                      <PolicyCard
                        policy={policy}
                        handleBuy={handleBuy.bind(this, policy)}
                        isSubmitting={isSubmitting}
                      />
                    </div>
                  ) : (
                    <div className="motor_compare_policies_list_help mb-3rem">
                      <GetHelpCard image={helpImg} />
                    </div>
                  )
                )}

                {policies.map(policy =>
                  policy.isPolicy ? (
                    <div className="motor_compare_policies_list_card_mobile mb-3rem">
                      <PolicyCard
                        policy={policy}
                        handleBuy={handleBuy.bind(this, policy)}
                        isSubmitting={isSubmitting}
                      />
                    </div>
                  ) : (
                    <div className="motor_compare_policies_list_help_mobile mb-3rem">
                      <GetHelpCard image={helpImgMobile} />
                    </div>
                  )
                )}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </React.Fragment>
  )
}

export default WithProducts(WithUser(LifeCompare))
