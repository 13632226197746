import React, { Component } from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import axios from 'axios'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import info from 'img/info.svg'
import { Tooltip } from 'react-tippy'
import Scroll from 'react-scroll'
import { setPropertyProducts } from 'components/Product/actions'
import WithRegion from 'hocs/WithRegion'
import { getHeaders } from '../../config'
import { getCookie } from 'utils/urls'

let scroller = Scroll.scroller

class PropertyProductSearch extends Component {
  constructor(props) {
    super(props)
    let residentNumber = 1
    let houseNumber = 1

    let resident = {
      houseId: null,
      name: '',
      dob: null
    }

    let residents = [
      {
        houseId: 0,
        name: '',
        dob: null
      }
    ]

    for (let i = 0; i < residentNumber - 1; i++) {
      residents.push(Object.assign({}, resident))
    }

    let house = {
      value: null,
      address: '',
      location: '',
      builtWith: '',
      roofedWith: '',
      hasFireExtinguisher: false,
      hasDamageHistory: false,
      residentialPurpose: false,
      businessPurpose: false,
      residents: [],
      thirdPartySumAssured: null,
      personalSumAssured: null,
      morgageCompanyInfo: '',
      machineryCost: ''
    }

    let houses = [
      {
        value: null,
        address: '',
        location: '',
        builtWith: '',
        roofedWith: '',
        hasFireExtinguisher: false,
        hasDamageHistory: false,
        residentialPurpose: false,
        businessPurpose: false,
        residents: [],
        thirdPartySumAssured: null,
        personalSumAssured: null,
        morgageCompanyInfo: '',
        machineryCost: ''
      }
    ]

    for (let i = 0; i < houseNumber - 1; i++) {
      houses.push(Object.assign({}, house))
    }

    this.state = {
      propertyType: '',
      extentOfCover: [],
      duration: 12,
      natureOfBusiness: '',
      startDate: null,
      houses: houses,
      residents: residents,
      isSubmitting: false,
      errorMessage: {},
      coverOptions: [],
      showMachineryCost: false,
      showThirdPartySumAssured: false,
      showPersonalSumAssured: false,
      region: '',
      referrer: '',
      affiliateReferrer: false
    }
  }

  handleSelectUpdate = stateName => obj => {
    if (obj) {
      this.setState({ [stateName]: obj.value })
    } else {
      this.setState({ [stateName]: '' })
    }

    // remove form field errors
    if (this.state.errorMessage[stateName]) {
      this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          [stateName]: null,
          nonFieldErrors: null
        }
      }))
    }
  }

  handleFieldUpdate = stateName => event => {
    this.setState({ [stateName]: event.target.value })

    // remove form field errors
    if (this.state.errorMessage[stateName]) {
      this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          [stateName]: null,
          nonFieldErrors: null
        }
      }))
    }
  }

  handleRegion = stateName => obj => {
    if (obj) {
      this.setState({ [stateName]: obj.value }, () => {
        this.props.setRegion(this.state.region)
      })
    } else {
      this.setState({ [stateName]: '' })
    }
  }

  handleDateUpdate = stateName => date => {
    this.setState({ [stateName]: date })

    // remove form field errors
    if (this.state.errorMessage[stateName]) {
      this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          [stateName]: null,
          nonFieldErrors: null
        }
      }))
    }
  }

  handleDateChangeRaw = e => {
    e.preventDefault()
  }

  checkForMachineryCover = options => {
    for (let i = 0; i < options.length; i++) {
      let optionTitle = options[i].title.toLowerCase()
      if (optionTitle.includes('machinery')) {
        return true
      }
    }
    return false
  }

  checkForPersonalSumAssuredCover = options => {
    for (let i = 0; i < options.length; i++) {
      let optionTitle = options[i].title.toLowerCase()
      if (optionTitle.includes('personal')) {
        return true
      }
    }
    return false
  }

  checkForThirdPartySumAssuredCover = options => {
    for (let i = 0; i < options.length; i++) {
      let optionTitle = options[i].title.toLowerCase()
      if (optionTitle.includes('third')) {
        return true
      }
    }
    return false
  }

  checkForBuildingCover = options => {
    for (let i = 0; i < options.length; i++) {
      let optionTitle = options[i].title.toLowerCase()
      if (optionTitle === 'building') {
        return true
      }
    }
    return false
  }

  setDefaultOptions = options => {
    const building = this.state.coverOptions.filter(
      obj => obj.title.toLowerCase() === 'building'
    )

    if (this.checkForBuildingCover(options)) {
      return options
    } else {
      let updatedOptions = this.state.extentOfCover
      updatedOptions.concat(building)
      return updatedOptions
    }
  }

  handleCoverUpdate = selectedOption => {
    const updatedOptions = this.setDefaultOptions(selectedOption)
    this.setState({ extentOfCover: updatedOptions }, () => {
      this.setState({
        showMachineryCost: this.checkForMachineryCover(this.state.extentOfCover)
      })

      this.setState({
        showPersonalSumAssured: this.checkForPersonalSumAssuredCover(
          this.state.extentOfCover
        )
      })

      this.setState({
        showThirdPartySumAssured: this.checkForThirdPartySumAssuredCover(
          this.state.extentOfCover
        )
      })
    })

    // remove form field errors
    if (this.state.errorMessage['extentOfCover']) {
      this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          extentOfCover: null,
          nonFieldErrors: null
        }
      }))
    }
  }

  // house functions
  handleHouseSelectUpdate = (stateName, id) => obj => {
    const updatedHouses = this.state.houses.map((house, houseId) => {
      // maintain other houses.
      if (id !== houseId) return house
      // update this house.
      if (obj) {
        return { ...house, [stateName]: obj.value }
      } else {
        return { ...house, [stateName]: '' }
      }
    })
    this.setState({ houses: updatedHouses })

    // update house errors
    if (this.state.errorMessage.houses) {
      const updatedHousesErrors = this.state.errorMessage.houses.map(h => {
        // maintain other house errors
        if (id !== h.houseId) return h

        return { ...h, [stateName]: null }
      })

      this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          houses: updatedHousesErrors,
          nonFieldErrors: null
        }
      }))
    }
    // end update house errors
  }

  handleHouseFieldUpdate = id => event => {
    const updatedHouses = this.state.houses.map((house, houseId) => {
      // maintain other houses.
      if (id !== houseId) return house
      // update this house.
      return { ...house, [event.target.name]: event.target.value }
    })
    this.setState({ houses: updatedHouses })

    // update house errors
    if (this.state.errorMessage.houses) {
      const updatedHousesErrors = this.state.errorMessage.houses.map(h => {
        // maintain other house errors
        if (id !== h.houseId) return h

        return { ...h, [event.target.name]: null }
      })

      this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          houses: updatedHousesErrors,
          nonFieldErrors: null
        }
      }))
    }
    // end update house errors
  }

  handleHouseCheckbox = id => event => {
    const updatedHouses = this.state.houses.map((house, houseId) => {
      // maintain other houses.
      if (id !== houseId) return house
      // update this house.
      return { ...house, [event.target.name]: !house[event.target.name] }
    })
    this.setState({ houses: updatedHouses })
  }

  handleAddHouse = event => {
    event.preventDefault()
    console.log('current houses ' + this.state.houses.length)
    this.setState(
      {
        houses: this.state.houses.concat([
          {
            value: null,
            address: '',
            location: '',
            builtWith: '',
            roofedWith: '',
            hasFireExtinguisher: false,
            hasDamageHistory: false,
            residents: [],
            thirdPartySumAssured: null,
            personalSumAssured: null,
            morgageCompanyInfo: '',
            machineryCost: ''
          }
        ])
      },
      () => {
        console.log('current houses ' + this.state.houses.length)
        // add new resident for new house
        this.setState({
          residents: this.state.residents.concat([
            {
              houseId: this.state.houses.length - 1,
              name: '',
              dob: null
            }
          ])
        })
      }
    )
  }

  handleRemoveHouse = id => event => {
    event.preventDefault()
    this.setState(
      {
        houses: this.state.houses.filter((house, houseId) => id !== houseId)
      },
      // remove all residents relating to this house
      this.setState({
        residents: this.state.residents.filter(res => res.houseId !== id)
      })
    )
  }
  // end house functions

  // resident functions
  handleResidentBirthdateUpdate = (stateName, id) => date => {
    let newDate = moment(date).format('YYYY-MM-DD')
    const updatedResidents = this.state.residents.map((res, resId) => {
      if (id !== resId) return res
      return { ...res, [stateName]: newDate }
    })
    this.setState({ residents: updatedResidents })

    // update residents errors
    if (this.state.errorMessage.residents) {
      const updatedResidentsErrors = this.state.errorMessage.residents.map(
        res => {
          // maintain other resident errors
          if (id !== res.resId) return res

          return { ...res, [stateName]: null }
        }
      )

      this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          residents: updatedResidentsErrors,
          nonFieldErrors: null
        }
      }))
    }
    // end update residents errors
  }

  handleResidentInputUpdate = (stateName, id) => event => {
    const updatedResidents = this.state.residents.map((res, resId) => {
      // retain data of other residents
      if (id !== resId) return res
      // update this resident
      return { ...res, [stateName]: event.target.value }
    })
    this.setState({ residents: updatedResidents })

    // update residents errors
    if (this.state.errorMessage.residents) {
      const updatedResidentsErrors = this.state.errorMessage.residents.map(
        res => {
          // maintain other resident errors
          if (id !== res.resId) return res

          return { ...res, [stateName]: null }
        }
      )

      this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          residents: updatedResidentsErrors,
          nonFieldErrors: null
        }
      }))
    }
    // end update residents errors
  }

  handleAddResident = houseId => event => {
    event.preventDefault()
    this.setState({
      residents: this.state.residents.concat([
        {
          houseId,
          name: '',
          dob: null
        }
      ])
    })
  }

  handleRemoveResident = id => event => {
    event.preventDefault()
    this.setState({
      residents: this.state.residents.filter((res, resId) => id !== resId)
    })
  }
  // end resident functions

  getHouseResidents = houseId => {
    let houseResidents = this.state.residents.filter(
      res => res.houseId === houseId
    )
    return houseResidents
  }

  getHouses() {
    let houses = this.state.houses.map((house, houseId) => {
      return {
        ...house,
        residents:
          this.state.propertyType === 'H' ? this.getHouseResidents(houseId) : []
      }
    })
    return houses
  }

  scrollTo = () => {
    scroller.scrollTo('propertySearchResults', {
      duration: 300,
      delay: 100,
      smooth: true,
      offset: 50
    })
  }

  handleSubmit = async event => {
    event.preventDefault()
    let payload = {
      duration: this.state.duration,
      propertyType: this.state.propertyType,
      extentOfCover: this.state.extentOfCover.map(cov => {
        return cov.id
      }),
      natureOfBusiness: this.state.natureOfBusiness,
      startDate: this.state.startDate
        ? moment(this.state.startDate).format('YYYY-MM-DD')
        : '',
      referrer: this.state.referrer,
      houses: this.getHouses()
    }

    this.setState({ isSubmitting: true })
    try {
      let {
        data: { results }
      } = await axios({
        url: `${process.env.REACT_APP_API_BASE}/api/v1/property-policies/preview/`,
        method: 'post',
        data: payload,
        headers: getHeaders()
      })

      await this.props.setPropertyProducts(results)
      this.scrollTo()
      this.setState({ isSubmitting: false })
    } catch (err) {
      this.setState({ isSubmitting: false })
      if (err.response) {
        err.response.status === 400
          ? this.setState(prevState => ({
              errorMessage: {
                ...err.response.data,
                nonFieldErrors: ['Solve form errors above']
              }
            }))
          : this.setState({
              errorMessage: {
                nonFieldErrors: ['Oops, server Error! Retry']
              }
            })
      } else {
        this.setState(prevState => ({
          errorMessage: {
            ...prevState.errorMessage,
            nonFieldErrors: ['Error Connecting to Server, Retry.']
          }
        }))
      }
    }
  }

  async componentDidMount() {
    try {
      let resp = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_BASE}/api/v1/property-policies/covers/`,
        headers: getHeaders()
      })
      this.setState(
        { coverOptions: resp.data, region: this.props.region },
        () => {
          // set building cover by default to extent of cover
          const building = resp.data.filter(
            obj => obj.title.toLowerCase() === 'building'
          )
          this.setState({ extentOfCover: building })
        }
      )
    } catch (err) {
      console.log('Covers Error')
    }

    // initialize referrer code if available.
    if (getCookie('referrer')) {
      this.setState({
        affiliateReferrer: true,
        referrer: getCookie('referrer')
      })
    }

    const { propertyProducts } = this.props
    propertyProducts.length && this.scrollTo()
  }

  // componentDidUpdate(prevProps, prevState) {
  componentDidUpdate() {
    // this.props.setTravellerAges(this.getAges())
  }

  render() {
    const propertyTypeOptions = [
      { label: 'Homeowners', value: 'H' },
      { label: 'Commercial', value: 'C' }
    ]

    const roofingOptions = [
      { label: 'Aluminium', value: 'ALUMINIUM' },
      { label: 'Slate', value: 'SLATE' },
      { label: 'Asphalt', value: 'ASPHALT' },
      { label: 'Wood', value: 'WOOD' }
    ]

    const constructionMaterialOption = [
      { label: 'Glass', value: 'GLASS' },
      { label: 'Concrete', value: 'CONCRETE' },
      { label: 'Gypsum', value: 'GYPSUM' },
      { label: 'Wood', value: 'WOOD' },
      { label: 'Metal', value: 'METAL' },
      { label: 'Marble', value: 'MARBLE' },
      { label: 'Clay', value: 'CLAY' }
    ]

    return (
      <div className="wrapper mt-5rem mb-5rem">
        <div className="row reverse-md">
          <div className="col-md-8 col-md-offset-3">
            <form onSubmit={this.handleSubmit} className="form">
              <h3 className="site-content__lead text-primary">
                Enter Home Details, Get Best Offers. No Long Talk!
              </h3>
              {/* <p className="text--muted">Add a few details about your trip</p> */}

              <div className="mt-2rem form__inputs">
                {/* country */}
                {/* <div className="form__wrapper">
                  <label className="form__label mt-1rem mb-1rem">
                    Select Your Country
                  </label>
                  <Select
                    required
                    value={this.state.region}
                    name="region"
                    onChange={this.handleRegion('region')}
                    options={this.props.regions}
                  />
                </div> */}
                {/* end country */}

                {/* property types */}
                <div className="form__wrapper">
                  <label className="form__label mt-1rem mb-1rem">
                    Select type of cover
                    <Tooltip
                      size="big"
                      title="Welcome to React"
                      position="right"
                      trigger="mouseenter click"
                      html={
                        <div style={{ textAlign: 'left' }}>
                          <div style={{ paddingBottom: '.5rem' }}>
                            Homeowners - Covers losses and damages to an
                            individual's residence along with their assets.
                          </div>
                          <div>
                            Commercial - Covers losses and damages to commercial
                            buildings along with their assets.
                          </div>
                        </div>
                      }
                    >
                      <img
                        style={{
                          maxHeight: '1.4rem',
                          marginLeft: '.5rem'
                        }}
                        src={info}
                        alt="info"
                      />
                    </Tooltip>
                  </label>
                  <Select
                    required
                    value={this.state.propertyType}
                    name="propertyType"
                    onChange={this.handleSelectUpdate('propertyType')}
                    options={propertyTypeOptions}
                  />
                  {this.state.errorMessage.propertyType
                    ? this.state.errorMessage.propertyType.map(err => (
                        <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                      ))
                    : null}
                </div>
                {/* end property types */}

                {/* extent of cover */}
                <div className="form__wrapper">
                  <label className="form__label mt-1rem mb-1rem">
                    Extent of Cover
                    <Tooltip
                      size="big"
                      title="Welcome to React"
                      position="right"
                      trigger="mouseenter click"
                      html={
                        <div style={{ textAlign: 'left' }}>
                          <div>
                            Determines how your building gets insured and the
                            claim benefits.
                          </div>
                        </div>
                      }
                    >
                      <img
                        style={{
                          maxHeight: '1.4rem',
                          marginLeft: '.5rem'
                        }}
                        src={info}
                        alt="info"
                      />
                    </Tooltip>
                  </label>
                  <Select
                    required
                    value={this.state.extentOfCover}
                    onChange={this.handleCoverUpdate}
                    options={this.state.coverOptions}
                    multi
                  />
                  {this.state.errorMessage.extentOfCover
                    ? this.state.errorMessage.extentOfCover.map(err => (
                        <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                      ))
                    : null}
                </div>
                {/* end extent of cover */}
              </div>

              <div className="mt-2rem form__inputs">
                {/* start date */}
                <div className="form__wrapper">
                  <div>
                    <label className="form__label mt-1rem mb-1rem">
                      Start Date
                    </label>
                    <DatePicker
                      selected={this.state.startDate}
                      placeholderText="DD/MM/YYYY"
                      dateFormat="DD/MM/YYYY"
                      minDate={moment(new Date())}
                      className="form__input"
                      onChange={this.handleDateUpdate('startDate')}
                      onChangeRaw={this.handleDateChangeRaw}
                    />
                    {this.state.errorMessage.startDate
                      ? this.state.errorMessage.startDate.map(err => (
                          <p style={{ color: 'red', marginTop: '1em' }}>
                            {err}
                          </p>
                        ))
                      : null}
                  </div>
                </div>

                {/* nature of business */}
                {this.state.propertyType === 'C' && (
                  <div className="form__wrapper">
                    <label className="form__label mt-1rem mb-1rem">
                      Nature of Business
                    </label>
                    <textarea
                      cols="100"
                      rows="5"
                      className="form__input mr-2rem"
                      value={this.state.natureOfBusiness}
                      name="natureOfBusiness"
                      placeholder="eg: real estate, etc."
                      onChange={this.handleFieldUpdate('natureOfBusiness')}
                    />

                    {this.state.errorMessage.natureOfBusiness
                      ? this.state.errorMessage.natureOfBusiness.map(err => (
                          <p style={{ color: 'red', marginTop: '1em' }}>
                            {err}
                          </p>
                        ))
                      : null}
                  </div>
                )}
              </div>

              {/* <div className="mt-2rem form__inputs">
                
              </div> */}

              {/* building information and residents */}
              <h3 className="mt-5rem site-content__lead">
                Building Information
              </h3>
              {/* <p className="text--muted">Add information on who's making the trip</p> */}

              {this.state.houses.map((house, houseId) => (
                <div key={houseId}>
                  <div className="mt-3rem">
                    {this.state.houses.length > 1 ? (
                      <h4>House {houseId + 1}</h4>
                    ) : null}
                  </div>

                  {/* property and address */}
                  <div className="mt-2rem form__inputs">
                    <div className="form__wrapper">
                      <label className="form__label mt-1rem mb-1rem">
                        House Address
                      </label>
                      <textarea
                        rows="5"
                        cols="100"
                        className="form__input mr-2rem"
                        value={house.address}
                        name="address"
                        placeholder="eg: N41, Adabraka."
                        onChange={this.handleHouseFieldUpdate(houseId)}
                      />

                      {this.state.errorMessage.houses
                        ? this.state.errorMessage.houses.map(
                            err =>
                              err.houseId === houseId && (
                                <p style={{ color: 'red', marginTop: '1em' }}>
                                  {err.address}
                                </p>
                              )
                          )
                        : null}
                    </div>

                    <div className="form__wrapper">
                      <label className="form__label mt-1rem mb-1rem">
                        House Location (GPS Address)
                      </label>
                      <textarea
                        rows="5"
                        cols="100"
                        className="form__input mr-2rem"
                        value={house.location}
                        name="location"
                        placeholder="eg: GM-XX-XX-XXX."
                        onChange={this.handleHouseFieldUpdate(houseId)}
                      />

                      {this.state.errorMessage.houses
                        ? this.state.errorMessage.houses.map(
                            err =>
                              err.houseId === houseId && (
                                <p style={{ color: 'red', marginTop: '1em' }}>
                                  {err.location}
                                </p>
                              )
                          )
                        : null}
                    </div>
                  </div>
                  {/* end property and address */}

                  {/* building materials */}
                  <div className="mt-2rem form__inputs">
                    <div className="form__wrapper">
                      <label className="form__label mt-1rem mb-1rem">
                        Built With
                      </label>
                      <Select
                        required
                        value={house.builtWith}
                        name="builtWith"
                        onChange={this.handleHouseSelectUpdate(
                          'builtWith',
                          houseId
                        )}
                        options={constructionMaterialOption}
                      />

                      {this.state.errorMessage.houses
                        ? this.state.errorMessage.houses.map(
                            err =>
                              err.houseId === houseId && (
                                <p style={{ color: 'red', marginTop: '1em' }}>
                                  {err.builtWith}
                                </p>
                              )
                          )
                        : null}
                    </div>

                    <div className="form__wrapper">
                      <div>
                        <label className="form__label mt-1rem mb-1rem">
                          Roofed With
                        </label>
                        <Select
                          required
                          value={house.roofedWith}
                          onChange={this.handleHouseSelectUpdate(
                            'roofedWith',
                            houseId
                          )}
                          options={roofingOptions}
                        />

                        {this.state.errorMessage.houses
                          ? this.state.errorMessage.houses.map(
                              err =>
                                err.houseId === houseId && (
                                  <p style={{ color: 'red', marginTop: '1em' }}>
                                    {err.roofedWith}
                                  </p>
                                )
                            )
                          : null}
                      </div>
                    </div>
                  </div>
                  {/* end building materials */}

                  {/* morgagee info, value */}
                  <div className="mt-2rem form__inputs">
                    <div className="form__wrapper">
                      <label className="form__label mt-1rem mb-1rem">
                        Value of Your Home (GHS)
                        <Tooltip
                          size="big"
                          title="Welcome to React"
                          position="right"
                          trigger="mouseenter click"
                          html={
                            <div style={{ textAlign: 'left' }}>
                              <div>How much is your building worth in GHS?</div>
                            </div>
                          }
                        >
                          <img
                            style={{
                              maxHeight: '1.4rem',
                              marginLeft: '.5rem'
                            }}
                            src={info}
                            alt="info"
                          />
                        </Tooltip>
                      </label>
                      <input
                        type="number"
                        className="form__input mr-2rem"
                        value={house.value}
                        name="value"
                        placeholder="eg: 20000"
                        onChange={this.handleHouseFieldUpdate(houseId)}
                      />

                      {this.state.errorMessage.houses
                        ? this.state.errorMessage.houses.map(
                            err =>
                              err.houseId === houseId && (
                                <p style={{ color: 'red', marginTop: '1em' }}>
                                  {err.value}
                                </p>
                              )
                          )
                        : null}
                    </div>

                    <div className="form__wrapper">
                      <label className="form__label mt-1rem mb-1rem">
                        Name of Morgagee (if any)
                      </label>
                      <input
                        className="form__input mr-2rem"
                        value={house.morgageCompanyInfo}
                        name="morgageCompanyInfo"
                        placeholder="eg: John and Doe LTD"
                        onChange={this.handleHouseFieldUpdate(houseId)}
                      />
                    </div>
                  </div>
                  {/* end morgagee info, value */}

                  {/* machinery cost, sum assured */}
                  <div className="mt-2rem form__inputs">
                    {this.state.showMachineryCost && (
                      <div className="form__wrapper">
                        <label className="form__label mt-1rem mb-1rem">
                          Value of Machinery and Content of Building (GHS)
                          <Tooltip
                            size="big"
                            title="Welcome to React"
                            position="right"
                            trigger="mouseenter click"
                            html={
                              <div style={{ textAlign: 'left' }}>
                                <div>
                                  The value of your machinery and any content in
                                  the building.
                                </div>
                              </div>
                            }
                          >
                            <img
                              style={{
                                maxHeight: '1.4rem',
                                marginLeft: '.5rem'
                              }}
                              src={info}
                              alt="info"
                            />
                          </Tooltip>
                        </label>
                        <input
                          type="number"
                          className="form__input mr-2rem"
                          value={house.machineryCost}
                          name="machineryCost"
                          placeholder="eg: 20000"
                          onChange={this.handleHouseFieldUpdate(houseId)}
                        />

                        {this.state.errorMessage.houses
                          ? this.state.errorMessage.houses.map(
                              err =>
                                err.houseId === houseId && (
                                  <p style={{ color: 'red', marginTop: '1em' }}>
                                    {err.machineryCost}
                                  </p>
                                )
                            )
                          : null}
                      </div>
                    )}

                    {this.state.showPersonalSumAssured && (
                      <div className="form__wrapper">
                        <label className="form__label mt-1rem mb-1rem">
                          Sum Assured for Personal Accident (GHS)
                          <Tooltip
                            size="big"
                            title="Welcome to React"
                            position="right"
                            trigger="mouseenter click"
                            html={
                              <div style={{ textAlign: 'left' }}>
                                <div style={{ paddingBottom: '.5rem' }}>
                                  Sum Assured (for Personal Accident) - How much
                                  do you want as cover for injuries and/or death
                                  to residents of the building?
                                </div>
                              </div>
                            }
                          >
                            <img
                              style={{
                                maxHeight: '1.4rem',
                                marginLeft: '.5rem'
                              }}
                              src={info}
                              alt="info"
                            />
                          </Tooltip>
                        </label>
                        <input
                          type="number"
                          className="form__input mr-2rem"
                          value={house.personalSumAssured}
                          name="personalSumAssured"
                          placeholder="eg: 20000"
                          onChange={this.handleHouseFieldUpdate(houseId)}
                        />

                        {this.state.errorMessage.houses
                          ? this.state.errorMessage.houses.map(
                              err =>
                                err.houseId === houseId && (
                                  <p style={{ color: 'red', marginTop: '1em' }}>
                                    {err.personalSumAssured}
                                  </p>
                                )
                            )
                          : null}
                      </div>
                    )}
                  </div>
                  {/* end machinery cost, personal sum assured */}

                  {/* third party sum assured */}
                  <div className="mt-2rem form__inputs">
                    {this.state.showThirdPartySumAssured && (
                      <div className="form__wrapper">
                        <label className="form__label mt-1rem mb-1rem">
                          Sum Assured for Third Party (GHS)
                          <Tooltip
                            size="big"
                            title="Welcome to React"
                            position="right"
                            trigger="mouseenter click"
                            html={
                              <div style={{ textAlign: 'left' }}>
                                <div style={{ paddingBottom: '.5rem' }}>
                                  Sum Assured (for Third Party) - How much do
                                  you want as cover for injuries and/or death to
                                  third parties?
                                </div>
                              </div>
                            }
                          >
                            <img
                              style={{
                                maxHeight: '1.4rem',
                                marginLeft: '.5rem'
                              }}
                              src={info}
                              alt="info"
                            />
                          </Tooltip>
                        </label>
                        <input
                          type="number"
                          className="form__input mr-2rem"
                          value={house.thirdPartySumAssured}
                          name="thirdPartySumAssured"
                          placeholder="eg: 20000"
                          onChange={this.handleHouseFieldUpdate(houseId)}
                        />

                        {this.state.errorMessage.houses
                          ? this.state.errorMessage.houses.map(
                              err =>
                                err.houseId === houseId && (
                                  <p style={{ color: 'red', marginTop: '1em' }}>
                                    {err.thirdPartySumAssured}
                                  </p>
                                )
                            )
                          : null}
                      </div>
                    )}
                  </div>
                  {/* third party sum assured */}

                  {/* fire extinguisher, et al */}
                  <div className="mt-2rem form__inputs">
                    <div className="form__wrapper">
                      <label className="mt-1rem">
                        <input
                          type="checkbox"
                          required
                          checked={house.hasFireExtinguisher}
                          name="hasFireExtinguisher"
                          onChange={this.handleHouseCheckbox(houseId)}
                        />{' '}
                        Has Fire Extinguisher?
                      </label>
                    </div>

                    <div className="form__wrapper">
                      <label className="mt-1rem">
                        <input
                          type="checkbox"
                          required
                          checked={house.hasDamageHistory}
                          name="hasDamageHistory"
                          onChange={this.handleHouseCheckbox(houseId)}
                        />{' '}
                        Has suffered loss due to Fire, Burglery?
                      </label>
                    </div>
                  </div>
                  {/* end fire extinguisher, et al */}

                  {/* business purpose, residential purpose */}
                  <div className="mt-2rem form__inputs">
                    {this.state.propertyType === 'H' && (
                      <div className="form__wrapper">
                        <label className="mt-1rem">
                          <input
                            type="checkbox"
                            required
                            checked={house.residentialPurpose}
                            name="residentialPurpose"
                            onChange={this.handleHouseCheckbox(houseId)}
                          />{' '}
                          Is the building solely occupied for Residential
                          purpose(s)?
                        </label>
                      </div>
                    )}

                    {this.state.propertyType === 'C' && (
                      <div className="form__wrapper">
                        <label className="mt-1rem">
                          <input
                            type="checkbox"
                            required
                            checked={house.businessPurpose}
                            name="businessPurpose"
                            onChange={this.handleHouseCheckbox(houseId)}
                          />{' '}
                          Is the building solely occupied for Business
                          purpose(s)?
                        </label>
                      </div>
                    )}
                  </div>
                  {/* end business purpose, business purpose */}

                  {/* resident section */}
                  {this.state.propertyType === 'H' && (
                    <div
                      className=""
                      style={{
                        backgroundColor: '#f2f2f2',
                        padding: '2rem',
                        marginTop: '1rem',
                        width: '80%'
                      }}
                    >
                      <h3 className="mt-5rem site-content__lead">
                        Residents Information
                      </h3>
                      <p className="text--muted">
                        Add information building residents
                      </p>

                      {this.state.residents.map((res, id) => (
                        <div key={id} className="property-residents-section">
                          {res.houseId === houseId ? (
                            <>
                              <div className="mt-3rem">
                                {this.getHouseResidents(houseId).length > 1 ? (
                                  <h4>Resident {id + 1}</h4>
                                ) : null}
                              </div>

                              <div className="form__inputs">
                                <div className="form__wrapper">
                                  <div>
                                    <label className="form__label mt-1rem mb-1rem">
                                      Name
                                    </label>
                                    <input
                                      className="form__input"
                                      placeholder="John Benjamin"
                                      value={res.name}
                                      onChange={this.handleResidentInputUpdate(
                                        'name',
                                        id
                                      )}
                                      required
                                    />

                                    {this.state.errorMessage.residents
                                      ? this.state.errorMessage.residents.map(
                                          err =>
                                            err.resId === id && (
                                              <p
                                                style={{
                                                  color: 'red',
                                                  marginTop: '1em'
                                                }}
                                              >
                                                {err.name}
                                              </p>
                                            )
                                        )
                                      : null}
                                  </div>
                                </div>

                                <div className="form__wrapper">
                                  <label className="form__label mt-1rem mb-1rem">
                                    Date of Birth
                                  </label>
                                  <DatePicker
                                    selected={res.dob ? moment(res.dob) : null}
                                    placeholderText="DD/MM/YYYY"
                                    dateFormat="DD/MM/YYYY"
                                    className="form__input"
                                    onChange={this.handleResidentBirthdateUpdate(
                                      'dob',
                                      id
                                    )}
                                    required
                                  />
                                  {this.state.errorMessage.residents
                                    ? this.state.errorMessage.residents.map(
                                        err =>
                                          err.resId === id && (
                                            <p
                                              style={{
                                                color: 'red',
                                                marginTop: '1em'
                                              }}
                                            >
                                              {err.dob}
                                            </p>
                                          )
                                      )
                                    : null}
                                </div>
                              </div>

                              {this.getHouseResidents(houseId).length > 1 ? (
                                <button
                                  className="btn btn--danger mt-2rem"
                                  onClick={this.handleRemoveResident(id)}
                                >
                                  Remove Resident {id + 1}
                                </button>
                              ) : null}
                            </>
                          ) : null}
                        </div>
                      ))}
                      <button
                        className="btn btn--proceed mt-5rem"
                        onClick={this.handleAddResident(houseId)}
                      >
                        Add Resident
                      </button>
                    </div>
                  )}
                  {/* end residents secttion */}

                  {this.state.houses.length > 1 ? (
                    <button
                      className="btn btn--danger mt-2rem"
                      onClick={this.handleRemoveHouse(houseId)}
                    >
                      Remove House {houseId + 1}
                    </button>
                  ) : null}

                  <hr
                    size="10"
                    width="60%"
                    style={{
                      marginTop: '2rem',
                      marginBottom: ''
                    }}
                    align="left"
                  />
                </div>
              ))}

              <button
                className="btn btn--proceed mt-4rem"
                onClick={this.handleAddHouse}
              >
                Add House
              </button>
              {/* end building information and residents */}

              {this.state.errorMessage.nonFieldErrors
                ? this.state.errorMessage.nonFieldErrors.map(err => (
                    <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                  ))
                : null}

              {/* {!this.state.affiliateReferrer ? (
                <>
                  <h3 className="mt-5rem site-content__lead">
                    Referral Code (Optional)
                  </h3>

                  <div className="form__inputs">
                    <div className="form__wrapper">
                      <div>
                        <label className="form__label mt-1rem mb-1rem">
                        </label>
                        <input
                          className="form__input"
                          placeholder="code here"
                          value={this.state.referrer}
                          onChange={this.handleFieldUpdate('referrer')}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : null} */}

              <div className="mt-5rem mb-2rem">
                <button
                  className="btn btn--gradient-primary btn--width-lng mr-2rem mb-2rem"
                  type="submit"
                  onClick={this.handleSubmit}
                >
                  {this.state.isSubmitting
                    ? 'Submitting...'
                    : 'Get me BestQuotes'}
                </button>
              </div>

              <small>
                By proceeding you agree to the{' '}
                <a href="/terms-of-service">terms and conditions</a> governing
                the purchase of this policy as they exist on the the date of
                purchase.
              </small>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
PropertyProductSearch.propTypes = {
  setPropertyProducts: PropTypes.func
}

export default connect(
  state => ({
    authUser: state.authUser,
    propertyProducts: state.propertyProducts
  }),
  dispatch => ({
    setPropertyProducts: products => dispatch(setPropertyProducts(products))
  })
)(WithRegion(PropertyProductSearch))
