import React, { Component } from 'react'
import { Table, Input, Button, Space } from 'antd'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

class FilterTable extends Component {
  state = {
    searchText: '',
    searchedColumn: ''
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false })
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex
              })
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100)
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  })

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    })
  }

  handleReset = clearFilters => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  render() {
    let filteredColumns = this.props.columns.map(i => {
      if (this.props.searchFields.includes(i.dataIndex)) {
        return {
          ...i,
          key: i.dataIndex,
          ...this.getColumnSearchProps(i.dataIndex)
        }
      }
      return {
        ...i,
        key: i.dataIndex
      }
    })

    let filteredData = this.props.dataSource.map(i => {
      return {
        ...i,
        key: i.id
      }
    })

    return (
      <Table
        columns={filteredColumns}
        dataSource={filteredData}
        pagination={true}
        boardered
        scroll={{ x: 300, y: 1500 }}
        onRow={this.props.onRow}
      />
    )
  }
}

FilterTable.propTypes = {
  dataSource: PropTypes.array,
  columns: PropTypes.array,
  searchFields: PropTypes.array,
  onRow: PropTypes.func
}

FilterTable.defaultProps = {
  searchFields: []
}

export default FilterTable
