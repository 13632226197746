import React, { Component } from 'react'
import PropTypes from 'prop-types'

import WithUser from 'hocs/WithUser'

import Modal from 'components/UI/Modal'

import UpdatePasswordForm from 'pages/dashboard/UpdatePasswordForm'
import UpdateAccountForm from 'pages/dashboard/UpdateAccountForm'

class UserAccount extends Component {
  state = {
    showModal: false,
    activeForm: null
  }

  handleCloseModal = () => {
    this.setState({ showModal: false })
  }

  handleEditAccount = () => {
    this.setState({
      activeForm: 'UpdateAccountForm',
      showModal: true
    })
  }

  handleEditPassword = () => {
    this.setState({
      activeForm: 'UpdatePasswordForm',
      showModal: true
    })
  }

  render() {
    let { user } = this.props.authUser

    let activeForm = {
      UpdatePasswordForm: <UpdatePasswordForm />,
      UpdateAccountForm: <UpdateAccountForm />
    }

    return (
      <div className="user-dash__main">
        <p className="site-content__lead">
          Account Profile - View Account Details
        </p>
        <p className="text--muted mb-2rem">
          To change your account details click on Update Profile
          <button
            className="btn btn--gradient-border ml-2rem"
            onClick={this.handleEditAccount}
          >
            Update Profile
          </button>
        </p>

        <hr />

        <div className="mt-5rem flex">
          <div className="form__inputs">
            <div className="form__wrapper mb-2rem">
              <label className="label mb-1rem">First name</label>
              <input
                className="form__input form__input--read-only text--upper"
                value={user.firstName}
                readOnly
              />
            </div>
          </div>
          <div className="form__inputs">
            <div className="form__wrapper mb-2rem">
              <label className="label mb-1rem">Last name</label>
              <input
                className="form__input form__input--read-only text--upper"
                value={user.lastName}
                readOnly
              />
            </div>
          </div>
          <div className="form__inputs">
            <div className="form__wrapper mb-2rem">
              <label className="label mb-1rem">Email</label>
              <input
                className="form__input form__input--read-only text--upper"
                value={user.email}
                readOnly
              />
            </div>
          </div>
        </div>

        {user.isAffiliate ? (
          <>
            <p className="site-content__lead mt-5rem">
              My Referral Code:{' '}
              <span className="text--muted">{user.referralCode}</span>
            </p>
            {/* <div className="mt-2rem flex">
              <div className="form__inputs">
                <div className="form__wrapper mb-2rem">
                  <label className="label mb-1rem">{user.referralCode}</label>
                  <input
                    className="form__input form__input--read-only text--lower"
                    value={''}
                    readOnly
                  />
                </div>
              </div>
            </div> */}

            <p className="site-content__lead mt-3rem">
              Links to Share or Embed on Websites
            </p>
            <p className="mb-1rem">
              Car Insurance:{' '}
              <span className="text--muted">{`https://mybestquote.com/motor?referrer=${user.referralCode}`}</span>
            </p>

            <p className="mb-1rem">
              Travel Insurance:{' '}
              <span className="text--muted">{`https://mybestquote.com/travel?referrer=${user.referralCode}`}</span>
            </p>

            <p className="mb-1rem">
              Home Insurance:{' '}
              <span className="text--muted">{`https://mybestquote.com/property?referrer=${user.referralCode}`}</span>
            </p>
          </>
        ) : null}

        <p className="site-content__lead mt-5rem">Account Password</p>
        <p className="text--muted mb-2rem">
          To update account password, click on Add New Password
          <button
            className="btn btn--gradient-border ml-2rem"
            onClick={this.handleEditPassword}
          >
            Add new Password
          </button>
        </p>

        <hr />

        <div className="mt-5rem flex">
          <div className="form__inputs">
            <div className="form__wrapper mb-2rem">
              <label className="label mb-1rem">Password</label>
              <input className="form__input form__input--read-only" />
            </div>
          </div>
        </div>

        <Modal
          show={this.state.showModal}
          handleCloseModal={this.handleCloseModal}
          modalStyle="modal modal--overflow"
          backdropStyle="backdrop"
        >
          {activeForm[this.state.activeForm]}
        </Modal>
      </div>
    )
  }
}
UserAccount.propTypes = {
  authUser: PropTypes.shape({
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string
    })
  })
}

export default WithUser(UserAccount)
