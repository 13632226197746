import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import axios from 'axios'
import { passwordResetConfirmUrl } from '../../config'
import PropTypes from 'prop-types'
import { Input, Form } from 'antd'
import BasicInput from 'components/Forms/BasicInput'
import WithUser from 'hocs/WithUser'

import bqLogo from 'img/bestquote-logo.png'

class ConfirmPasswordReset extends Component {
  state = {
    isSubmitting: false,
    isConfirmed: false,
    newPassword: '',
    confirmPassword: '',
    resetPin: '',
    errorMessage: ''
  }

  // componentDidMount(){
  //   this.setState({ isSubmitting: true }, () => {
  //     let params = this.props.match.params;
  //     let data = { token: , uid, new_password1, new_password2};

  //     axios.post(
  //       `${process.env.REACT_APP_API_BASE}/api/v1/users/${params.userId}/code-verification/`,
  //       {
  //         pin: params.verificationPin,
  //       }
  //     ).then(
  //       res => {
  //         this.props.setUser(res.data.results);
  //         this.setState({ isConfirmed: true, isSubmitting: false })
  //       }
  //     ).catch(
  //       err => {
  //         this.setState({ errorMessage: 'Unable to activate account' });
  //         this.setState({ isSubmitting: false });
  //       }
  //     )
  //   });

  // }

  handleInputUpdate = (stateName, event) => {
    this.setState({ [stateName]: event.target.value })
  }

  handleSubmit = () => {
    this.setState({ isSubmitting: true })
    let data = {
      resetPin: this.state.resetPin,
      password1: this.state.newPassword,
      password2: this.state.confirmPassword
    }

    axios
      .post(passwordResetConfirmUrl, data)
      .then(() => {
        this.setState({ isConfirmed: true })
      })
      .catch(err => {
        if (err.response && err.response.status < 500) {
          this.setState({ errorMessage: err.response.data.message })
        }
        this.setState({ isSubmitting: false })
      })
  }

  componentDidUpdate() {
    if (this.state.isConfirmed) {
      setTimeout(() => {
        this.props.history.push('/auth/login')
      }, 5000)
    }
  }

  render() {
    return (
      <div className="auth">
        <div className="auth__bounding-box">
          <div
            className="align--right mt-2rem"
            style={{ fontSize: '3rem', cursor: 'pointer' }}
          >
            <span onClick={this.props.history.goBack}>&times;</span>
          </div>
          <img
            src={bqLogo}
            alt="BestQuote Logo"
            className="bq-logo bq-logo--auth mb-2rem"
          />

          {!this.state.isConfirmed ? (
            <div>
              <p className="site-content__lead mb-3rem">
                Reset Your Account With New Password
              </p>

              <Form onFinish={this.handleSubmit} layout="vertical">
                <BasicInput
                  label="Verification Pin"
                  value={this.state.resetPin}
                  name={'resetPin'}
                  placeholder="enter verification pin"
                  handleChange={this.handleInputUpdate.bind(this, 'resetPin')}
                  toolTipLabel="Kindly check your email for the pin"
                  required={true}
                  className="mb-1rem"
                />
                <label for="password1" style={{ fontWeight: '700' }}>
                  New Password
                </label>
                {/* <input
                      className="form__input"
                      type="password"
                      onChange={this.handleInputUpdate.bind(
                        this,
                        'newPassword'
                      )}
                      autoFocus="autofocus"
                      required
                    /> */}
                <Input.Password
                  className="mb-2rem"
                  id="newPassword"
                  name="newPassword"
                  placeholder="New password"
                  type="password"
                  onChange={this.handleInputUpdate.bind(this, 'newPassword')}
                  required
                />

                <label for="password1" style={{ fontWeight: '700' }}>
                  Confirm Password
                </label>
                {/* <input
                      className="form__input"
                      type="password"
                      onChange={this.handleInputUpdate.bind(
                        this,
                        'newPassword'
                      )}
                      autoFocus="autofocus"
                      required
                    /> */}
                <Input.Password
                  className="mb-1rem"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm password"
                  type="password"
                  onChange={this.handleInputUpdate.bind(
                    this,
                    'confirmPassword'
                  )}
                  required
                />

                {this.state.errorMessage ? (
                  <p style={{ color: 'red', marginTop: '1em' }}>
                    {this.state.errorMessage}
                  </p>
                ) : null}

                <button
                  className="btn  btn--gradient-primary mt-2rem btn--width-lng"
                  type="submit"
                >
                  Reset password
                </button>
              </Form>
            </div>
          ) : (
            <div>
              <p className="site-content__lead">
                Your Password has been Successfully Reset!
                <Link to="/auth/login">
                  <span className="navbar__link--active">Login Now!</span>
                </Link>
              </p>
            </div>
          )}
        </div>
      </div>
    )
  }
}
ConfirmPasswordReset.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      uid: PropTypes.string,
      token: PropTypes.string
    })
  })
}

export default withRouter(WithUser(ConfirmPasswordReset))
