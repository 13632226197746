import kenyaImg from '../img/regions/ke.png'
import ghanaImg from '../img/regions/gh.png'
import nigeriaImg from '../img/regions/ng.png'
import cotedivoireImg from '../img/regions/ci.png'

let regions = [
  { value: 'gh', flag: ghanaImg, label: 'Ghana' },
  { value: 'ng', flag: nigeriaImg, label: 'Nigeria' },
  { value: 'ke', flag: kenyaImg, label: 'Kenya' },
  { value: 'ci', flag: cotedivoireImg, label: "Cote D'Ivoire" }
]

export function getRegion(region) {
  let reg = regions.find(obj => obj.value === region)
  return reg
}

export function getRegions() {
  return regions
}

export function filteredRegions(reg) {
  let regs = regions.filter(obj => obj.value !== reg)
  return regs
}
