import React from 'react'
import ReactDOM from 'react-dom'
// import registerServiceWorker, { unregister } from './registerServiceWorker';
import { Provider } from 'react-redux'
import store from './store'
import 'react-select/dist/react-select.css'
import 'react-accessible-accordion/dist/fancy-example.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'rc-dropdown/assets/index.css'
import 'flexboxgrid'
import 'antd/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.min.css'
// import 'img/fontawesome-free/css/all.min.css'
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import App from './App'

const rootElement = document.getElementById('root')

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement
)
