import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import Dropdown from 'rc-dropdown'
import Menu, { Item as MenuItem, Divider } from 'rc-menu'
import PropTypes from 'prop-types'

import { PROPERTY_PRODUCT_TYPE_CHOICES } from 'app-constants'

class PropertyUserPolicyCard extends Component {
  render() {
    let { policy } = this.props
    let { id } = policy
    let { premium } = policy
    let { company } = policy
    let { user } = policy
    let { status } = policy
    let { propertyPolicyInfo } = policy
    let { propertyType } = policy

    let nfExact = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })

    const menuActive = (
      <Menu>
        <MenuItem key="1" className="pointer">
          <span className="mr-1rem mb-1rem">
            <Link
              to={`/account/claims/property/new/${id}`}
              className="navbar__link"
            >
              Make Claim
            </Link>
          </span>
        </MenuItem>
        <Divider />
        <MenuItem key="3">
          <Link
            to={`/account/policies/property/${id}`}
            className="navbar__link"
          >
            View More
          </Link>
        </MenuItem>
      </Menu>
    )

    const menuUnpaid = (
      <Menu>
        <MenuItem key="1">
          <Link
            to={`/account/policies/property/${id}`}
            className="navbar__link"
          >
            View More
          </Link>
        </MenuItem>
      </Menu>
    )

    const menus = {
      U: menuUnpaid,
      A: menuActive,
      PR: menuActive,
      PA: menuActive,
      CA: menuActive,
      C: menuActive,
      F: menuActive,
      P: menuActive,
      D: menuActive
    }

    return (
      <div className="user-dash-card mr-2rem mb-2rem">
        {!isEmpty(policy) && (
          <React.Fragment>
            <div style={{ flex: '1' }}>
              <div className="space-between">
                {/* <span className="user-dash-card__category">{product.name}</span> */}
                <span className="user-dash-card__category">{`${PROPERTY_PRODUCT_TYPE_CHOICES[propertyType]} - ${propertyPolicyInfo.duration} months`}</span>
                <Dropdown
                  trigger={['click']}
                  overlay={menus[status]}
                  animation="slide-up"
                >
                  <span className="user-dash-card__options-toggle">...</span>
                </Dropdown>
              </div>

              <p className="text--muted user-dash-card__description">
                for {user.firstName} {user.lastName}
              </p>
            </div>

            <div className="space-between">
              <span className="user-dash-card__premium align-end">
                GHS {nfExact.format(premium.amount)}
              </span>
              <img
                className="user-dash-card__logo"
                src={company.logoUrl}
                alt="Insurer logo"
              />
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

PropertyUserPolicyCard.propTypes = {
  policy: PropTypes.shape({
    id: PropTypes.number,
    premium: PropTypes.number,
    status: PropTypes.string,
    propertyType: PropTypes.string,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string
    }),
    propertyPolicyInfo: PropTypes.shape({
      duration: PropTypes.number
    }),
    company: PropTypes.shape({
      logoUrl: PropTypes.string
    })
  })
}

export default withRouter(
  connect(state => ({
    authUser: state.authUser
  }))(PropertyUserPolicyCard)
)
