import React from 'react'
// import Page from "components/LayoutComponents/Page";
import Helmet from 'react-helmet'
import Policies from './Policies'
import * as c from 'app-constants/accounts'

class MotorPolices extends React.Component {
  static defaultProps = {
    pathName: 'Policies',
    roles: [c.MANAGEMENT, c.FINANCE, c.SALES]
  }

  render() {
    return (
      <div>
        <Helmet title="Motor Polices" />
        <Policies />
      </div>
    )
  }
}

export default MotorPolices
