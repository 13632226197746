import React, { Component } from 'react'
import axios from 'axios'

// import * as yc from 'app-constants/payments'
import * as pc from 'app-constants/products'

class Property extends Component {
  constructor(props) {
    super(props)

    this.state = {
      policy: this.props.policy,
      processed: this.props.policy.isProcessed,
      scheduleDocument: null,
      errors: {}
    }
  }

  handleInput = stateName => event => {
    this.setState({ [stateName]: event.target.value })
  }

  handleFiles = event => {
    this.setState({ [event.target.name]: event.target.files[0] })
  }

  handleSubmit = event => {
    event.preventDefault()
    let { scheduleDocument } = this.state

    const data = new FormData()
    data.append('scheduleDocument', scheduleDocument)

    const URL = `${process.env.REACT_APP_API_BASE}/api/v1/property-policies/${this.state.policy.policyId}/process/`

    const authUser = JSON.parse(localStorage.getItem('bestquoteUser'))
    const headers = {
      Authorization: `JWT ${authUser.token}`,
      'Content-Type': 'multipart/form-data'
    }

    axios
      .post(URL, data, { headers })
      .then(results => {
        this.props.handleCancel()
      })
      .catch(error => {
        if (error.response && error.status < 500) {
          this.setState({ errors: error.response.data })
        } else {
          this.setState({
            errors: {
              nonFieldErrors: ['server error, try again.']
            }
          })
        }
      })
  }

  // componentDidMount() {
  //   console.log(this.props.policy);
  // }

  render() {
    let { policy, processed } = this.state

    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div className="text-center mb-2">
            {processed ? (
              <div className="alert alert-dark">
                <span>STATUS: </span>{' '}
                <span className="badge badge-success">PROCESSED</span>
              </div>
            ) : (
              <div className="alert alert-dark">
                <span>STATUS: </span>{' '}
                <span className="badge badge-danger">NOT PROCESSED</span>
              </div>
            )}
          </div>
          <br />
          <div className="row">
            <div className="col-md-4 mb-4">
              <label className="mb-1">First Name</label>
              <input
                type="text"
                disabled
                value={policy.user.firstName}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-4">
              <label className="mb-1">Last Name</label>
              <input
                type="text"
                disabled
                value={policy.user.lastName}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-4">
              <label className="mb-1">Email Address</label>
              <input
                type="text"
                disabled
                value={policy.user.email}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-6">
              <label className="mb-1">Policy</label>
              <input
                type="text"
                disabled
                value={pc.PROPERTY_PRODUCT_TYPE_CHOICES[policy.propertyType]}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6">
              <label className="mb-1">Value</label>
              <input
                type="text"
                disabled
                value={policy.premiumAmount}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-12">
              {processed && (
                <label className="mb-1 mt-2">Property Schedule Document</label>
              )}
              <br />
              {processed && (
                <div>
                  <a
                    href={policy.scheduleDocument}
                    download={policy.scheduleDocument}
                    target="blank"
                  >
                    Schedule Document -- Download
                  </a>
                </div>
              )}
            </div>

            {!processed && (
              <div>
                <div className="col-md-12 mt-2 form-group">
                  <label className="mb-2" htmlFor="scheduleDocument">
                    Process Policy
                  </label>
                  <input
                    type="file"
                    onChange={this.handleFiles}
                    name="scheduleDocument"
                    accept="application/pdf"
                  />
                </div>
                <div className="col-md-8">
                  <div className="row mb-3">
                    <div className="col-md-8" />
                  </div>
                </div>
              </div>
            )}

            <div className="col-md-12 mt-4">
              <div className="row">
                <div className="col">
                  {/* {!processed && (
                    <button
                      className="btn btn-success btn-sm mr-3"
                      type="submit"
                      disabled={
                        this.state.scheduleDocument === null ? true : false
                      }
                    >
                      Send Document
                    </button>
                  )} */}
                  <button
                    className="btn btn-outline-secondary btn-sm"
                    onClick={this.props.handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default Property
