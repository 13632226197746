import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import SignUpForm from './SignUpForm'
import bqLogo from 'img/bestquote-logo.png'
// import signupBorder from 'img/signup-border.svg'

class SignUpPage extends Component {
  facebookLogin = () => {
    let url = 'https://www.facebook.com/v2.10/dialog/oauth?'
    url += `client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}&`
    url += `redirect_uri=${process.env.REACT_APP_APP_BASE}/auth/facebook-redirect&`
    url += 'response_type=code&scope=public_profile,email,user_birthday'

    window.location.href = url
  }

  render() {
    return (
      <div className="auth">
        <div className="auth__bounding-box">
          {/* <div
            className="auth__back"
            style={{ fontSize: '3rem', cursor: 'pointer' }}
          >
            <span onClick={this.props.history.goBack}>&times;</span>
          </div> */}
          <div className="auth__top">
            <img
              src={bqLogo}
              alt="BestQuote Logo"
              className="bq-logo bq-logo--auth mb-1rem"
              onClick={() => this.props.history.push('/')}
            />
          </div>
          <div>
            <hr />
          </div>

          <div className="auth__caption mb-2rem">
            <h2 className="mb-2rem">Agent Sign Up</h2>

            <p className="auth__caption__box">
              After signing up, kindly contact us at support@mybestquote.com or
              call us on +233 302 904 929 to verify your agent account.
            </p>
          </div>

          <SignUpForm />

          <div className="auth__footer mt-1rem">
            <div>
              Already have an account?{' '}
              <Link to="/auth/login">
                <span className="navbar__link--active">Log in</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SignUpPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func
  })
}

export default SignUpPage
