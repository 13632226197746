import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import LoginForm from './LoginForm'
import bqLogo from 'img/bestquote-logo.png'

class LoginPage extends Component {
  facebookLogin = () => {
    let url = 'https://www.facebook.com/v2.10/dialog/oauth?'
    url += `client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}&`
    url += `redirect_uri=${process.env.REACT_APP_APP_BASE}/auth/facebook-redirect&`
    url += 'response_type=code&scope=public_profile,email,user_birthday'

    window.location.href = url
  }

  handleHome = e => {
    e.preventDefault()
    this.props.history.push('/')
  }

  render() {
    return (
      <div className="auth">
        <div className="auth__bounding-box">
          <div className="auth__top">
            <img
              src={bqLogo}
              alt="BestQuote Logo"
              className="bq-logo bq-logo--auth mb-1rem"
              onClick={() => this.props.history.push('/')}
            />
          </div>
          <div>
            <hr />
          </div>
          <div className="auth__caption mb-5rem">
            <h2>Agent Dashboard</h2>
            <small>Login into your account to proceed</small>
          </div>

          <LoginForm />

          <div className="auth__footer mb-1rem">
            <div className="mr-2rem">
              Don't have an account?{' '}
              <Link to="/auth/signup">
                <span className="navbar__link--active">Register Now</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

LoginPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func
  })
}

export default withRouter(LoginPage)
