import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  setPayment,
  setPaymentRegion,
  setSubaccount,
  openPaymentModal
} from 'components/Payments/actions'

const WithPayments = WrappedComponent => {
  class With extends Component {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return connect(
    state => ({
      payment: state.payment,
      paymentRegion: state.payment.paymentRegion,
      openPaymentModal: state.payment.openDialog
    }),
    dispatch => ({
      setPayment: payment => dispatch(setPayment(payment)),
      setPaymentRegion: region => dispatch(setPaymentRegion(region)),
      setSubaccount: accountId => dispatch(setSubaccount(accountId)),
      openPaymentModal: open => dispatch(openPaymentModal(open))
    })
  )(With)
}

export default WithPayments
