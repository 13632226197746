import React from 'react'
import { Link } from 'react-router-dom'
import bqLogo from 'img/bestquote-logo.png'
import fb from 'img/logos_facebook.svg'
import insta from 'img/logos_instagram.svg'
import twitter from 'img/logos_twitter.svg'
import linkd from 'img/logos_linkedin.png'
import yt from 'img/logos_youtube.svg'
import PropTypes from 'prop-types'

const mainSiteUrl = process.env.REACT_APP_MAIN_SITE_URL

const Footer = ({ className }) => {
  return (
    <div className={`footer ${className}`}>
      <div className="footer__tier1">
        <div className="footer__tier1__detail">
          <div className="footer__logo">
            <Link to="/">
              <img src={bqLogo} alt="BestQuote Logo" className=" mb-1rem" />
            </Link>
          </div>

          <div className="social__items mt-1rem mb-1rem">
            <a href="https://web.facebook.com/bestquotegh/" target="blank">
              <img src={fb} alt="facebook" />
            </a>

            <a
              href="https://www.instagram.com/mybestquotegh/"
              target="blank"
              className="ml-2rem"
            >
              <img src={insta} alt="instagram" />
            </a>

            <a
              href="https://twitter.com/mybestquotegh"
              target="blank"
              className="ml-2rem"
            >
              <img src={twitter} alt="twitter" />
            </a>

            <a
              href="https://www.linkedin.com/company/bestquote-ghana-limited/"
              target="blank"
              className="ml-2rem"
            >
              <img src={linkd} alt="linkedin" />
            </a>

            <a
              href="https://www.youtube.com/channel/UCjJ6XYADfXRp85RI1U_IYLA"
              target="blank"
              className="ml-2rem"
            >
              <img src={yt} alt="youtube" />
            </a>
          </div>

          <div className="footer__tier1__detail__contact">
            <h4 className="footer__tier1__detail__contact--head">Contact Us</h4>
            <p className="contact--email mb-1rem">Support@MyBestQuote.com</p>
            <p className="contact--phone">+233 302 904 929</p>
          </div>
        </div>

        <div className="footer__tier1__link-group">
          <h4>Insurance Quotes</h4>
          <Link to={'/buy-motor-insurance'} className="footer__tier1__link">
            Buy Motor Insurance in Ghana
          </Link>
          <Link to={'/buy-travel-insurance'} className="footer__tier1__link">
            Compare Travel Insurance in Ghana
          </Link>
          <Link to={'/property'} className="footer__tier1__link">
            Compare Home Insurance in Ghana
          </Link>
          <Link to={'/#'} className="footer__tier1__link">
            Buy Health Insurance in Ghana
          </Link>
          <Link to={'/#'} className="footer__tier1__link">
            Buy Life Insurance in Ghana
          </Link>
        </div>

        <div className="footer__tier1__link-group">
          <h4>Resources</h4>
          <Link to={`${mainSiteUrl}/blog`} className="footer__tier1__link">
            Learn more about Insurance
          </Link>
          <Link to={'/buy-motor-insurance'} className="footer__tier1__link">
            Insurance status in Ghana
          </Link>
          <Link to={'/buy-motor-insurance'} className="footer__tier1__link">
            Insurance companies in Ghana
          </Link>
        </div>
      </div>

      <div className="footer__tier2">
        <div className="footer__tier2__links">
          <span className="footer__tier2__links__link">
            <Link to={'/#'}>ABOUT US</Link>
          </span>{' '}
          <span className="footer__tier2__links__separator">|</span>
          <span className="footer__tier2__links__link">
            <Link to={'/#'}>SITEMAP</Link>
          </span>{' '}
          <span className="footer__tier2__links__separator">|</span>
          <span className="footer__tier2__links__link">
            <Link to={`${mainSiteUrl}/terms-of-service`}>
              TERMS & CONDITIONS
            </Link>
          </span>{' '}
          <span className="footer__tier2__links__separator">|</span>
          <span className="footer__tier2__links__link">
            <Link to={`${mainSiteUrl}/privacy-policy`}>PRIVACY POLICY</Link>
          </span>{' '}
          <span className="footer__tier2__links__separator">|</span>
          <span className="footer__tier2__links__link">
            <Link to={`${mainSiteUrl}/faq`}>FAQs</Link>
          </span>
          <span className="footer__tier2__links__separator">|</span>
          <span className="footer__tier2__links__link">
            <Link to={`${mainSiteUrl}/contact`}>CONTACT US</Link>
          </span>
        </div>

        <hr />

        <div className="wrapper footer__legal v-spacer">
          <small>
            BestQuote is licensed by the National Insurance Commission as a
            corporate agent of Olea M&amp;G Insurance Brokers Limited
          </small>
        </div>

        {/* <hr /> */}

        <div className="wrapper footer__legal">
          <small className="text--blue" style={{ color: '#009b9c' }}>
            Copyright &copy; BestQuote (Ghana) LTD. All rights reserved
          </small>
        </div>
      </div>
    </div>
  )
}

Footer.propTypes = {
  className: PropTypes.string
}

export default Footer
