import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import TravelMainPage from './TravelMainPage'

import PageNotFound from 'pages/PageNotFound'
import TravelCompare from './TravelCompare'
import TravelBuy from './TravelBuy'
import PrivateRoute from 'hocs/PrivateRoute'
// import Footer from 'components/Footer'

const TravelRoutes = props => (
  <>
    <Switch>
      <Route exact path={props.match.path} component={TravelMainPage} />
      <Route
        exact
        path={`${props.match.path}/compare`}
        component={TravelCompare}
      />
      <PrivateRoute
        path={`${props.match.path}/:id/buy`}
        component={TravelBuy}
      />
      <Route component={PageNotFound} />
    </Switch>
    {/* <Footer /> */}
  </>
)
TravelRoutes.propTypes = {
  match: PropTypes.object
}

export default TravelRoutes
