import React, { Component } from 'react'
// import axios from 'axios'
import { Link } from 'react-router-dom'

import * as p from 'app-constants/policies'
// import * as pc from 'app-constants/products'

class Motor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      policy: this.props.policy,
      processed: this.props.policy.isProcessed,
      payment: p.POLICY_STATUS_CHOICES[this.props.policy.status],
      scheduleDocument: null
    }
  }

  render() {
    let { policy } = this.props

    return (
      <div>
        <form>
          <div className="text-center mb-2">
            <div className="alert alert-dark">
              <span>PAYMENT STATUS: </span>{' '}
              <span
                className={`badge badge-${
                  policy.paymentColor === 'green' ? 'success' : 'danger'
                }`}
              >
                {policy.payment}
              </span>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-6 mb-4">
              <label className="mb-1">First Name</label>
              <input
                type="text"
                disabled
                value={policy.user.firstName}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-1">Last Name</label>
              <input
                type="text"
                disabled
                value={policy.user.lastName}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-6 mb-4">
              <label className="mb-1">Email Address</label>
              <input
                type="text"
                disabled
                value={policy.user.email}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-1">Policy</label>
              <input
                type="text"
                disabled
                value={policy.product}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-6 mb-4">
              <label className="mb-1">Amount</label>
              <input
                type="text"
                disabled
                value={policy.premiumAmount}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-1">Duration</label>
              <input
                type="text"
                disabled
                value={policy.duration}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-6 mb-4">
              <label className="mb-1">Company</label>
              <input
                type="text"
                disabled
                value={policy.company}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-1">Vehicle Reg. Number</label>
              <input
                type="text"
                disabled
                value={policy.vehicleNumber}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-6 mb-4">
              <label className="mb-1">Vehicle Chassis Number</label>
              <input
                type="text"
                disabled
                value={policy.chassisNumber}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-1">Vehicle Model</label>
              <input
                type="text"
                disabled
                value={policy.brand}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-6 mb-4">
              <label className="mb-1">Number of Seats</label>
              <input
                type="text"
                disabled
                value={policy.seats}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-1">Year of Manufacture</label>
              <input
                type="text"
                disabled
                value={policy.yearOfManufacture}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-6 mb-4">
              <label className="mb-1">Vehicle Value</label>
              <input
                type="text"
                disabled
                value={policy.vehicleValue}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-1">Color of Vehicle</label>
              <input
                type="text"
                disabled
                value={policy.vehicleColor}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-6 mb-4">
              <label className="mb-1">Payment</label>
              <input
                type="text"
                disabled
                value={policy.payment}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-1">Policy Status</label>
              <input
                type="text"
                disabled
                value={policy.statusName}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-12 mt-4">
              <div className="row">
                <div
                  className="col"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {/* <button
                    className="btn btn-outline-secondary btn-sm"
                    onClick={this.props.handleCancel}
                  >
                    Close
                  </button> */}
                  {[p.PAID, p.ACTIVE].includes(policy.status) ? (
                    <Link
                      to={{
                        pathname: `/claims/motor/new/${policy.policyId}`
                      }}
                      className="navbar__link"
                    >
                      <button className="btn btn--width-lng btn--gradient-primary">
                        Make Claim
                      </button>
                    </Link>
                  ) : (
                    <Link
                      to={`/buy-motor-insurance/${policy.policyId}/buy`}
                      className="navbar__link"
                    >
                      <button className="btn btn--width-lng btn--gradient-primary">
                        Make Payment
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default Motor
