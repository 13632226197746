import React from 'react'
import { Drawer, Menu } from 'antd'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'

import {
  CarOutlined,
  ExportOutlined,
  HomeOutlined,
  UserOutlined,
  LogoutOutlined,
  LoginOutlined,
  UserAddOutlined,
  DashboardOutlined
} from '@ant-design/icons'
import PropTypes from 'prop-types'

const Sidenav = props => {
  return (
    <>
      <Drawer
        title="Menu"
        placement={'right'}
        onClose={props.handleClose}
        visible={props.showDrawer}
        className="navbar_drawer"
        // extra={
        //   <Space>
        //     <Button onClick={onClose}>Cancel</Button>
        //     <Button type="primary" onClick={onClose}>
        //       OK
        //     </Button>
        //   </Space>
        // }
      >
        <Menu
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          mode="inline"
          theme="light"
          inlineCollapsed={false}
          className=""
          style={{ width: '100%' }}
        >
          <Menu.Item
            key="1"
            icon={<CarOutlined />}
            onClick={() => props.history.push('/buy-motor-insurance')}
          >
            Car Insurance
          </Menu.Item>
          <Menu.Item
            key="2"
            icon={<ExportOutlined />}
            onClick={() => props.history.push('/buy-travel-insurance')}
          >
            Travel Insurance
          </Menu.Item>
          {/* <Menu.Item
            key="3"
            icon={<ExportOutlined />}
            onClick={() => props.history.push('/buy-life-insurance')}
          >
            Life Insurance
          </Menu.Item> */}
          <Menu.Item
            key="4"
            icon={<HomeOutlined />}
            onClick={() => props.history.push('/property')}
          >
            Home Insurance
          </Menu.Item>
          {props.authUser.token ? (
            <>
              <Menu.Item key="5" icon={<DashboardOutlined />}>
                <Link to={'/account'}>My Policies</Link>
              </Menu.Item>

              <Menu.Item key="6" icon={<UserOutlined />}>
                <Link to={'/account/settings'}>My Account</Link>
              </Menu.Item>
            </>
          ) : null}

          {props.authUser.token ? (
            <Menu.Item
              key="7"
              icon={<LogoutOutlined />}
              onClick={props.handleLogout}
            >
              Logout
            </Menu.Item>
          ) : (
            <>
              <Menu.Item key="8" icon={<LoginOutlined />}>
                <Link to={'/auth/login'}>Login</Link>
              </Menu.Item>

              <Menu.Item key="9" icon={<UserAddOutlined />}>
                <Link to={'/auth/signup'}>Sign Up Free</Link>
              </Menu.Item>
            </>
          )}
          {/* <SubMenu key="sub1" icon={<MailOutlined />} title="Navigation One">
            <Menu.Item key="5">Option 5</Menu.Item>
            <Menu.Item key="6">Option 6</Menu.Item>
            <Menu.Item key="7">Option 7</Menu.Item>
            <Menu.Item key="8">Option 8</Menu.Item>
          </SubMenu> */}
          {/* <SubMenu key="sub2" icon={<AppstoreOutlined />} title="Navigation Two">
            <Menu.Item key="9">Option 9</Menu.Item>
            <Menu.Item key="10">Option 10</Menu.Item>
            <SubMenu key="sub3" title="Submenu">
              <Menu.Item key="11">Option 11</Menu.Item>
              <Menu.Item key="12">Option 12</Menu.Item>
            </SubMenu>
          </SubMenu> */}
        </Menu>
      </Drawer>
    </>
  )
}

Sidenav.propTypes = {
  showDrawer: PropTypes.bool,
  handleClose: PropTypes.func,
  authUser: PropTypes.shape({
    token: PropTypes.string
  }),
  handleLogout: PropTypes.func
}

export default withRouter(Sidenav)
