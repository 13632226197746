import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { SafetyCertificateFilled } from '@ant-design/icons'
import { statsUrl, getHeaders } from '../../config'
import axios from 'axios'

const DashboardOverview = props => {
  const [stats, setStats] = useState([])

  useEffect(() => {
    axios.get(statsUrl, { headers: getHeaders() }).then(res => {
      setStats(res.data.data)
    })
  }, [])

  const handleClick = type => event => {
    if (type === 'T') {
      props.history.push('/travel')
    } else if (type === 'M') {
      props.history.push('/motor')
    } else if (type === 'P') {
      props.history.push('/property')
    } else if (type === 'H') {
      props.history.push('/health')
    } else if (type === 'L') {
      props.history.push('/life')
    }
  }

  const OverviewCard = props => (
    <div
      className="dashboard_home_overview_card"
      onClick={handleClick(props.stat.type)}
    >
      <div className="dashboard_home_overview_card_top">
        <div>
          <span>{props.stat.currency}</span>
        </div>

        <SafetyCertificateFilled style={{ color: '#009B9C' }} />
      </div>
      <h2>{props.stat.amount}</h2>
      <div className="dashboard_home_overview_card_bottom">
        <span>{props.stat.title}</span>
        <span>{props.stat.count}</span>
      </div>
    </div>
  )

  return (
    <div className="container">
      <div className="dashboard_home">
        <div className="dashboard_home_overview">
          {stats.map((s, i) => (
            <OverviewCard stat={s} key={i} />
          ))}
          <hr />
        </div>
      </div>
    </div>
  )
}
DashboardOverview.propTypes = {
  match: PropTypes.object
}

export default withRouter(DashboardOverview)
