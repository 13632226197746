import React, { Component } from 'react'
import { connect } from 'react-redux'

import { removeRegion, setRegion } from 'components/Region/action'
import { getRegions } from 'utils/regions'

const WithRegion = WrappedComponent => {
  class With extends Component {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return connect(
    state => ({
      region: state.region.region,
      regions: getRegions()
    }),
    dispatch => ({
      setRegion: region => dispatch(setRegion(region)),
      removeRegion: () => dispatch(removeRegion())
    })
  )(With)
}

export default WithRegion
