import React, { useState, useEffect } from 'react'
import {
  // PaystackButton,
  // usePaystackPayment,
  PaystackConsumer
} from 'react-paystack'
import axios from 'axios'
import { transactionsUrl, getHeaders, errorMessage } from '../../config'

import WithPayments from 'hocs/WithPayments'
import WithUser from 'hocs/WithUser'
import { withRouter } from 'react-router-dom'

const PaystackPayment = props => {
  const { id, amount, reference } = props.payment
  const { email, phoneNumber, firstName, lastName } = props.authUser.user
  const [paystackAmount, setPaystackAmount] = useState(0)
  const [metaData, setMetaData] = useState({})
  const [defaultEmail, setDefaultEmail] = useState('support@mybestquote.com')
  const [key, setKey] = useState(process.env.REACT_APP_PAYSTACK_PUBLIC_KEY) //PAYSTACK PUBLIC KEY
  const [currency, setCurrency] = useState('GHS')

  useEffect(() => {
    console.log(paystackAmount)
    setKey(process.env.REACT_APP_PAYSTACK_PUBLIC_KEY)
    setDefaultEmail('support@mybestquote.com')
    setPaystackAmount(Number(amount) * 100)
    setCurrency('GHS')
    setMetaData({
      name: `${firstName} ${lastName}`,
      phone: phoneNumber
    })
    // console.log('PAYSTACK AMOUNT', props.payment)
    // eslint-disable-next-line
  }, [])

  const callback = response => {
    const payload = {
      reference: response.reference
    }

    axios
      .post(`${transactionsUrl}${id}/confirm`, payload, {
        headers: getHeaders()
      })
      .then(res => {
        /* NOTE: clear reference from state. 
        and remove amount from state. 
        to prevent duplicate confirmation. 
        to prevent duplicated wallet values.
        */

        props.handleConfirmSuccess()
      })
      .catch(err => {
        if (err.response) {
          errorMessage('Error Processing Payment, Retry.')
        } else {
          errorMessage('Check your internet connection, retry')
        }
      })
  }

  const close = () => {
    console.log('Payment closed')
  }

  const componentProps = {
    email: email ? email : defaultEmail,
    amount: Math.floor(Number(amount) * 100),
    reference,
    currency,
    metadata: metaData,
    publicKey: key,
    onSuccess: callback,
    onClose: close
  }

  // const initializePayment = usePaystackPayment(componentProps);

  return (
    <div>
      {/* <button
        type="button"
        className="btn btn-success btn-block"
        onChange={() => initializePayment()}
      >
        Pay Now
      </button> */}

      {/* <PaystackButton
        className="btn btn-success btn-block"
        text="Pay Now"
        {...componentProps}
      /> */}

      <PaystackConsumer {...componentProps}>
        {({ initializePayment }) => (
          <button
            className="block btn btn--gradient-primary btn--width-lng"
            onClick={() => {
              initializePayment()
            }}
          >
            Make Payment
          </button>
        )}
      </PaystackConsumer>
    </div>
  )
}

export default withRouter(WithPayments(WithUser(PaystackPayment)))
