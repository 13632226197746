import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Dropdown from 'rc-dropdown'
import PropTypes from 'prop-types'

import Menu, { Item as MenuItem, Divider } from 'rc-menu'

class UserPolicyCard extends Component {
  render() {
    let product = this.props.policy.product
    let user = this.props.policy.user
    let logoUrl = product.company.logoUrl
    let status = this.props.policy.status

    let nfExact = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: product.currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })

    const menuActive = (
      <Menu>
        <MenuItem key="1" className="pointer">
          <span className="mr-1rem mb-1rem">
            <Link
              to={`/account/claims/travel/new/${this.props.policy.id}`}
              className="navbar__link"
            >
              Make Claim
            </Link>
          </span>
        </MenuItem>
        <Divider />
        <MenuItem key="2" className="pointer">
          <span className="mr-1rem mb-1rem">
            <Link
              to={`/account/cancellations/new/${this.props.policy.id}`}
              className="navbar__link"
            >
              Cancel Policy
            </Link>
          </span>
        </MenuItem>
        <Divider />
        <MenuItem key="3">
          <Link
            to={`/account/policies/travel/${this.props.policy.id}`}
            className="navbar__link"
          >
            View More
          </Link>
        </MenuItem>
      </Menu>
    )

    const menuUnpaid = (
      <Menu>
        <MenuItem key="1">
          <Link
            to={`/account/policies/travel/${this.props.policy.id}`}
            className="navbar__link"
          >
            View More
          </Link>
        </MenuItem>
      </Menu>
    )

    const menus = {
      U: menuUnpaid,
      A: menuActive,
      PR: menuActive,
      PA: menuActive,
      CA: menuActive,
      C: menuActive,
      F: menuActive,
      P: menuActive,
      D: menuActive
    }

    return (
      <div className="user-dash-card mr-2rem mb-2rem">
        <div style={{ flex: '1' }}>
          <div className="space-between">
            <span className="user-dash-card__category">{product.name}</span>
            <Dropdown
              trigger={['click']}
              overlay={menus[status]}
              animation="slide-up"
            >
              <span className="user-dash-card__options-toggle">...</span>
            </Dropdown>
          </div>

          <p className="text--muted user-dash-card__description">
            for {user.firstName} {user.lastName}
          </p>
        </div>

        <div className="space-between">
          <span className="user-dash-card__premium align-end">
            {nfExact.format(this.props.policy.premium.amount)}
          </span>
          <img
            className="user-dash-card__logo"
            src={logoUrl}
            alt="Insurer logo"
          />
        </div>
      </div>
    )
  }
}
UserPolicyCard.propTypes = {
  policy: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    premium: PropTypes.shape({
      amount: PropTypes.string
    }),
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string
    }),
    product: PropTypes.shape({
      name: PropTypes.string,

      currency: PropTypes.string,
      company: PropTypes.shape({
        logoUrl: PropTypes.string
      })
    })
  })
}

export default withRouter(
  connect(state => ({
    authUser: state.authUser
  }))(UserPolicyCard)
)
