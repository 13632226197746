import React, { useState } from 'react'
import axios from 'axios'
import { Button, Col, Space, Breadcrumb, Form } from 'antd'
import {
  getHeaders,
  errorToast,
  successToast,
  clientsUrl
} from '../../../config'
import { toast } from 'react-toastify'
import BasicInput from 'components/Forms/BasicInput'
import DatePickerInput from 'components/Forms/DatePickerInput'
import SelectInput from 'components/Forms/SelectInput'
import moment from 'moment'

const ClientForm = props => {
  const [department, setDepartment] = useState({
    email: '',
    firstName: '',
    lastName: '',
    otherNames: '',
    phoneNumber: '',
    address: '',
    dateOfBirth: '' || new Date(),
    occupation: '',
    passportNumber: '',
    driversLicenseId: '',
    confirmed: false
  })

  const [serverErrors, setServerErrors] = useState({})
  const [loading, setLoading] = useState(false)

  const handleInputChange = stateName => event => {
    setDepartment({ ...department, [stateName]: event.target.value })
  }

  const handleDateUpdate = stateName => date => {
    setDepartment({ ...department, [stateName]: date })
  }

  const handleSelect = stateName => value => {
    setDepartment({
      ...department,
      [stateName]: value
    })
  }

  const handleSubmit = event => {
    event.preventDefault()

    let data = {
      ...department,
      dateOfBirth: moment(department.dateOfBirth).format('YYYY-MM-DD')
    }

    setLoading(true)
    axios
      .post(clientsUrl, data, {
        headers: getHeaders()
      })
      .then(res => {
        setLoading(false)
        props.history.push('/clients')
        successToast(toast, 'New Client Added')
      })
      .catch(err => {
        setLoading(false)
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorToast(toast, 'Error adding client, retry.', err, props)
      })
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/clients">Clients</Breadcrumb.Item>
            <Breadcrumb.Item>New Client</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <div className="card mb-4rem">
            <div className="card-header">Add New Client</div>
            <div className="card-body">
              <Form className="p-2rem" layout="vertical">
                <div className="row">
                  <div className="col-md-5">
                    <BasicInput
                      label="First Name"
                      value={department.firstName}
                      name={'first name'}
                      placeholder="first name"
                      handleChange={handleInputChange('firstName')}
                      required={true}
                    />
                    {serverErrors.firstName &&
                      serverErrors.firstName.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5 offset-md-2 offset-sm-0 ">
                    <BasicInput
                      label="Last Name"
                      value={department.lastName}
                      name={'last name'}
                      placeholder="last name"
                      handleChange={handleInputChange('lastName')}
                      required={true}
                    />
                    {serverErrors.lastName &&
                      serverErrors.lastName.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5">
                    <BasicInput
                      label="Phone Number"
                      value={department.phoneNumber}
                      name={'phone number'}
                      placeholder="phone number"
                      handleChange={handleInputChange('phoneNumber')}
                      required={true}
                    />

                    {serverErrors.phoneNumber &&
                      serverErrors.phoneNumber.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5 offset-md-2 offset-sm-0 ">
                    <BasicInput
                      label="Email"
                      value={department.email}
                      name={'email'}
                      placeholder="email"
                      handleChange={handleInputChange('email')}
                      required={false}
                    />
                    {serverErrors.email &&
                      serverErrors.email.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5">
                    <BasicInput
                      label="Residential Address"
                      value={department.address}
                      name={'address'}
                      placeholder="address"
                      handleChange={handleInputChange('address')}
                      required={false}
                    />
                    {serverErrors.address &&
                      serverErrors.address.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>
                  <div className="col-md-5 offset-md-2 offset-sm-0 ">
                    <DatePickerInput
                      label="Date of Birth"
                      value={department.dateOfBirth}
                      name="date of birth"
                      handleChange={handleDateUpdate('dateOfBirth')}
                      required={false}
                    />
                    {serverErrors.dateOfBirth &&
                      serverErrors.dateOfBirth.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5">
                    <BasicInput
                      label="Occupation"
                      value={department.occupation}
                      name={'occupation'}
                      placeholder="occupation"
                      handleChange={handleInputChange('occupation')}
                      required={false}
                    />
                    {serverErrors.occupation &&
                      serverErrors.occupation.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>
                  <div className="col-md-5 offset-md-2 offset-sm-0">
                    <BasicInput
                      label="Passport Number"
                      value={department.passportNumber}
                      name={'passportNumber'}
                      placeholder="passportNumber"
                      handleChange={handleInputChange('passportNumber')}
                      required={false}
                    />
                    {serverErrors.passportNumber &&
                      serverErrors.passportNumber.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5">
                    <BasicInput
                      label="Driver License Number"
                      value={department.driversLicenseId}
                      name={'driver license number'}
                      placeholder="driver license number"
                      handleChange={handleInputChange('driversLicenseId')}
                      required={false}
                    />
                    {serverErrors.driversLicenseId &&
                      serverErrors.driversLicenseId.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>
                  <div className="col-md-5 offset-md-2 offset-sm-0 ">
                    <SelectInput
                      placeholder="select"
                      label="Is Client Confirmed?"
                      name="confirmation"
                      value={department.confirmed}
                      onChange={handleSelect('confirmed')}
                      optionList={[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false }
                      ]}
                    />
                    {serverErrors.confirmed &&
                      serverErrors.confirmed.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  {serverErrors.nonFieldErrors &&
                    serverErrors.nonFieldErrors.map((err, i) => (
                      <div className="text-danger" key={i}>
                        {err}
                      </div>
                    ))}

                  <Col md={24}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginBottom: '20px'
                      }}
                    >
                      <Space>
                        <Button onClick={() => props.history.push('/clients')}>
                          Go Back
                        </Button>
                        <Button
                          type="primary"
                          onClick={handleSubmit}
                          loading={loading}
                        >
                          Submit
                        </Button>
                      </Space>
                    </div>
                  </Col>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientForm
