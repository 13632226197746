import React, { useEffect, useState } from 'react'
import { NavLink, Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Menu, Dropdown } from 'antd'
import bqLogo from 'img/bestquote-logo.png'
import WithUser from 'hocs/WithUser'
import Sidenav from 'components/Sidenav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
// import { useSelector } from 'react-redux'
// import { setRegion } from './Region/action'
// import {
//   getRegion,
//   filteredRegions
// } from '../utils/regions'
import bqUserIcon from 'img/bq-user-icon.svg'

const Navbar = props => {
  const [showSidenav, setShowSidenav] = useState(false)

  // const { region } = useSelector(state => state.region)
  // const filteredRegionsList = filteredRegions(region)

  const handleToggleSidenave = () => {
    setShowSidenav(!showSidenav)
  }

  const handleSidenavLogout = () => {
    props.unsetUser()
    setShowSidenav(false)
  }

  // const handleChangeRegion = reg => {
  //   setRegion(reg)
  // }

  useEffect(() => {}, [])

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/account" className="">
          My Policies
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/account/settings" className="">
          My Account
        </Link>
      </Menu.Item>
      <Menu.Item onClick={props.unsetUser}>Logout</Menu.Item>
    </Menu>
  )

  return (
    <div className="wrapper">
      <Sidenav
        showDrawer={showSidenav}
        handleClose={handleToggleSidenave}
        authUser={props.authUser}
        handleLogout={handleSidenavLogout}
      />
      <nav className={'navbar'}>
        <div className="align-vertical">
          <a href={process.env.REACT_APP_HOMEPAGE_URL}>
            <img
              src={bqLogo}
              className="bq-logo bq-logo--navbar"
              alt="BestQuote Logo"
            />
          </a>
          {props.hidden404 ? null : (
            <span className="navbar__contact navbar--hidden">
              +233 302 904 929
            </span>
          )}
        </div>
        {props.hidden404 ? null : (
          <div className="navbar__links navbar--hidden">
            <NavLink
              to="/buy-motor-insurance"
              className={'navbar__link'}
              activeClassName="navbar__link--active"
            >
              Car
            </NavLink>
            <NavLink
              to="/buy-travel-insurance"
              className={'navbar__link'}
              activeClassName="navbar__link--active"
            >
              Travel
            </NavLink>
            {/* <NavLink
              to="/buy-life-insurance"
              className={'navbar__link'}
              activeClassName="navbar__link--active"
            >
              Life
            </NavLink> */}
            {/* <NavLink
              to="/buy-health-insurance"
              className={'navbar__link'}
              activeClassName="navbar__link--active"
            >
              Health
            </NavLink> */}
            <NavLink
              to="/property"
              className={'navbar__link'}
              activeClassName="navbar__link--active"
            >
              Home
            </NavLink>
          </div>
        )}

        <div className="navbar__auth navbar--hidden">
          {props.authUser.token ? (
            <div>
              <Dropdown overlay={menu} placement="bottomCenter">
                <div className="pointer">
                  <span>
                    <img src={bqUserIcon} alt="user-icon" />{' '}
                    {`${props.authUser.user.firstName &&
                      props.authUser.user.firstName.toUpperCase()} ${props
                      .authUser.user.lastName &&
                      props.authUser.user.lastName.toUpperCase()}`}
                  </span>
                  <span style={{ fontSize: '1.6rem' }} className="ml-1rem">
                    <FontAwesomeIcon icon={faAngleDown} />
                  </span>
                </div>
              </Dropdown>
            </div>
          ) : (
            <div>
              <Link to={'/auth/login'}>
                <button className="btn btn--width-sm ml-2rem btn--white">
                  Log in
                </button>
              </Link>
              <Link to={'/auth/signup'}>
                <button className="btn btn--width-sm ml-2rem btn--gradient-primary btn--width-auto">
                  Sign Up
                </button>
              </Link>
            </div>
          )}
        </div>
        <div className="navbar__menu-button">
          <span onClick={handleToggleSidenave} className="pointer">
            {showSidenav ? (
              <button
                className={'btn btn--width-sm ml-2rem btn--gradient-border'}
              >
                Close <FontAwesomeIcon icon={faTimes} />
              </button>
            ) : (
              <button
                className={'btn btn--width-sm ml-2rem btn--gradient-border'}
              >
                Menu <FontAwesomeIcon icon={faBars} />
              </button>
            )}
          </span>
        </div>
      </nav>
    </div>
  )
}

Navbar.propTypes = {
  match: PropTypes.object,
  unsetUser: PropTypes.func,
  history: PropTypes.object,
  authUser: PropTypes.shape({
    token: PropTypes.string,
    user: PropTypes.shape({
      firstName: PropTypes.string
    })
  }),
  hidden404: PropTypes.bool
}

export default withRouter(WithUser(Navbar))
