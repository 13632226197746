import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import PropTypes from 'prop-types'

import { passwordResetUrl } from '../../config'
import bqLogo from 'img/bestquote-logo.png'
import BasicInput from 'components/Forms/BasicInput'
import { LoadingOutlined } from '@ant-design/icons'
import { Form } from 'antd'

class PasswordResetRequest extends Component {
  state = {
    email: '',
    isSubmitting: false,
    errorMessage: '',
    isSuccessful: false
  }

  handleInputUpdate = (stateName, event) => {
    this.setState({ [stateName]: event.target.value })
  }

  handleSubmit = () => {
    this.setState({ isSubmitting: true })
    let data = { email: this.state.email }
    axios
      .post(passwordResetUrl, data)
      .then(() => {
        this.setState({ isSuccessful: true, isSubmitting: false })
        this.props.history.push('/auth/password-reset-confirm')
      })
      .catch(err => {
        if (err.response && err.response.status < 500) {
          this.setState({ errorMessage: err.response.data.message })
        } else {
          this.setState({ errorMessage: 'Something went wrong, retry' })
        }
        this.setState({ isSubmitting: false })
      })
  }

  componentDidUpdate() {
    // if (this.state.isSuccessful) {
    //   setTimeout(() => {
    //     this.props.history.push('/auth/password-reset-confirm')
    //   }, 3000)
    // }
  }

  render() {
    let { email } = this.state
    return (
      <div className="auth">
        <div className="auth__bounding-box">
          <div
            className="align--right mt-2rem"
            style={{ fontSize: '3rem', cursor: 'pointer' }}
          >
            <span onClick={this.props.history.goBack}>&times;</span>
          </div>
          <img
            src={bqLogo}
            alt="BestQuote Logo"
            className="bq-logo bq-logo--auth mb-2rem"
          />
          {this.state.isSuccessful ? (
            <div>
              <p className="site-content__lead">
                A link has been sent to <em>{this.state.email}</em>. Please
                click on the link in your email to change your password.
              </p>
            </div>
          ) : (
            <div>
              <p className="site-content__lead">Need to reset your password?</p>
              <p className="site-content__subtitle mb-3rem">
                Add account email and we'll send you a pin via email!
              </p>

              <Form onFinish={this.handleSubmit} layout="vertical">
                <BasicInput
                  label="Email Address"
                  value={email}
                  name={'email'}
                  placeholder="john@doe.com"
                  handleChange={this.handleInputUpdate.bind(this, 'email')}
                  required={true}
                />
                {this.state.errorMessage ? (
                  <p style={{ color: 'red', marginTop: '1em' }}>
                    {this.state.errorMessage}
                  </p>
                ) : null}

                <button
                  className="btn  btn--gradient-primary mt-2rem btn--width-lng"
                  type="submit"
                >
                  {this.state.isSubmitting ? (
                    <LoadingOutlined />
                  ) : (
                    'Get Verification Pin'
                  )}
                </button>
              </Form>
            </div>
          )}
        </div>
      </div>
    )
  }
}
PasswordResetRequest.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func
  })
}

export default withRouter(PasswordResetRequest)
