import React, { useEffect, useState } from 'react'
import { Space, Breadcrumb, Modal } from 'antd'
// import { toast } from 'react-toastify'

import { getHeaders, clientsUrl } from '../../../config'
import axios from 'axios'
import FilterTable from 'components/Forms/searchFilter'
import Detail from './Detail'

const Department = props => {
  const [departments, setDepartments] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [selected, setSelected] = useState({})
  // const [selectedRowData, setSelectedRowData] = useState([])

  const getDepartments = () => {
    axios
      .get(clientsUrl, { headers: getHeaders() })
      .then(res => {
        const filteredDepartments = res.data.results.map(dep => {
          return {
            ...dep,
            confirmedText: dep.confirmed ? 'Yes' : 'No',
            createdAt: new Date(dep.createdAt).toDateString()
          }
        })
        setDepartments(filteredDepartments)
      })
      .catch(err => {
        console.log('error')
      })
  }

  useEffect(() => {
    getDepartments()
  }, [])

  const handleDetail = record => {
    setShowModal(true)
    setSelected(record)
  }

  const columns = [
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 120
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      width: 120
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 120
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      width: 150
      // fixed: 'left'
    },

    {
      title: 'Confirmed',
      dataIndex: 'confirmedText',
      key: 'confirmedText',
      width: 90
    },

    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 80,
      render: (text, record) => (
        <Space size="middle">
          <span
            onClick={handleDetail.bind(this, record)}
            style={{ color: 'blue', cursor: 'pointer' }}
          >
            View
          </span>
          {/* <a onClick={handleDetail.bind(this, record)}>View</a> */}
        </Space>
      )
    }
  ]

  // const handleShowModal = () => {
  //   setShowModal(true)
  // }

  const handleOk = () => {
    setShowModal(false)
  }

  const handleCancel = () => {
    setShowModal(false)
    getDepartments()
  }

  let detailRow = (record, rowIndex) => ({
    onClick: event => {
      handleDetail(record)
    }
  })

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Clients</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <div className="card mb-4rem">
            <div className="card-header">
              <div className="utils__title">
                <span>My Clients</span>
              </div>
            </div>
            <div className="card-body">
              <div style={{ marginBottom: 16 }}>
                <button
                  className="btn_normal btn_gradient btn_md"
                  onClick={() => props.history.push('/clients/add-new')}
                >
                  Add New Client
                </button>
                {/*
                <span style={{ marginLeft: 8 }}>
                  {hasSelected
                    ? `Selected ${selectedRowKeys.length} items`
                    : ""}
                </span> */}
              </div>
              {/* <Table
                columns={columns}
                // rowSelection={rowSelection}
                dataSource={departments}
                pagination={true}
                boardered
              /> */}

              <FilterTable
                dataSource={departments}
                columns={columns}
                searchFields={['email', 'firstName', 'lastName']}
                onRow={detailRow}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Client Details"
        centered
        visible={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Detail handleCancel={handleCancel} policy={selected} />
      </Modal>
    </div>
  )
}

export default Department
