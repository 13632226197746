import React from 'react'
import { Space, Modal, Breadcrumb, Tag } from 'antd'
import axios from 'axios/index'
import * as pc from 'app-constants/products'
import * as oc from 'app-constants/policies'
import 'c3/c3.css'
import './style.scss'
import Motor from './Motor'
import { getHeaders, motorUrl } from '../../../../config'
import { withRouter } from 'react-router-dom'
import FilterTable from 'components/Forms/searchFilter'

class Policies extends React.Component {
  state = {
    motorTableData: [],
    showMotorModal: false,
    selectedMotor: null
  }

  // update tables
  updateTableResults() {
    // const { dispatch } = this.props

    axios
      .get(motorUrl, { headers: getHeaders() })
      .then(results => {
        // motor request
        let motor_policies = []
        results.data.results.forEach(policy => {
          policy.vehicles.forEach(vehicle => {
            motor_policies.push({
              ...policy,
              ...vehicle,
              customer: `${policy.user.firstName} ${policy.user.lastName}`,
              product: pc.PRODUCT_TYPE_CHOICES[policy.motor_type],
              company: policy.company.name,
              type: pc.PRODUCT_TYPE_CHOICES[policy.motor_type],
              typeName: pc.PRODUCT_TYPE_CHOICES['M'],
              premiumAmount: `${policy.premium.currency} ${policy.premium.amount}`,
              vehicleNumber: `${vehicle.registrationNumber}`,
              chassisNumber: `${vehicle.chassisNumber}`,
              vehicleType: pc.VEHICLE_TYPE_CHOICES[policy.motorType],
              seats: `${vehicle.seats}`,
              yearOfManufacture: `${vehicle.manufactureYear}`.toUpperCase(),
              vehicleColor: `${vehicle.color}`,
              vehicleValue: `${vehicle.value}`,
              brand: `${vehicle.make}`,
              model: `${vehicle.model}`,
              statusName: policy.validity,
              key: vehicle.id,
              countries: ['NA'],
              policyId: policy.id,
              timestamp: new Date(policy.updatedAt).toDateString(),
              duration: `${policy.duration} Months`,
              payment: oc.POLICY_STATUS_CHOICES[policy.status],
              paymentColor: [oc.PAID, oc.ACTIVE].includes(policy.status)
                ? 'green'
                : 'red',
              status: policy.status
            })
          })
        })
        // end motor request

        this.setState({
          motorTableData: motor_policies
        })
        console.log('DATA SET')
      })
      .catch(error => {
        console.log(error)
      })
      .then(() => {
        // dispatch(setLoading(false));
      })
  }
  // end update tables

  componentDidMount() {
    this.updateTableResults()
  }

  handleShowMotorModal = () => {
    console.log('Show modal')
    this.setState({
      showMotorModal: true
    })
  }

  handleMotorOk = e => {
    console.log(e)
    this.setState({
      showMotorModal: false
    })
  }

  handleMotorCancel = e => {
    console.log(e)
    this.setState(
      {
        showMotorModal: false
      },
      () => this.updateTableResults()
    )
  }

  handleInputChange = stateName => event => {
    this.setState({ [stateName]: event.target.value })
  }

  render() {
    if (this.props.isLoading) return null

    let motorDetailRow = (record, rowIndex) => ({
      onClick: event => {
        this.setState({ selectedMotor: record, showMotorModal: true })
      }
    })

    let { motorTableData } = this.state

    const Columns = [
      {
        title: 'Policy Number',
        dataIndex: 'number',
        key: 'number',
        width: 120
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        width: 120
      },
      {
        title: 'Customer',
        dataIndex: 'customer',
        key: 'customer',
        width: 120
      },
      {
        title: 'Vehicle No',
        dataIndex: 'vehicleNumber',
        key: 'vehicleNumber',
        width: 120
      },
      {
        title: 'Chassis No',
        dataIndex: 'chassisNumber',
        key: 'chassisNumber',
        width: 120
      },
      {
        title: 'Brand',
        dataIndex: 'brand',
        key: 'brand',
        width: 100
      },
      {
        title: 'Premium',
        dataIndex: 'premiumAmount',
        key: 'premium',
        width: 80
      },
      {
        title: 'Payment',
        dataIndex: 'payment',
        key: 'payment',
        width: 80,
        render: (text, record) => (
          <Space size="middle">
            <Tag color={record.paymentColor}>{text}</Tag>
          </Space>
        )
      },
      {
        title: 'Status',
        dataIndex: 'statusName',
        key: 'status',
        // fixed: 'right',
        width: 80
        // filters: [
        //   {
        //     text: 'Unpaid',
        //     value: oc.UNPAID
        //   },
        //   {
        //     text: 'Paid',
        //     value: oc.ACTIVE
        //   },
        //   {
        //     text: 'Inactive',
        //     value: oc.INACTIVE
        //   },
        //   {
        //     text: 'Cancelled',
        //     value: oc.CANCELLED
        //   }
        // ],
        // onFilter: (value, record) => record.status === value
      },
      {
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 100,
        render: (text, record) => (
          <Space size="middle">
            <span
              // onClick={handleDetail.bind(this, record)}
              style={{ color: 'blue', cursor: 'pointer' }}
            >
              View
            </span>
          </Space>
        )
      }
    ]

    return (
      <div className="row main-page">
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            {/* <HomeOutlined /> */}
            <span>Home</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Motor Policies</Breadcrumb.Item>
        </Breadcrumb>
        <br />
        <br />
        <Modal
          title="Motor Details"
          centered
          visible={this.state.showMotorModal}
          onOk={this.handleMotorOk}
          onCancel={this.handleMotorCancel}
          footer={null}
        >
          {this.state.showMotorModal ? (
            <Motor
              handleCancel={this.handleMotorCancel}
              policy={this.state.selectedMotor}
            />
          ) : null}
        </Modal>

        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <div className="utils__title">
                <span>Motor Policies</span>
              </div>
            </div>
            <div className="card-body">
              <div style={{ marginBottom: 16 }}>
                <button
                  className="btn_normal btn_gradient btn_md"
                  onClick={() =>
                    this.props.history.push('/buy-motor-insurance')
                  }
                >
                  Buy New Policy
                </button>
              </div>
              {/* <Table
                columns={Columns}
                dataSource={motorTableData}
                onRow={motorDetailRow}
              /> */}
              <FilterTable
                columns={Columns}
                dataSource={motorTableData}
                onRow={motorDetailRow}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Policies)
