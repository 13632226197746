import React, { Component } from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import axios from 'axios/index'
import PropTypes from 'prop-types'

import WithUser from 'hocs/WithUser'
import UserCancellationCard from './travel/UserCancellationCard'
import policyIcon from 'img/policy-icon.svg'
import { getHeaders } from '../../config'

class UserCancellations extends Component {
  state = {
    cancellations: [],
    width: 0,
    height: 0
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  componentDidMount() {
    let URL = `${process.env.REACT_APP_API_BASE}/api/v1/cancellations`
    axios
      .get(URL, {
        headers: getHeaders()
      })
      .then(res => {
        this.setState({
          cancellations: res.data.results
        })
      })

    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  render() {
    return (
      <div className="user-dash__main">
        <p className="site-content__lead">
          Cancellations Made on Purchased Policies
        </p>
        <p className="text--muted mb-2rem">
          Here’s an overview of policies you’ve cancelled
        </p>

        <hr />

        <p className="mt-2rem">Travel</p>

        {this.state.cancellations.length ? (
          <div className="mt-5rem flex-row flex-wrap">
            {this.state.cancellations.map(cancellation => (
              <UserCancellationCard
                key={cancellation.id}
                cancellation={cancellation}
              />
            ))}
          </div>
        ) : (
          <div className="mt-5rem flex-row flex-wrap">
            {this.state.width > 767 ? (
              <img src={policyIcon} alt="policy-icon" />
            ) : null}
            <p className={cx(this.state.width > 767 ? 'ml-2rem' : null)}>
              <span className="mr-2rem">You have no cancelled policies</span>
              <button
                className={cx(
                  'btn btn--gradient-border',
                  this.state.width > 767 ? null : 'mt-1rem'
                )}
              >
                <Link to="/travel">Discover Policies</Link>
              </button>
            </p>
          </div>
        )}
      </div>
    )
  }
}
UserCancellations.propTypes = {
  authUser: PropTypes.shape({
    token: PropTypes.string
  })
}

export default WithUser(UserCancellations)
