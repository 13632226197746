import React from 'react'
// import Page from "components/LayoutComponents/Page";
import Helmet from 'react-helmet'
import Policies from './Policies'
import * as c from 'app-constants/accounts'

class PoliciesPage extends React.Component {
  static defaultProps = {
    pathName: 'Policies',
    roles: [c.MANAGEMENT, c.FINANCE, c.SALES]
  }

  render() {
    return (
      <div className="mb-5rem">
        <Helmet title="Property Policies" />
        <Policies />
      </div>
    )
  }
}

export default PoliciesPage
