import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Col, Space, Breadcrumb, Form } from 'antd'
import {
  getHeaders,
  clientsUrl,
  successNotification,
  errorNotification
} from '../../../config'
import BasicInput from 'components/Forms/BasicInput'
import DatePickerInput from 'components/Forms/DatePickerInput'
import SelectInput from 'components/Forms/SelectInput'
import moment from 'moment'
import PropTypes from 'prop-types'

const Detail = props => {
  const [client, setClient] = useState({
    email: props.policy.email,
    firstName: props.policy.firstName,
    lastName: props.policy.lastName,
    otherNames: props.policy.otherNames,
    phoneNumber: props.policy.phoneNumber,
    address: props.policy.address,
    dateOfBirth: props.policy.dateOfBirth || new Date(),
    occupation: props.policy.occupation,
    passportNumber: props.policy.passportNumber,
    driversLicenseId: props.policy.driversLicenseId,
    confirmed: props.policy.confirmed
  })

  // const [disableForm, setDisableForm] = useState(false)
  const [serverErrors, setServerErrors] = useState({})
  const [loading, setLoading] = useState(false)
  // const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line
  }, [])

  const handleInputChange = stateName => event => {
    setClient({ ...client, [stateName]: event.target.value })
  }

  const handleDateUpdate = stateName => date => {
    setClient({ ...client, [stateName]: date })
  }

  const handleSelect = stateName => value => {
    setClient({
      ...client,
      [stateName]: value
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    let data = {
      ...client,
      dateOfBirth: moment(client.dateOfBirth).format('YYYY-MM-DD')
    }

    setLoading(true)
    axios
      .put(clientsUrl + '/' + props.policy.id, data, {
        headers: getHeaders()
      })
      .then(res => {
        setLoading(false)

        successNotification('Client Updated')
        props.handleCancel()
      })
      .catch(err => {
        setLoading(false)
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error updating client, retry.')
      })
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/clients">Clients</Breadcrumb.Item>
            <Breadcrumb.Item>Update Client</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <div className="card mb-4rem">
            <div className="card-header">Update Client</div>
            <div className="card-body">
              <Form layout="vertical" className="p-2rem">
                <div className="row">
                  <div className="col-md-5">
                    <BasicInput
                      label="First Name"
                      value={client.firstName}
                      name={'first name'}
                      placeholder="first name"
                      handleChange={handleInputChange('firstName')}
                      required={true}
                    />
                    {serverErrors.firstName &&
                      serverErrors.firstName.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5 offset-md-2 offset-sm-0 ">
                    <BasicInput
                      label="Last Name"
                      value={client.lastName}
                      name={'last name'}
                      placeholder="last name"
                      handleChange={handleInputChange('lastName')}
                      required={true}
                    />
                    {serverErrors.lastName &&
                      serverErrors.lastName.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5">
                    <BasicInput
                      label="Phone Number"
                      value={client.phoneNumber}
                      name={'phone number'}
                      placeholder="phone number"
                      handleChange={handleInputChange('phoneNumber')}
                      required={true}
                    />

                    {serverErrors.phoneNumber &&
                      serverErrors.phoneNumber.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5 offset-md-2 offset-sm-0 ">
                    <BasicInput
                      label="Email"
                      value={client.email}
                      name={'email'}
                      placeholder="email"
                      handleChange={handleInputChange('email')}
                      required={false}
                    />
                    {serverErrors.email &&
                      serverErrors.email.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5">
                    <BasicInput
                      label="Residential Address"
                      value={client.address}
                      name={'address'}
                      placeholder="address"
                      handleChange={handleInputChange('address')}
                      required={false}
                    />
                    {serverErrors.address &&
                      serverErrors.address.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>
                  <div className="col-md-5 offset-md-2 offset-sm-0 ">
                    <DatePickerInput
                      label="Date of Birth"
                      value={client.dateOfBirth}
                      name="date of birth"
                      handleChange={handleDateUpdate('dateOfBirth')}
                      required={false}
                    />
                    {serverErrors.dateOfBirth &&
                      serverErrors.dateOfBirth.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5">
                    <BasicInput
                      label="Occupation"
                      value={client.occupation}
                      name={'occupation'}
                      placeholder="occupation"
                      handleChange={handleInputChange('occupation')}
                      required={false}
                    />
                    {serverErrors.occupation &&
                      serverErrors.occupation.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>
                  <div className="col-md-5 offset-md-2 offset-sm-0">
                    <BasicInput
                      label="Password Number"
                      value={client.passportNumber}
                      name={'passportNumber'}
                      placeholder="passportNumber"
                      handleChange={handleInputChange('passportNumber')}
                      required={false}
                    />
                    {serverErrors.passportNumber &&
                      serverErrors.passportNumber.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5">
                    <BasicInput
                      label="Driver License Number"
                      value={client.driversLicenseId}
                      name={'driver license number'}
                      placeholder="driver license number"
                      handleChange={handleInputChange('driversLicenseId')}
                      required={false}
                    />
                    {serverErrors.driversLicenseId &&
                      serverErrors.driversLicenseId.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>
                  <div className="col-md-5 offset-md-2 offset-sm-0 ">
                    <SelectInput
                      placeholder="select"
                      label="Is Client Confirmed?"
                      name="confirmation"
                      value={client.confirmed}
                      onChange={handleSelect('confirmed')}
                      optionList={[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false }
                      ]}
                    />
                    {serverErrors.confirmed &&
                      serverErrors.confirmed.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  {serverErrors.nonFieldErrors &&
                    serverErrors.nonFieldErrors.map((err, i) => (
                      <div className="text-danger" key={i}>
                        {err}
                      </div>
                    ))}

                  <Col md={24}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginBottom: '20px'
                      }}
                    >
                      <Space>
                        <Button onClick={() => props.history.push('/clients')}>
                          Go Back
                        </Button>
                        <Button
                          type="primary"
                          onClick={handleSubmit}
                          loading={loading}
                        >
                          Submit
                        </Button>
                      </Space>
                    </div>
                  </Col>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Detail.propTypes = {
  handleCancel: PropTypes.string,
  policy: PropTypes.object
}

export default Detail
