import React from 'react'
import PropTypes from 'prop-types'
// import TravelBannerPage from './TravelBanner'
import TravelSearchForm from 'components/Forms/TravelSearchForm'
// import TravelPartners from './TravelPartners'
// import bannerImage from 'img/travel/travel-banner.svg'
import { Breadcrumb } from 'antd'
// import Navbar from 'components/Navbar'

const TravelMainPage = props => {
  // const [showModal, setShowModal] = useState(false)

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 mb-5rem">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Buy Travel Insurance</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <TravelSearchForm />
        </div>
      </div>
    </div>
  )
}

TravelMainPage.propTypes = {
  match: PropTypes.object
}

export default TravelMainPage
