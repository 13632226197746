export function getGhsValue(premium, currency, exchangeRate) {
  const premiumFloat = parseFloat(premium)
  const ghsToUsd = parseFloat(exchangeRate.ghsToUsd)
  const ghsToEur = parseFloat(exchangeRate.ghsToEur)
  const value =
    currency === 'USD'
      ? premiumFloat * ghsToUsd
      : currency === 'EUR'
      ? premiumFloat * ghsToEur
      : premiumFloat
  return Math.round(value * 100) / 100
}

export const getApproximateValue = (currency, amount) => {
  let approx = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  return approx.format(amount)
}

export const getExactValue = (currency, amount) => {
  let exact = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  return exact.format(amount)
}

export const getDecimalValue = amount => {
  let decimalFormat = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  return decimalFormat.format(amount)
}
