import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import axios from 'axios'
import PropTypes from 'prop-types'

import ScrollToTop from './hocs/ScrollToTop'
import PrivateRoute from './hocs/PrivateRoute'

import DashboardRoutes from 'pages/dashboard/DashboardRoutes'
import AuthRoutes from 'pages/user-auth'
import PageNotFound from 'pages/PageNotFound'
import { getHeaders } from './config'
import { setCookie } from 'utils/urls'
import Meta from 'components/Meta'

import ReactGA from 'react-ga'
// import FullStory from 'react-fullstory'
import Messenger from 'react-messenger-customer-chat'
import 'styles/styles.css'
import WithProducts from 'hocs/WithProducts'

// const fullstory_org = '13FWHV'

const App = props => {
  let { showMessenger } = props

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE}/api/v1/exchange-rates/latest/`, {
        headers: getHeaders()
      })
      .then(res => {
        props.setExchangeRate(res.data.results)
      })

    // initialize react google analytics
    ReactGA.initialize('UA-175185911-1')
    ReactGA.pageview(window.location.pathname + window.location.search)

    // get url params
    const params = new URLSearchParams(window.location.search)
    // set reference code of an affiliate user.
    if (params.has('referrer')) {
      let expiryDate = new Date()
      expiryDate.setDate(expiryDate.getDate() + 7)
      setCookie('referrer', params.get('referrer'), expiryDate)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Meta>
      <Router>
        <ScrollToTop>
          <div className="app">
            {/* <FullStory org={fullstory_org} /> */}
            <Messenger
              pageId={'485942981883225'}
              htmlRef={window.location.pathname}
              shouldShowDialog={showMessenger}
            />
            <Switch>
              <Route path="/auth" component={AuthRoutes} />
              <PrivateRoute path="/" component={DashboardRoutes} />
              <Route component={PageNotFound} />
            </Switch>
          </div>
        </ScrollToTop>
      </Router>
    </Meta>
  )
}

App.propTypes = {
  setExchangeRate: PropTypes.func
}

export default WithProducts(App)
