import React, { useState } from 'react'
import moment from 'moment'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { getHeaders, lifeUrl } from '../../config'
// import { getCookie } from 'utils/urls'
import { LoadingOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import DatePickerInput from './DatePickerInput'
import BasicInput from './BasicInput'
import PropTypes from 'prop-types'

const LifeBuyForm = props => {
  const [policy, setPolicy] = useState({
    id: props.policy.id,
    firstName: '',
    lastName: '',
    otherNames: '',
    address: '',
    dateOfBirth: new Date(),
    occupation: '',
    email: '',
    phoneNumber: '',
    startDate: new Date()
  })

  const [formSection, setFormSection] = useState(1)
  // const [referrer, setReferrer] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState({})
  // const [affiliateReferrer, setAffiliateReferrer] = useState(false)

  const removeErrorMessage = stateName => {
    // remove form field errors
    if (errorMessage[stateName]) {
      setErrorMessage({
        ...errorMessage,
        [stateName]: null,
        nonFieldErrors: null
      })
    } else if (errorMessage.vehicles) {
      setErrorMessage({
        ...errorMessage,
        vehicles: errorMessage.vehicles.map(v => {
          return { ...v, [stateName]: null }
        }),
        nonFieldErrors: null
      })
    }
  }

  const handleFieldUpdate = stateName => event => {
    setPolicy({ ...policy, [stateName]: event.target.value })

    // remove form field errors
    removeErrorMessage(stateName)
  }

  const handleDateUpdate = stateName => date => {
    setPolicy({ ...policy, [stateName]: date })

    // remove form field errors
    removeErrorMessage(stateName)
  }

  const handleSubmit = async () => {
    let data = {
      ...policy,
      startDate: policy.startDate
        ? moment(policy.startDate).format('YYYY-MM-DD')
        : '',
      dateOfBirth: policy.dateOfBirth
        ? moment(policy.dateOfBirth).format('YYYY-MM-DD')
        : ''
    }

    setIsSubmitting(true)

    axios
      .put(`${lifeUrl}/${policy.id}`, data, {
        headers: getHeaders()
      })
      .then(res => {
        props.history.push({
          pathname: `/payments/new/${res.data.data.id}`,
          state: { id: res.data.data.id, type: 'life' }
        })
        setIsSubmitting(false)
      })
      .catch(err => {
        setIsSubmitting(false)
        if (err.response) {
          err.response.status === 400
            ? setErrorMessage({
                ...err.response.data,
                nonFieldErrors: ["Kindly make sure you've completed the form"]
              })
            : setErrorMessage({
                ...errorMessage,
                nonFieldErrors: ['Oops, server Error! Retry']
              })
        } else {
          setErrorMessage({
            ...errorMessage,
            nonFieldErrors: ['Error Connecting to Server, Retry.']
          })
        }
      })
  }

  const handleNextSection = () => {
    setFormSection(formSection + 1)
  }

  return (
    <Form
      onFinish={formSection === 2 ? handleSubmit : handleNextSection}
      layout="vertical"
      className="motor_form"
    >
      {formSection === 1 ? (
        <div className="row">
          <h3 className="mb-2rem">
            Finish up by giving more information about yourself
          </h3>
          <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly enter your first name"
              value={policy.firstName}
              name={'first name'}
              placeholder="enter first name"
              handleChange={handleFieldUpdate('firstName')}
              required={true}
            />

            {errorMessage.firstName
              ? errorMessage.firstName.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
                ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly enter your last name"
              value={policy.lastName}
              name={'last name'}
              placeholder="enter last name"
              handleChange={handleFieldUpdate('lastName')}
              required={true}
            />

            {errorMessage.lastName
              ? errorMessage.lastName.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
                ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly enter your other names"
              value={policy.otherNames}
              name={'other name'}
              placeholder="enter other name"
              handleChange={handleFieldUpdate('otherNames')}
              required={false}
            />

            {errorMessage.otherNames
              ? errorMessage.otherNames.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
                ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <DatePickerInput
              label="Kindly indicate when you were born"
              value={policy.dateOfBirth}
              handleChange={handleDateUpdate('dateOfBirth')}
              name="date of birth"
              required={true}
            />{' '}
            {errorMessage.dateOfBirth
              ? errorMessage.dateOfBirth.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
                ))
              : null}
          </div>
        </div>
      ) : null}

      {formSection === 2 ? (
        <div className="row">
          <h3 className="mb-2rem">Give us more details about you</h3>
          <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly provide your occupation or line of work"
              value={policy.occupation}
              name={'occupation'}
              placeholder="enter occupation"
              handleChange={handleFieldUpdate('occupation')}
              required={true}
            />

            {errorMessage.occupation
              ? errorMessage.occupation.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
                ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly provide your address"
              value={policy.address}
              name={'address'}
              placeholder="enter address"
              handleChange={handleFieldUpdate('address')}
              required={true}
            />

            {errorMessage.address
              ? errorMessage.address.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
                ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly provide your phone number"
              value={policy.phoneNumber}
              name={'phone number'}
              placeholder="enter phone number"
              handleChange={handleFieldUpdate('phoneNumber')}
              required={true}
            />

            {errorMessage.phoneNumber
              ? errorMessage.phoneNumber.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
                ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly provide your email address"
              inputType="email"
              value={policy.email}
              name={'email address'}
              placeholder="enter email address"
              handleChange={handleFieldUpdate('email')}
              required={true}
            />

            {errorMessage.email
              ? errorMessage.email.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
                ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <DatePickerInput
              label="Kindly chose when you want this policy to start"
              value={policy.startDate}
              handleChange={handleDateUpdate('startDate')}
              name="starting date"
              required={true}
            />{' '}
            {errorMessage.startDate
              ? errorMessage.startDate.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
                ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            {errorMessage.nonFieldErrors
              ? errorMessage.nonFieldErrors.map((err, i) => (
                  <div style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </div>
                ))
              : null}
          </div>
        </div>
      ) : null}

      <div className="motor_form_buttons">
        <button
          className="btn_normal btn_dark btn_md"
          type="button"
          onClick={() =>
            setFormSection(formSection > 1 ? formSection - 1 : formSection)
          }
          style={formSection !== 1 ? {} : { visibility: 'hidden' }}
        >
          Back
        </button>

        <button className="btn_normal btn_gradient btn_md" type="submit">
          {isSubmitting ? (
            <LoadingOutlined />
          ) : formSection === 2 ? (
            'Submit'
          ) : (
            'Continue'
          )}
        </button>
      </div>
    </Form>
  )
}

LifeBuyForm.propTypes = {
  policy: PropTypes.object
}

export default withRouter(LifeBuyForm)
