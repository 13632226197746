// Reducers
export const constants = {
  ADD_DUMMY: 'ADD_DUMMY',
  LOGIN_URL: '/auth/login',

  // AUTH_USER: "AUTH_USER",
  // UNAUTH_USER: "UNAUTH_USER",
  FETCHING_USER: 'FETCHING_USER',
  FETCHING_USER_FAILURE: 'FETCHING_USER_FAILURE',
  FETCHING_USER_SUCCESS: 'FETCHING_USER_SUCCESS',

  MAKE_CLAIM: 'MAKE_CLAIM',
  MAKE_CANCELLATION: 'MAKE_CANCELLATION',
  EDIT_PASSWORD: 'EDIT_PASSWORD',
  EDIT_ACCOUNT: 'EDIT_ACCOUNT',

  SET_PRODUCTS: 'SET_PRODUCTS',
  SET_MOTOR_PRODUCTS: 'SET_MOTOR_PRODUCTS',
  SET_HEALTH_PRODUCTS: 'SET_HEALTH_PRODUCTS',
  SET_LIFE_PRODUCTS: 'SET_LIFE_PRODUCTS',
  SET_PROPERTY_PRODUCTS: 'SET_PROPERTY_PRODUCTS',
  SET_COMPARE_PRODUCTS: 'SET_COMPARE_PRODUCTS',
  CLEAR_COMPARE_PRODUCTS: 'CLEAR_COMPARE_PRODUCTS',

  SET_PAYLOAD: 'SET_PAYLOAD',
  SET_MOTOR_PAYLOAD: 'SET_MOTOR_PAYLOAD',
  SET_PROPERTY_PAYLOAD: 'SET_PROPERTY_PAYLOAD',
  SET_LIFE_PAYLOAD: 'SET_LIFE_PAYLOAD',
  SET_HEALTH_PAYLOAD: 'SET_HEALTH_PAYLOAD',
  CLEAR_PAYLOAD: 'CLEAR_PAYLOAD',

  TOGGLE_PRODUCT: 'TOGGLE_PRODUCT',
  CLEAR_PRODUCT_TOGGLES: 'CLEAR_PRODUCT_TOGGLES',
  SET_SEARCH_RESULTS: 'SET_SEARCH_RESULTS',
  SET_SELECTED_PRODUCT: 'SET_SELECTED_PRODUCT',
  SET_SEARCH_ITEM: 'SET_SEARCH_ITEM',
  CLEAR_SEARCH_ITEM: 'CLEAR_SEARCH_ITEM',

  TOGGLE_MESSENGER: 'TOGGLE_MESSENGER',

  SET_EXCHANGE_RATE: 'SET_EXCHANGE_RATE',
  SET_CURRENT_TIME: 'SET_CURRENT_TIME',
  SET_PAYMENT: 'SET_PAYMENT',
  REMOVE_PAYMENT: 'REMOVE_PAYMENT',
  SET_PAYMENT_REGION: 'SET_PAYMENT_REGION',
  REMOVE_PAYMENT_REGION: 'REMOVE_PAYMENT_REGION',
  OPEN_PAYMENT_DIALOG: 'OPEN_PAYMENT_DIALOG',

  SET_USER: 'SET_USER',
  UNSET_USER: 'UNSET_USER',
  // AUTH_START: "AUTH_START",

  SET_REGION: 'SET_REGION',
  REMOVE_REGION: 'REMOVE_REGION',
  UPDATE_REGION: 'UPDATE_REGION',
  SET_SUBACCOUNT: 'SET_SUBACCOUNT',
  REMOVE_SUBACCOUNT: 'REMOVE_SUBACCOUNT',

  FILTER_POLICIES: 'FILTER_POLICIES',
  FETCHING_POLICIES: 'FETCHING_POLICIES',
  FETCHING_POLICIES_ERROR: 'FETCHING_POLICIES_ERROR',
  FETCHING_POLICIES_SUCCESS: 'FETCHING_POLICIES_SUCCESS',
  REMOVE_FETCHING: 'REMOVE_FETCHING',
  TOGGLE_POLICY: 'TOGGLE_POLICY',
  SELECT_POLICY: 'SELECT_POLICY',
  SELECT_POLICIES: 'SELECT_POLICIES',
  UNSELECT_POLICIES: 'UNSELECT_POLICIES',
  SHOW_SELECTED_POLICIES: 'SHOW_SELECTED_POLICIES',
  HIDE_SELECTED_POLICIES: 'HIDE_SELECTED_POLICIES',
  MAX_SELECTION_EXCEEDED: 'MAX_SELECTION_EXCEEDED',
  COMPARE_SELECTED_POLICIES: 'COMPARE_SELECTED_POLICIES',
  SAVE_SELECTED_POLICIES: 'SAVE_SELECTED_POLICIES',
  PURCHASE_POLICY: 'PURCHASE_POLICY',
  PURCHASING_POLICY: 'PURCHASING_POLICY',
  PURCHASE_POLICY_FAILURE: 'PURCHASE_POLICY_FAILURE',
  PURCHASE_POLICY_SUCCESS: 'PURCHASE_POLICY_SUCCESS',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  TOGGLE_MODAL: 'TOGGLE_MODAL',
  UPDATE_POLICY_SEARCH: 'UPDATE_POLICY_SEARCH',
  FETCHING_USER_POLICIES: 'FETCHING_USER_POLICIES',
  FETCHING_USER_POLICIES_SUCCESS: 'FETCHING_USER_POLICIES_SUCCESS',
  FETCHING_USER_POLICIES_FAILURE: 'FETCHING_USER_POLICIES_FAILURE',
  FETCHING_USER_SEARCHES: 'FETCHING_USER_SEARCHES',
  FETCHING_USER_SEARCHES_SUCCESS: 'FETCHING_USER_SEARCHES_SUCCESS',
  FETCHING_USER_SEARCHES_FAILURE: 'FETCHING_USER_SEARCHES_FAILURE'
}

// Policies
export const UNPAID = 'U'
export const ACTIVE = 'A'
export const INACTIVE = 'I'
// export const CANCELLED = "C";
export const REFUND_REQUESTED = 'F'
export const REFUND_PROCESSED = 'P'
export const CLAIM_PENDING = 'C'
export const CLAIM_PROCESSED = 'D'

export const POLICY_STATUS_CHOICES = {
  [UNPAID]: 'Unpaid',
  [ACTIVE]: 'Active',
  [INACTIVE]: 'Inactive',
  // [CANCELLED]: "Cancelled",
  [REFUND_REQUESTED]: 'Refund requested',
  [REFUND_PROCESSED]: 'Refund Processed',
  [CLAIM_PENDING]: 'Claim pending',
  [CLAIM_PROCESSED]: 'Claim processed'
}

export const STATUS_CLASSNAMES = {
  [UNPAID]: '',
  [ACTIVE]: '',
  [INACTIVE]: '',
  // [CANCELLED]: "",
  [REFUND_REQUESTED]: '--requested',
  [REFUND_PROCESSED]: '--processed',
  [CLAIM_PENDING]: '--in-progress',
  [CLAIM_PROCESSED]: '--processed'
}

export const SCHENGEN_COUNTRIES = [
  'at',
  'be',
  'ch',
  'cz',
  'de',
  'dk',
  'ee',
  'el',
  'es',
  'fi',
  'fr',
  'hu',
  'is',
  'it',
  'li',
  'lt',
  'lu',
  'lv',
  'mt',
  'nl',
  'no',
  'pl',
  'pt',
  'sw',
  'si',
  'sk'
]

export const NON_SCHENGEN_EU_COUNTRIES = ['bg', 'hr', 'ro', 'cy', 'ie', 'uk']

export const TWO_EXCEPTION_COUNTRIES = ['us', 'ca']

export const FOUR_EXCEPTION_COUNTRIES = ['us', 'ca', 'jp', 'au']

// Products
export const MOTOR = 'M'
export const TRAVEL = 'T'
export const LIFE = 'L'
export const PROPERTY = 'P'

export const PRODUCT_TYPE_CHOICES = {
  [MOTOR]: 'Motor',
  [TRAVEL]: 'Travel',
  [PROPERTY]: 'Property',
  [LIFE]: 'Life'
}

// system modules
export const MOTOR_INSURANCE = 'motor'
export const TRAVEL_INSURANCE = 'travel'
export const PROPERTY_INSURANCE = 'property'
export const LIFE_INSURANCE = 'life'
export const HEALTH_INSURANCE = 'health'

export const LIST_OF_SYSTEM_MODULES = [
  MOTOR_INSURANCE,
  TRAVEL_INSURANCE,
  PROPERTY_INSURANCE,
  LIFE_INSURANCE,
  HEALTH_INSURANCE
]

export const THIRD_PARTY = 'T'
export const THIRD_PARTY_FIRE_AND_THEFT = 'F'
export const COMPREHENSIVE = 'C'

export const MOTOR_PRODUCT_TYPE_CHOICES = {
  [THIRD_PARTY]: 'Third Party',
  // [THIRD_PARTY_FIRE_AND_THEFT]: 'Third Party Fire and Theft',
  [COMPREHENSIVE]: 'Comprehensive'
}

export const HOME_OWNERS = 'H'
export const COMMERCIAL = 'C'

export const PROPERTY_PRODUCT_TYPE_CHOICES = {
  [HOME_OWNERS]: 'Home Owners',
  [COMMERCIAL]: 'Commercial Buildings'
}

export const EUROPE = 'E'
export const SCHENGEN = 'S'
export const WORLDWIDE = 'W'

export const TRAVEL_REGION_CHOICES = {
  [EUROPE]: 'Europe',
  [SCHENGEN]: 'Schengen',
  [WORLDWIDE]: 'Worldwide'
}

export const NONE = 'N'
export const US_CANADA = 'U'
export const US_CANADA_JP_AU = 'J'

export const TRAVEL_EXCEPTION_CHOICES = {
  [NONE]: '',
  [US_CANADA]: 'US & Canada',
  [US_CANADA_JP_AU]: 'US, Canada, Austrailia, & Japan'
}

export const MEDICAL_LIMIT = 'M'
export const SUM_ASSURED = 'S'
export const LOST_BAGGAGE = 'L'

export const TRAVEL_COVER_BD_TYPES = {
  [MEDICAL_LIMIT]: 'Medical Limit',
  [SUM_ASSURED]: 'Sum Insured',
  [LOST_BAGGAGE]: 'Lost baggage'
}

export const PRIVATE_IND = 'X1'
export const PRIVATE_CORP = 'X4'
export const TAXI = 'TX'
export const HIRING_CARS = 'HC'
export const MINI_BUS = 'MB'
export const MAXI_BUS = 'XB'
export const MOTORCYCLE = 'MC'
export const AMBULANCE = 'AB'
export const OWN_GOODS_TO_3000 = 'O3'
export const OWN_GOODS_ABOVE_3000 = 'O4'
export const TANKERS = 'TK'
export const GEN_CARTAGE_TO_3000 = 'GT'
export const GEN_CARTAGE_ABOVE_3000 = 'GA'
export const Z_ON_SITE = 'ZS'
export const Z_ON_ROAD = 'ZR'
export const GW1_CLASS1 = 'G1'
export const GW1_CLASS2 = 'G2'
export const GW1_CLASS3 = 'G3'

export const VEHICLE_TYPE_CHOICES = {
  [PRIVATE_IND]: 'Private Car (Personal Owner)',
  [PRIVATE_CORP]: 'Private Car (Company)',
  [TAXI]: 'Taxi',
  [HIRING_CARS]: 'Hiring/Rental Cars',
  [MINI_BUS]: 'Minibus (up to 15 seats)',
  [MAXI_BUS]: 'Maxi-bus (above 15 seats)',
  [MOTORCYCLE]: 'Motorcycle',
  [AMBULANCE]: 'Ambulance/Hearse',
  [OWN_GOODS_TO_3000]: 'Own Goods Z.300 (Up to 3000CC)',
  [OWN_GOODS_ABOVE_3000]: 'Own Goods Z.300 (Above 3000CC)',
  [TANKERS]: 'Articulated Lorry/Tanker',
  [GEN_CARTAGE_TO_3000]: 'Gen. Cartage Z.301 (Up to 3000CC)',
  [GEN_CARTAGE_ABOVE_3000]: 'Gen. Cartage Z.301 (Above 3000CC)',
  [Z_ON_SITE]: 'Heavy Duty Equipment (On site, Z.802)',
  [Z_ON_ROAD]: 'Heavy Duty Equipment (On road, Z.802)',
  [GW1_CLASS1]: 'GW1 Class 1',
  [GW1_CLASS2]: 'GW1 Class 2',
  [GW1_CLASS3]: 'GW1 Class 3'
}
