export function parse_query_string(query) {
  let vars = query.split('&')
  let query_string = {}
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=')
    let key = decodeURIComponent(pair[0])
    let value = decodeURIComponent(pair[1])
    // If first entry with this name
    if (typeof query_string[key] === 'undefined') {
      query_string[key] = decodeURIComponent(value)
      // If second entry with this name
    } else if (typeof query_string[key] === 'string') {
      query_string[key] = [query_string[key], decodeURIComponent(value)]
      // If third or later entry with this name
    } else {
      query_string[key].push(decodeURIComponent(value))
    }
  }
  return query_string
}

export function setCookie(queryString, queryValue, expiry) {
  document.cookie = `${queryString}=${queryValue}; expires=${expiry}`
}

export function getCookie(queryString) {
  let cookieValue = false
  if (document.cookie.split('; ').find(row => row.startsWith(queryString))) {
    cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith(`${queryString}=`))
      .split('=')[1]
  }

  return cookieValue
}
