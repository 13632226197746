import React from 'react'
import PropTypes from 'prop-types'

const HealthPartners = props => {
  return (
    <div className="motor_partners">
      <h3 className="motor_partners_title">Our Life Insurance Partners</h3>

      <p className="motor_partners_subtitle">
        Compare cheap life insurance from over 20 insurance providers we work
        with.
      </p>

      <div className="motor_partners_logos">
        {/* <img className="motor_partners_logo" src={vangu} alt="insurance" /> */}
        <div className="motor_partners_logo">
          <img
            className=""
            src="https://bestquote-production.s3.amazonaws.com/media/accounts/avatars/allianz-01.png"
            alt="Allianz Insurance"
          />
        </div>

        <div className="motor_partners_logo">
          <img
            className=""
            src="https://bestquote-production.s3.us-east-2.amazonaws.com/media/accounts/avatars/prime-01.png"
            alt="prime"
          />
        </div>

        <div className="motor_partners_logo">
          <img
            className=""
            src="https://bestquote-production.s3.us-east-2.amazonaws.com/media/accounts/avatars/vanguard-01.png"
            alt="Vanguard Insurance"
          />
        </div>

        <div className="motor_partners_logo">
          <img
            className=""
            src="https://bestquote-production.s3.us-east-2.amazonaws.com/media/accounts/avatars/unique-01.png"
            alt="Unique Insurance"
          />
        </div>

        <div className="motor_partners_logo">
          <img
            className=""
            src="https://bestquote-production.s3.us-east-2.amazonaws.com/media/accounts/avatars/nsia-01.png"
            alt="Nsia Insurance"
          />
        </div>

        <div className="motor_partners_logo">
          <img
            className=""
            src="https://bestquote-production.s3.us-east-2.amazonaws.com/media/accounts/avatars/activa-01.png"
            alt="Activa Insurance"
          />
        </div>

        <div className="motor_partners_logo">
          <img
            className=""
            src="https://bestquote-production.s3.amazonaws.com/media/accounts/avatars/enterprise-01.png"
            alt="Enterprise Insurance"
          />
        </div>
      </div>
    </div>
  )
}

HealthPartners.propTypes = {
  match: PropTypes.object,
  bannerImage: PropTypes.any,
  moduleName: PropTypes.string,
  price: PropTypes.string
}

export default HealthPartners
