import React, { Component } from 'react'
import { Redirect, withRouter, Link } from 'react-router-dom'
import axios from 'axios'
import { signupUrl, OTPUrl, getHeaders, companyUrl } from '../../config'
import PropTypes from 'prop-types'
import { getCookie, setCookie } from 'utils/urls'
import WithUser from 'hocs/WithUser'
import { Input, Form } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import BasicInput from 'components/Forms/BasicInput'
import SelectInput from 'components/Forms/SelectInput'

class SignUpForm extends Component {
  state = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    country: 'gh',
    email: '',
    password1: '',
    password2: '',
    referredBy: '',
    isCompanyAffiliate: false,
    affiliateCompany: '',
    isSubmitting: false,
    errorMessage: {},
    redirectToReferrer: false,
    companies: []
  }

  componentDidMount() {
    axios.get(`${companyUrl}/featured-companies`).then(res => {
      this.setState({ companies: res.data.results })
    })

    this.setState({
      referredBy: getCookie('referrer') ? getCookie('referrer') : ''
    })
  }

  handleInputUpdate = (stateName, event) => {
    this.setState({ [stateName]: event.target.value })
  }

  handleSelectUpdate = stateName => value => {
    this.setState({ [stateName]: value })
  }

  handleSubmit = () => {
    this.setState({ isSubmitting: true })
    let {
      email,
      firstName,
      lastName,
      password1,
      password2,
      phoneNumber,
      country,
      referredBy,
      isCompanyAffiliate,
      affiliateCompany
    } = this.state
    let data = {
      email,
      firstName,
      lastName,
      phoneNumber,
      country,
      referredBy,
      password1,
      password2,
      isCompanyAffiliate,
      affiliateCompany: isCompanyAffiliate ? affiliateCompany : ''
    }
    !isCompanyAffiliate && delete data.affiliateCompany

    axios
      .post(signupUrl, data)
      .then(res => {
        let userData = res.data
        userData.phoneNumber = phoneNumber
        this.props.setUser(userData)

        axios
          .post(OTPUrl, { phoneNumber }, { headers: getHeaders() })
          .then(res => {
            this.props.history.push('/auth/signup-verification')

            // remove referrer code from cookies
            let expiryDate = new Date('1970-01-01')
            expiryDate.setDate(expiryDate.getDate() - 1000)
            setCookie('referrer', referredBy, expiryDate)
          })
          .catch(err => {
            console.log('otp error')
          })
      })
      .catch(error => {
        if (error.response && error.response.status < 500) {
          this.setState({ errorMessage: error.response.data })
        } else
          this.setState({
            errorMessage: {
              nonFieldErrors: ['Oops! Something happened. Please try again.']
            }
          })

        this.setState({ isSubmitting: false })
      })
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    const redirectPath = from.pathname
    const { redirectToReferrer } = this.state

    const comapnyOptions = this.state.companies.map(comp => {
      return {
        label: comp.name,
        value: comp.id
      }
    })

    const affiliateOptions = [
      { label: "No I'm Independent", value: false },
      { label: "Yes I'm a company's agent", value: true }
    ]

    if (redirectToReferrer === true) {
      return <Redirect to={redirectPath} />
    }

    return (
      <Form className="" onFinish={this.handleSubmit} layout="vertical">
        <div className="row">
          <div className="col-md-6">
            <div className=" mb-2rem">
              {/* <label for="firstName" style={{ fontWeight: '700' }}>
                First Name
              </label>
              <Input
                type="text"
                className=""
                id="firstName"
                placeholder="Kofi"
                onChange={this.handleInputUpdate.bind(this, 'firstName')}
              /> */}
              <BasicInput
                label="First Name"
                value={this.state.firstName}
                name={'first name'}
                placeholder="Kofi"
                handleChange={this.handleInputUpdate.bind(this, 'firstName')}
                required={true}
              />
              {this.state.errorMessage.firstName
                ? this.state.errorMessage.firstName.map(err => (
                    <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                  ))
                : null}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-2rem">
              {/* <label for="lastName" style={{ fontWeight: '700' }}>
                Last Name
              </label>
              <Input
                className=""
                placeholder="Amankwa"
                onChange={this.handleInputUpdate.bind(this, 'lastName')}
              /> */}
              <BasicInput
                label="Last Name"
                value={this.state.lastName}
                name={'last name'}
                placeholder="Amankwa"
                handleChange={this.handleInputUpdate.bind(this, 'lastName')}
                required={true}
              />
              {this.state.errorMessage.lastName
                ? this.state.errorMessage.lastName.map(err => (
                    <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                  ))
                : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group mb-2rem">
              {/* <label for="email" style={{ fontWeight: '700' }}>
                Email Address
              </label>
              <Input
                className=""
                id="email"
                type="email"
                placeholder="kofi@gmail.com"
                onChange={this.handleInputUpdate.bind(this, 'email')}
              /> */}
              <BasicInput
                label="Email Address"
                value={this.state.email}
                name={'email'}
                placeholder="kofi@gmail.com"
                handleChange={this.handleInputUpdate.bind(this, 'email')}
                required={true}
              />
              {this.state.errorMessage.email
                ? this.state.errorMessage.email.map(err => (
                    <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                  ))
                : null}
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group mb-2rem">
              {/* <label for="phone" style={{ fontWeight: '700' }}>
                Phone Number
              </label>
              <Input
                className=""
                id="phone"
                placeholder="0244xxxxxx"
                type="text"
                onChange={this.handleInputUpdate.bind(this, 'phoneNumber')}
              /> */}

              <BasicInput
                label="Phone Number"
                value={this.state.phoneNumber}
                name={'phone number'}
                placeholder="0244xxxxxx"
                handleChange={this.handleInputUpdate.bind(this, 'phoneNumber')}
                required={true}
              />
              {this.state.errorMessage.phoneNumber
                ? this.state.errorMessage.phoneNumber.map(err => (
                    <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                  ))
                : null}
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group mb-2rem">
              {/* <label for="isCompanyAffiliate" style={{ fontWeight: '700' }}>
                Are you an agent for any insurance company?
              </label>
              <Select
                value={this.state.isCompanyAffiliate}
                options={affiliateOptions}
                name="isCompanyAffiliate"
                className=""
                onChange={this.handleSelectUpdate('isCompanyAffiliate')}
                style={{ width: '100%' }}
              /> */}
              <SelectInput
                label={'Are you an agent for any insurance company?'}
                value={this.state.isCompanyAffiliate}
                name={'affiliate field'}
                optionList={affiliateOptions}
                onChange={this.handleSelectUpdate('isCompanyAffiliate')}
                required={true}
              />
              {this.state.errorMessage.isCompanyAffiliate
                ? this.state.errorMessage.isCompanyAffiliate.map(err => (
                    <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                  ))
                : null}
            </div>
          </div>

          {this.state.isCompanyAffiliate ? (
            <div className="col-md-12">
              <div className="form-group mb-2rem">
                {/* <label for="affiliateCompany" style={{ fontWeight: '700' }}>
                  Kindly choose your affiliated company
                </label>
                <Select
                  value={this.state.affiliateCompany}
                  options={comapnyOptions}
                  name="affiliateCompany"
                  className=""
                  onChange={this.handleSelectUpdate('affiliateCompany')}
                  style={{ width: '100%' }}
                /> */}
                <SelectInput
                  label={'Kindly choose your affiliated company'}
                  value={this.state.affiliateCompany}
                  name={'company'}
                  optionList={comapnyOptions}
                  onChange={this.handleSelectUpdate('affiliateCompany')}
                  required={true}
                />
                {this.state.errorMessage.affiliateCompany
                  ? this.state.errorMessage.affiliateCompany.map(err => (
                      <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                    ))
                  : null}
              </div>
            </div>
          ) : null}

          <div className="col-md-12">
            <div className="form-group mb-2rem">
              <label for="password1" style={{ fontWeight: '700' }}>
                Password
              </label>
              <Input.Password
                className=""
                id="password1"
                name="password1"
                placeholder="Enter password"
                type="password"
                onChange={this.handleInputUpdate.bind(this, 'password1')}
              />
              {this.state.errorMessage.password1
                ? this.state.errorMessage.password1.map(err => (
                    <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                  ))
                : null}
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group mb-1rem">
              <label for="password2" style={{ fontWeight: '700' }}>
                Confirm Password
              </label>
              <Input.Password
                className=""
                placeholder="confirm password"
                id="password2"
                type="password"
                onChange={this.handleInputUpdate.bind(this, 'password2')}
              />
              {this.state.errorMessage.password2
                ? this.state.errorMessage.password2.map(err => (
                    <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                  ))
                : null}
            </div>
          </div>
        </div>

        <div>
          {this.state.errorMessage.nonFieldErrors
            ? this.state.errorMessage.nonFieldErrors.map(err => (
                <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
              ))
            : null}
        </div>

        <small className="text--muted">
          By clicking Sign Up, you agree to BestQuote’s
          <Link to="/terms-of-service">
            {' '}
            <span className="navbar__link--active">Terms of Service</span>
          </Link>{' '}
          &amp;
          <Link to="/privacy-policy">
            {' '}
            <span className="navbar__link--active">Privacy Policy.</span>
          </Link>
        </small>

        <div className="form__submit">
          <button
            className="btn btn--gradient-primary btn--width-lng mt-2rem mb-2rem"
            type="submit"
          >
            {this.state.isSubmitting ? <LoadingOutlined /> : 'Register'}
          </button>
        </div>
      </Form>
    )
  }
}
SignUpForm.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
}

export default withRouter(WithUser(SignUpForm))
