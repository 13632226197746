import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import ActivateAccount from './ActivateAccount'
import SignupSuccess from './SignupSuccess'
import LoginPage from './LoginPage'
import ConfirmPasswordReset from './ConfirmPasswordReset'
import PasswordResetRequest from './PasswordResetRequest'
import FacebookLogin from './FacebookLogin'
import SignUpPage from './SignUpPage'
import SignUpOTP from './SignupOTP'
import PageNotFound from 'pages/PageNotFound'

class AuthRoutes extends React.Component {
  render() {
    return (
      <Switch>
        {/* <Route exact path={props.match.path} component={AuthPage} /> */}
        <Route
          exact
          path={`${this.props.match.path}/signup`}
          render={props => <SignUpPage {...props} />}
        />
        <Route
          exact
          path={`${this.props.match.path}/login`}
          render={props => <LoginPage {...props} />}
        />
        <Route
          path={`${this.props.match.path}/signup-success`}
          // render={props => <SignupSuccess {...props} />}
          component={SignupSuccess}
        />
        <Route
          path={`${this.props.match.path}/signup-verification`}
          component={SignUpOTP}
        />
        <Route
          path={`${this.props.match.path}/activate/:key`}
          component={ActivateAccount}
        />
        <Route
          path={`${this.props.match.path}/password-reset`}
          component={PasswordResetRequest}
        />
        <Route
          path={`${this.props.match.path}/facebook-redirect`}
          component={FacebookLogin}
        />
        <Route
          path={`${this.props.match.path}/password-reset-confirm`}
          component={ConfirmPasswordReset}
        />
        <Route component={PageNotFound} />
      </Switch>
    )
  }
}
AuthRoutes.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string
  })
}

export default AuthRoutes
