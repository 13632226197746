import React, { Component } from 'react'
import axios from 'axios'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import { css } from 'react-emotion'
import { FadeLoader } from 'react-spinners'
import PropTypes from 'prop-types'
import { getHeaders, lifeUrl } from '../../../config'

import WithUser from 'hocs/WithUser'
import LifeUserPolicyCard from './LifeUserPolicyCard'
import policyIcon from 'img/policy-icon.svg'

class MyLifePolicies extends Component {
  state = {
    loadingLife: true,
    unpaidLifePolicies: [],
    activeLifePolicies: [],

    width: 0,
    height: 0
  }

  async componentDidMount() {
    try {
      let {
        data: { results }
      } = await axios.get(lifeUrl, {
        headers: getHeaders()
      })
      let unpaidLifePolicies = results.filter(
        policy => policy.status === 'U' && policy.premium !== null
      )

      let activeLifePolicies = results.filter(
        policy => policy.status !== 'U' && policy.premium !== null
      )

      this.setState({
        unpaidLifePolicies,
        activeLifePolicies,
        loadingLife: false
      })
    } catch (err) {
      console.error(err)
    }

    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  handleMakeClaim = () => {
    this.props.makeClaim()
    this.props.openModal()
  }

  handleMakeCancellation = () => {
    this.props.makeCancellation()
    this.props.openModal()
  }

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `
    return (
      <div className="user-dash__main">
        <p className="site-content__lead">Purchased Life Insurance Policies</p>
        <p className="text--muted mb-2rem">Click any policy to see more</p>
        <hr />

        {this.state.loadingLife ? (
          <div className="mt-3rem">
            <FadeLoader
              className={override}
              sizeUnit={'px'}
              size={150}
              color={'#13d1cf'}
            />
          </div>
        ) : this.state.activeLifePolicies.length ? (
          <div className="mt-2rem flex-row flex-wrap">
            {this.state.activeLifePolicies.map(policy => (
              <LifeUserPolicyCard key={policy.id} policy={policy} />
            ))}
          </div>
        ) : (
          <div className="mt-2rem flex-row flex-wrap">
            {this.state.width > 767 ? (
              <img src={policyIcon} alt="policy-icon" />
            ) : null}
            <p className={cx(this.state.width > 767 ? 'ml-2rem' : null)}>
              <span className="mr-2rem">You have no active policies.</span>
              <button
                className={cx(
                  'btn btn--gradient-border',
                  this.state.width > 767 ? null : 'mt-1rem'
                )}
              >
                <Link to="/buy-life-insurance">Buy Life Insurance</Link>
              </button>
            </p>
          </div>
        )}

        <div className="mb-5rem" />

        <p className="site-content__lead">Unpaid Life Insurance Policies</p>
        <p className="text--muted mb-2rem">Click on any policy to pay</p>

        <hr />
        {this.state.loadingLife ? (
          <div className="mt-3rem">
            <FadeLoader
              className={override}
              sizeUnit={'px'}
              size={150}
              color={'#13d1cf'}
            />
          </div>
        ) : this.state.unpaidLifePolicies.length ? (
          <div className="mt-2rem flex-row flex-wrap">
            {this.state.unpaidLifePolicies.map(policy => (
              <LifeUserPolicyCard key={policy.id} policy={policy} />
            ))}
          </div>
        ) : (
          <div className="mt-2rem flex-row flex-wrap">
            {this.state.width > 767 ? (
              <img src={policyIcon} alt="policy-icon" />
            ) : null}
            <p className={cx(this.state.width > 767 ? 'ml-2rem' : null)}>
              <span className="mr-2rem">You have no unpaid policies.</span>
              <button
                className={cx(
                  'btn btn--gradient-border',
                  this.state.width > 767 ? null : 'mt-1rem'
                )}
              >
                <Link to="/buy-life-insurance">Buy Life Insurance</Link>
              </button>
            </p>
          </div>
        )}
      </div>
    )
  }
}

MyLifePolicies.propTypes = {
  authUser: PropTypes.shape({
    token: PropTypes.string
  }),
  makeClaim: PropTypes.func,
  openModal: PropTypes.func,
  makeCancellation: PropTypes.func
}

export default WithUser(MyLifePolicies)
