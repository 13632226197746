import React from 'react'

import PropTypes from 'prop-types'

const Backdrop = props =>
  props.show ? (
    <div className={props.backdropStyle} onClick={props.handleCloseModal} />
  ) : null
Backdrop.propTypes = {
  show: PropTypes.bool,
  backdropStyle: PropTypes.string,
  handleCloseModal: PropTypes.func
}

export default Backdrop
