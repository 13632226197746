import React, { useState } from 'react'
import PropTypes from 'prop-types'
import HealthBannerPage from './HealthBanner'
import HealthForm from 'components/Forms/HealthForm'
import HealthPartners from './HealthPartners'
import bannerImage from 'img/motor/motor-main-banner.svg'
import { Modal } from 'antd'

const HealthMainPage = props => {
  const [showModal, setShowModal] = useState(false)

  return (
    <React.Fragment>
      <div className="motor">
        <div className="row">
          <div className="col-md-6 col-xs-12 motor_banner_section">
            <HealthBannerPage
              bannerImage={bannerImage}
              moduleName="Car"
              price="GHS 88.00"
            />
          </div>
          <div className="col-md-6 motor_form_section">
            <HealthForm />
          </div>
        </div>

        <div className="row motor_partners_section pb-5rem">
          <div className="col-md-7">
            <HealthPartners />
          </div>
        </div>

        <div className="motor_floating_section">
          <button
            type="button"
            className="btn btn--gradient-primary mb-2rem motor_floating_section_button"
            onClick={() => setShowModal(true)}
          >
            Get Started Now!
          </button>
        </div>

        <Modal
          title=""
          centered
          width={'90%'}
          visible={showModal}
          onOk={() => setShowModal(false)}
          onCancel={() => setShowModal(false)}
          footer={null}
        >
          <div className="motor_form_wrapper">
            <HealthForm />
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

HealthMainPage.propTypes = {
  match: PropTypes.object
}

export default HealthMainPage
