import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Menu, Button } from 'antd'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  SafetyCertificateOutlined,
  // WalletOutlined,
  BookOutlined,
  UsergroupAddOutlined,
  SettingOutlined
} from '@ant-design/icons'
import bqLogo from 'img/bestquote-logo.png'

const { SubMenu } = Menu

const DashboardSidebar = props => {
  const [collapsed, setCollapsed] = useState()

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  return (
    <React.Fragment>
      <div className="dashboard_sidebar">
        <img
          src={bqLogo}
          alt="logo"
          className="dashboard_sidebar_logo"
          onClick={() => props.history.push('/')}
        />
        <Menu
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub4']}
          mode="inline"
          theme="light"
          inlineCollapsed={collapsed}
          className="dashboard_sidebar_menu"
        >
          <Menu.Item
            key="1"
            icon={<PieChartOutlined />}
            onClick={() => props.history.push('/')}
          >
            Overview
          </Menu.Item>
          <SubMenu key="sub4" icon={<UsergroupAddOutlined />} title="Clients">
            <Menu.Item key="17" onClick={() => props.history.push('/clients')}>
              View Clients
            </Menu.Item>
            <Menu.Item
              key="18"
              onClick={() => props.history.push('/clients/add-new')}
            >
              Add Clients
            </Menu.Item>
          </SubMenu>

          <SubMenu key="sub2" icon={<BookOutlined />} title="Buy Insurance">
            <Menu.Item
              key="10"
              onClick={() => props.history.push('/buy-motor-insurance')}
            >
              Car Insurance
            </Menu.Item>
            <Menu.Item
              key="11"
              onClick={() => props.history.push('/buy-travel-insurance')}
            >
              Travel Insurance
            </Menu.Item>
            <Menu.Item
              key="13"
              onClick={() => props.history.push('/buy-home-insurance')}
            >
              Home Insurance
            </Menu.Item>
          </SubMenu>

          <SubMenu
            key="sub1"
            icon={<SafetyCertificateOutlined />}
            title="Policy History"
          >
            <Menu.Item key="2" onClick={() => props.history.push('/motor')}>
              Car Policies
            </Menu.Item>
            <Menu.Item key="3" onClick={() => props.history.push('/travel')}>
              Travel Policies
            </Menu.Item>
            <Menu.Item key="4" onClick={() => props.history.push('/property')}>
              Home Policies
            </Menu.Item>
          </SubMenu>

          {/* <Menu.Item
            key="20"
            icon={<ArrowUpOutlined />}
            onClick={() => props.history.push('/claims')}
          >
            Claims
          </Menu.Item>
          <Menu.Item
            key="21"
            icon={<ArrowDownOutlined />}
            onClick={() => props.history.push('/cancellations')}
          >
            Cancellations
          </Menu.Item> */}

          {/* <SubMenu key="sub3" icon={<WalletOutlined />} title="Wallet">
            <Menu.Item key="14" onClick={() => props.history.push('#')}>
              View Commissions
            </Menu.Item>
            <Menu.Item key="15" onClick={() => props.history.push('#')}>
              Initiate Payout
            </Menu.Item>
            <Menu.Item key="16" onClick={() => props.history.push('#')}>
              View Payouts
            </Menu.Item>
          </SubMenu> */}

          <Menu.Item
            key="22"
            icon={<SettingOutlined />}
            onClick={() => props.history.push('/account-settings')}
          >
            Account Settings
          </Menu.Item>
        </Menu>
        <Button
          type="primary"
          onClick={toggleCollapsed}
          className="dashboard_sidebar_btn"
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined
          )}
        </Button>
      </div>
    </React.Fragment>
  )
}

DashboardSidebar.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}

export default withRouter(DashboardSidebar)
